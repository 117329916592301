

// TODO: в коде много общего с Text.vue, сделать mixin

import { defineComponent, computed } from 'vue'
import { getUID } from '../getUID'

export default defineComponent({
  name: 'Textarea',
  inheritAttrs:true,

  props: {
    caption: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    modelValue: { type: String, default: '' },
    disabled: { type: Boolean, default: false },

    sm:  { type: Boolean, default: false },
    md:  { type: Boolean, default: false },
    lg:  { type: Boolean, default: false },

    rows: { type: String, default: "3" },
    mp: { type: String, default: '' },

  },

  setup(props) {
    const uid = getUID('Textarea-')

    const cssClass = computed(() => {
      let classes = ['']
      if (props.mp) {
        classes.push(props.mp)
      }
      return classes.join(' ')
    })

    const cssInput = computed(() => {
      let classes = ['form-control form-control-sm']
      var suffix = ''
      suffix = props.sm ? 'form-control-sm' : suffix
      suffix = props.md ? 'form-control-md' : suffix
      suffix = props.lg ? 'form-control-lg' : suffix
      return 'form-control' + (suffix ? (' ' + suffix) : '')
    })

    return {
      uid,
      cssClass,
      cssInput
    };
  }
})
