
import { defineComponent, reactive, provide, onMounted,
         onBeforeMount, toRefs} from "vue"
import { useI18n } from 'vue-i18n'


export default defineComponent({
  name: "Tabs",
  inheritAttrs: true,
  props: {},

  setup(_, { slots }) {
    const i18n = useI18n()
    const state = reactive({
      selectedIndex: 0,
      tabs: [] as Array<string>,
      colors: [] as Array<string>,
      count: 0
    })
    provide("TabsProvider", state);

    const selectTab = (i: number) => {
      state.selectedIndex = i
    }

    const tabColor = (i: number) => {
      if (state.selectedIndex != i) {
        return 'color:' + state.colors[i]
      }
    }

    onBeforeMount(() => {
      if (slots.default) {
        slots.default()
          .filter((child: any) => child.type.name === "Tab")
          .forEach((tab) => {
            if (tab.props && tab.props.title) {
              state.tabs.push(tab.props.title)
            }
        })
      }
    })

    onMounted(() => {
      selectTab(0);
    })

    return {
      i18n,
      ...toRefs(state),
      selectTab,
      tabColor
    }
  },
})
