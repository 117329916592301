import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6240d139"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  colspan: "3",
  class: "cursor-pointer"
}
const _hoisted_3 = {
  key: 1,
  colspan: "3"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Box = _resolveComponent("Box")!
  const _component_Image = _resolveComponent("Image")!
  const _component_Table = _resolveComponent("Table")!

  return (_ctx.menu)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Table, {
          "col-width": "a,100%,a",
          hover: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.items, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                (item.id=='all')
                  ? (_openBlock(), _createElementBlock("td", _hoisted_2, [
                      _createVNode(_component_Box, {
                        span: "",
                        bold: _ctx.isActive(item.name),
                        class: "link",
                        align: "center",
                        fontsize: "1.1em",
                        onClick: ($event: any) => (_ctx.changeFilter(index))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["bold", "onClick"])
                    ]))
                  : _createCommentVNode("", true),
                (item.id=='-')
                  ? (_openBlock(), _createElementBlock("td", _hoisted_3, [
                      _createVNode(_component_Box, {
                        height: "10px",
                        mp: "0,0,0,0;-5,-5,-5,-5",
                        background: "lightgray"
                      })
                    ]))
                  : _createCommentVNode("", true),
                (item.id!='all' && item.id!='-')
                  ? (_openBlock(), _createElementBlock("td", _hoisted_4, [
                      _createElementVNode("div", null, [
                        _createVNode(_component_Image, {
                          path: "img/money/m-schet.png",
                          height: "16"
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (item.id!='all' && item.id!='-')
                  ? (_openBlock(), _createElementBlock("td", {
                      key: 3,
                      onClick: ($event: any) => (_ctx.changeFilter(index)),
                      class: "cursor-pointer"
                    }, [
                      _createVNode(_component_Box, {
                        span: "",
                        bold: _ctx.isActive(item.name),
                        class: "link"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["bold"])
                    ], 8, _hoisted_5))
                  : _createCommentVNode("", true),
                (item.id!='all' && item.id!='-')
                  ? (_openBlock(), _createBlock(_component_Box, {
                      key: 4,
                      td: "",
                      nowrap: "",
                      align: "right"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatPrice(item.balance)), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}