/* CRC:2427B23F:4 */
/* Реализация интерфейсов локализации */

import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import {
  RootMutationType,
  RootStateInterface,
  LocalesStateInterface
} from '@/models'
import { initialLocalesState} from './initialState'
import { i18n } from '@/plugins/vue-i18n-next-plugin'

// Vuex Locales mutations
export const mutations: MutationTree<LocalesStateInterface> = {
  selectLocale(state: LocalesStateInterface, localeId: string) {
    // set only the model selected to true
    state.availableLocales.forEach(localeInfo => {
      localeInfo.selected = localeInfo.locale === localeId
      if (localeInfo.selected) {
        // switch i18n selected locale
        i18n.global.locale = localeInfo.locale as any
      }
    })
  }
}

// Vuex Locales actions
export const actions: ActionTree<LocalesStateInterface, RootStateInterface> = {
  selectLocale({ commit }, localeId: string) {
    commit(RootMutationType.locales.selectLocale, localeId)
  }
}

export const getters: GetterTree<LocalesStateInterface, RootStateInterface> = {}

const namespaced: boolean = true
const state: LocalesStateInterface = initialLocalesState

export const localesState: Module<LocalesStateInterface, RootStateInterface> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
