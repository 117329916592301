
// TODO: если переданного значения в :value="modelValue" нет в списке options
// то не будет выделенного текущего элемента, по идее нужно выделить 1-й
// элемент списка (или задать значение по умолчанию)
// TODO: можно предусмотреть сортировку значений в options (есть ли смысл ?)

import { defineComponent, computed } from 'vue'
import { getUID } from '../getUID'

export default defineComponent({
  name: 'Select',
  inheritAttrs:true,

  props: {
    caption: { type: String, default: '' },
    modelValue: { type: [String, Number], default: '' },
    disabled: { type: Boolean, default: false },
    options: { Array, default: [] },

    sm:  { type: Boolean, default: false },
    md:  { type: Boolean, default: false },
    lg:  { type: Boolean, default: false }
  },

  setup(props) {
    const uid = getUID('Select-')

    const cssClass = computed(() => {
      var suffix = ''
      suffix = props.sm ? 'form-select-sm' : suffix
      suffix = props.md ? 'form-select-md' : suffix
      suffix = props.lg ? 'form-select-lg' : suffix
      return 'form-select' + (suffix ? (' ' + suffix) : '')
    })

  return {
      uid,
      cssClass
    };
  }
})
