import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f16e3e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_TasksOverdue = _resolveComponent("TasksOverdue")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, {
    fluid: "",
    mp: _ctx.cssContainer
  }, {
    default: _withCtx(() => [
      _createVNode(_component_MainHeader),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_TasksOverdue),
        _createVNode(_component_router_view)
      ])
    ]),
    _: 1
  }, 8, ["mp"]))
}