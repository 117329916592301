/* CRC:18422DFC:2 */
/* Формирование даты или времени в "человеческом" формате */

import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import 'dayjs/locale/en'

export const getTextDate = (date:string, fmt: string = 'date') => {
  const i18n = useI18n()
  dayjs.locale(i18n.t("days-js.lang"))
  if (fmt == 'time') {
    var dt = date
    if (date.length <= 8) { // передано только время
                           // (соотв. часовой пояс будет неверен)
      dt = '2000-01-01'+ date
    }
    return `${dayjs(dt).format('HH:mm')}`+
          ` [GMT ${dayjs(dt).format("Z")}]`
  }
  let result = dayjs(date).format('D MMMM YYYY г. (dddd)')
  if (result == 'Invalid Date') {
    return i18n.t("common.Invalid Date")
  }
  return result
}
