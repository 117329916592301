

// TODO: в коде много общего с Radio.vue, сделать mixin
// TODO: вместо <Checkbox switch> сделать компонент Switch (extend или mixin)

import { defineComponent, computed } from 'vue'
import { getUID } from '../getUID'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'Checkbox',
  inheritAttrs:true,

  props: {
    caption: { type: String, default: 'default checkbox' },
    modelValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    inline: { type: Boolean, default: false },
    switch: { type: Boolean, default: false },
    mp: { type: String, default: '' },
    center: { type: Boolean, default: false },
  },

  setup(props) {
    const i18n = useI18n()

    const uid = getUID('Checkbox-')

    const cssLabel = computed(() => {
      let classes = ['form-check-label']
      if (props.disabled) {
        classes.push('disabled')
      }
      return classes.join(' ')
    })

    const cssClass = computed(() => {
      let classes = ['form-check']
      if (props.inline) {
        classes.push('form-check-inline')
      }
      if (props.switch) {
        classes.push('form-switch')
      }
      if (props.mp) {
        classes.push(props.mp)
      }
      if (props.center) {
        classes.push('align-self-center')
      }
      return classes.join(' ')
    })

    return {
      i18n,
      uid,
      cssClass,
      cssLabel
    };
  }
})
