import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Box = _resolveComponent("Box")!
  const _component_Container = _resolveComponent("Container")!

  return (_ctx.countOverdue>0)
    ? (_openBlock(), _createBlock(_component_Container, {
        key: 0,
        fluid: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Box, {
            onClick: _ctx.goTask,
            class: "cursor-pointer text-align-center",
            mp: "5,0,5,0;0,0,0,-20",
            background: "#f44336",
            color: "white",
            width: "calc(100% + 40px)"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Box, {
                span: "",
                bold: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.i18n.t('tasks.ATTENTION')) + " !!!", 1)
                ]),
                _: 1
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.i18n.t('tasks.You have OVERDUE tasks')) + " - " + _toDisplayString(_ctx.countOverdue) + " " + _toDisplayString(_ctx.i18n.t('tasks.pcs')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}