/* CRC:FCE925E8:1 */
/* Экземпляры models/auth... с начальными значениями */


import { UserInterface } from '@/models'


export const initialUser: UserInterface = {
  id: '',
  email: '',
  enabled: true,
  f: '',
  i: '',
  o: '',
  roles: []
}
