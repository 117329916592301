import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32d3f5a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "placeholder", "value", "disabled", "rows"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.cssClass)
  }, [
    (_ctx.caption)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.uid,
          class: "form-label"
        }, _toDisplayString(_ctx.caption), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", _mergeProps({ class: _ctx.cssInput }, _ctx.$attrs, {
      id: _ctx.uid,
      placeholder: _ctx.placeholder,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      value: _ctx.modelValue,
      disabled: _ctx.disabled,
      rows: _ctx.rows
    }), null, 16, _hoisted_2)
  ], 2))
}