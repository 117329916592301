import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-abc5db88"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "value", "disabled"]
const _hoisted_2 = {
  key: 0,
  value: "",
  disabled: ""
}
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("select", _mergeProps({
      class: _ctx.cssClass,
      id: _ctx.uid
    }, _ctx.$attrs, {
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      value: _ctx.modelValue,
      disabled: _ctx.disabled
    }), [
      (_ctx.caption)
        ? (_openBlock(), _createElementBlock("option", _hoisted_2, _toDisplayString(_ctx.caption), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          key: option.value,
          value: option.value
        }, _toDisplayString(option.text), 9, _hoisted_3))
      }), 128))
    ], 16, _hoisted_1)
  ]))
}