import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10b0bc06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getValues, (option) => {
      return (_openBlock(), _createElementBlock("div", {
        key: option.value
      }, [
        _createVNode(_component_Checkbox, {
          caption: option.text,
          inline: "",
          disabled: _ctx.disabled,
          modelValue: option.cur_value,
          "onUpdate:modelValue": ($event: any) => ((option.cur_value) = $event),
          onInput: ($event: any) => (_ctx.setValues(option.value, $event.target.checked))
        }, null, 8, ["caption", "disabled", "modelValue", "onUpdate:modelValue", "onInput"])
      ]))
    }), 128))
  ]))
}