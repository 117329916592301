
import { computed, defineComponent, inject } from "vue"
import BtnClose from "../BtnClose.vue"

export default defineComponent({
  name: "ModalHeader",
  components: {
    BtnClose
  },
  props: {
    close: { type: Boolean, default: true },
    closeWhite: { type: Boolean, default: false },
    color:  { type: String, default: 'white' },
    background:  { type: String, default: '#17a2b8' }
  },
  setup(props, context) {
    const closeModal = inject("closeModal", false);

    const className = computed(() => {
      return ["modal-header"]
    });

    const headerStyle = () => {
      let bg = `background-color: ${props.background};`
      let color = `color: ${props.color};`
      return bg + color
    }

    return {
      className,
      closeModal,
      context,
      props,
      headerStyle
    };
  }
})
