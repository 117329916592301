/* CRC:EFC96E04:1 */
/* Экземпляр MoneyStateInterface с начальными значениями */


import { MoneyStateInterface } from '@/models'


export const initialMoneyState: MoneyStateInterface = {
  loading: false,
  items: [],

  menu: {
    showMenu: true,
    currentIndex: 0,
    items: [ { id: '', name: 'Загрузка...', balance: 0 }, ],
    currentItem: { id: '', name: '', balance: 0 },
  },

  currentItem: null,
  showEdit: false,
  accounts: [],
  products: [],

  error: {
    status: 0,
    statusText: '',
    msg: ''
  }
}
