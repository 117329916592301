import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9913a74c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.cssClass)
  }, [
    _createElementVNode("input", _mergeProps({
      type: "time",
      class: _ctx.cssInput
    }, _ctx.$attrs, {
      style: _ctx.cssStyle,
      id: _ctx.uid,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      value: _ctx.modelValue,
      disabled: _ctx.disabled
    }), null, 16, _hoisted_1),
    (_ctx.clearButton && !_ctx.intable && !_ctx.isMobile())
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: _normalizeClass(_ctx.cssButton),
          type: "button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.reset()))
        }, "✖ ", 2))
      : _createCommentVNode("", true)
  ], 2))
}