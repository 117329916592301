
import { defineComponent, computed, onMounted, ref } from 'vue'


// =========================================================================
export const parseMP = (props_mp: string) => {
  // разбор строки для padding, margin

  // --------------------------------------------------
  var padding: string[], margin: string[]
  if (props_mp.indexOf(";") != -1) {  // явное разделение точкой с запятой
      margin = props_mp.split(";")[1].split(",")
      padding = props_mp.split(";")[0].split(",")
  } else {
    padding = props_mp.split(",")
    if (padding.length > 4) {  // значения все подряд
      margin = padding.slice(4)
      padding = padding.slice(0, 4)
    } else {   // есть только padding
      margin = []
    }
  }

  // --------------------------------------------------
  // формируем окончательный результат
  var result: { [key: string]: string } = {}
  let toResult = (arr: string[], idx: number, name: string) => {
    if (arr.length - 1 >= idx) {
      if (!Number.isNaN(parseInt(arr[idx]))) {
        result[name] = arr[idx].trim() + "px"
  }}}

  toResult(padding, 0, 'padding-top')
  toResult(padding, 1, 'padding-right')
  toResult(padding, 2, 'padding-bottom')
  toResult(padding, 3, 'padding-left')

  toResult(margin, 0, 'margin-top')
  toResult(margin, 1, 'margin-right')
  toResult(margin, 2, 'margin-bottom')
  toResult(margin, 3, 'margin-left')

  // --------------------------------------------------
  return result
}


// =========================================================================
export default defineComponent({
  name: 'Box',

  // ---------------------------------------------------------------------------
  props: {
    color: { type: String, default: '' },        // цвет
    background: { type: String, default: '' },   // цвет фона
    mp: { type: String, default: '' },           // отступы (mdrgin, padding)
    align: { type: String, default: '' },        // выравнивание
    span: { type: Boolean, default: false },     // тип компонента (span/div)
    tr: { type: Boolean, default: false },       // тип компонента (tr)
    td: { type: Boolean, default: false },       // тип компонента (td)
    width: { type: String, default: '' },        // ширина контейнера
    border: { type: String, default: '' },       // рамка
    nowrap: { type: Boolean, default: false },   // отключение переноса строк
    bold:  { type: Boolean, default: false },    // жирный шрифт
    font: { type: String, default: '' },         // шрифт
    fontsize: { type: String, default: '' },     // размер шрифта
    height: { type: String, default: '' },       // высота
  },

  // ---------------------------------------------------------------------------
  setup(props) {
    const root: any = ref(null)

    // -------------------------------------------------------------------------
    const typeComponent = computed(() => {
      return props.tr ? 'tr' : (props.td ? 'td' : (props.span ? 'span' : 'div'))
    })

    // -------------------------------------------------------------------------
    const checkStyleMP = () => {
      // проверка стиля bootstrap (если есть буквы m p g считаем, что формат mp)
      return props.mp.indexOf('m') != -1 ||
             props.mp.indexOf('p') != -1 ||
             props.mp.indexOf('g') != -1
    }

    // -------------------------------------------------------------------------
    const parseBorder = () => {
      // разбор строки для border

      // --------------------------------------------------
      var direct = ['top', 'right', 'bottom', 'left']
      var border: string[], line: string = 'px solid '

      border = props.border.split(",")
      if (border.length > 4) {
        line += border[4]
      } else {
        border = border.concat(['0', '0', '0', '0'])
        line += 'black'
      }
      border = border.slice(0, 4)

      // --------------------------------------------------
      // формируем результат
      var result: { [key: string]: string } = {}
      for (let i = 0; i < 4; i++) {
        if (!Number.isNaN(parseInt(border[i])) && parseInt(border[i]) != 0) {
          result[`border-${direct[i]}`] = border[i].trim() + line
        }
      }

      // --------------------------------------------------
      return result
    }

    // -------------------------------------------------------------------------
    const cssStyle = computed(() => {
      var style: { [key: string]: string } = {}
      if (!checkStyleMP()) {
          style = parseMP(props.mp)
      }
      style = { ...style, ...parseBorder() }
      if (props.color) {
        style.color = props.color
      }
      if (props.background) {
        style['background-color'] = props.background
      }
      if (props.width) {
        style.width = props.width
      }
      if (props.nowrap) {
        style['word-wrap'] = 'break-word'
        style['white-space'] = 'nowrap'
      }
      if (props.bold) {
        style['font-weight'] = 'bold'
      }
      if (props.fontsize) {
        style['font-size'] = props.fontsize
      }
      if (props.height) {
        style['height'] = props.height
        style['max-height'] = props.height
        style['min-height'] = props.height
      }
      if (props.font) {
        style['font-family'] = props.font
      }
      return style
    })

    // -------------------------------------------------------------------------
    onMounted(() => {
      // добавляем класс (сделано таким образом, чтобы при отсутствии класса
      // не было пустого определения <div class="">, а был просто <div>)
      if (root && root.value) {
        var cls = []
        // -------------- стиль bootstrap
        if (checkStyleMP()) {
          cls.push(props.mp)
        }
          // TODO: вместо классов bootstrap сделать свои стили
        // -------------- align
        if (props.align) {
          // TODO: вроде не совсем корректно (если добавить d-flex - "едет"
          // таблица в диалоге редактирования задачи (вкладка "Инфо"))
          // и не только, проверить, где используется свойство align
          // и сделать "нормальные" классы + посмотреть, где используется
          // выравнивание из @/components/base-ui/common.scss
          if (props.align.indexOf('middle') == -1) {
            cls.push('d-flex')
          }
          if (props.align.indexOf('left') != -1){
            cls.push('justify-content-start')  // d-flex добавлен ранее
          }
          if (props.align.indexOf('center') != -1){
            cls.push('justify-content-center')  // d-flex добавлен ранее
          }
          if (props.align.indexOf('right') != -1){
            cls.push('justify-content-end')  // d-flex добавлен ранее
          }
          if (props.align.indexOf('top') != -1){
            cls.push('align-items-start')  // d-flex добавлен ранее
            cls.push('align-self-start')
            cls.push('align-top')
            cls.push('align-text-top')
          }
          if (props.align.indexOf('middle') != -1){
            // TODO: вроде не совсем корректно (если добавить d-flex - "едет"
            //       таблица в диалоге редактирования задачи (вкладка "Инфо"))
            if (!props.tr && !props.td) {
              cls.push('d-flex')
            }
            cls.push('align-items-center')
            cls.push('align-self-center')
            cls.push('align-middle')
          }
          if (props.align.indexOf('bottom') != -1){
            cls.push('align-items-end')  // d-flex добавлен ранее
            cls.push('align-self-end')
            cls.push('align-bottom')
            cls.push('align-text-bottom')
          }
        }
        // -------------- добавление пред. значения класса
        if (root.value.className) {
          cls.push(root.value.className)
        }
        // -------------- формирование стиля
        if (cls.length) {
          root.value.className = cls.join(' ')
        }
      }
    })

    // -------------------------------------------------------------------------
    return {
      root,
      cssStyle,
      typeComponent,
    }
  }
})
