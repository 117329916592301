import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f92e1cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Task = _resolveComponent("Task")!
  const _component_Table = _resolveComponent("Table")!
  const _component_Box = _resolveComponent("Box")!
  const _component_TaskMobile = _resolveComponent("TaskMobile")!
  const _component_Text = _resolveComponent("Text")!

  return _withDirectives((_openBlock(), _createElementBlock("span", null, [
    (_ctx.tasks)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          (_ctx.tasks.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_ctx.tasks && !_ctx.isMobile())
                  ? (_openBlock(), _createBlock(_component_Box, {
                      key: 0,
                      class: "overflow-y",
                      height: "calc(100vh - 120px)"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Table, {
                          "col-width": "a,a,a,a,a,100%,a,a",
                          hover: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasks, (task) => {
                              return (_openBlock(), _createBlock(_component_Task, {
                                key: task.id,
                                model: task,
                                onCloseTask: _ctx.onCloseTask,
                                onEditTask: _ctx.onEditTask,
                                onUpdateTask: _ctx.onUpdateTask
                              }, null, 8, ["model", "onCloseTask", "onEditTask", "onUpdateTask"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.tasks && _ctx.isMobile())
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasks, (task) => {
                        return (_openBlock(), _createBlock(_component_TaskMobile, {
                          key: task.id,
                          model: task,
                          onCloseTask: _ctx.onCloseTask,
                          onEditTask: _ctx.onEditTask,
                          onUpdateTask: _ctx.onUpdateTask
                        }, null, 8, ["model", "onCloseTask", "onEditTask", "onUpdateTask"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_Box, { mp: "120" }),
                _createVNode(_component_Text, {
                  align: "center",
                  fontsize: "32px"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.i18n.t('tasks.No tasks for the selected filter')) + ". ", 1)
                  ]),
                  _: 1
                })
              ]))
        ]))
      : _createCommentVNode("", true)
  ], 512)), [
    [_vShow, !_ctx.loading]
  ])
}