/* CRC:A0E9FD1F:3 */
/* Реализация интерфейса ApiClientInterface */

import { ApiClientInterface } from '@/models/api-client/ApiClient.interface'
import apiMockClient from './mock'
import apiLiveClient from './live'
import { config } from '@/config'

let env: string = 'mock'
if (process.env && process.env.VUE_APP_API_CLIENT) {
  env = process.env.VUE_APP_API_CLIENT.trim()
}
// return either the live or the mock client
let apiClient: ApiClientInterface
if (config.apiClient.type === 'live') {
  apiClient = apiLiveClient
} else if (config.apiClient.type === 'mock') {
  apiClient = apiMockClient
} else {
  throw Error('Invalid or undefined config.apiClient.type')
}

export default apiClient
