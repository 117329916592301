/* CRC:C761B2F7:5 */
/* Все мутации приложения */

// group our constants in a namespace
import { LocalesMutationType} from '../locales/Locales.mutation.type'

import { TasksMutationType } from '../tasks/Tasks.mutation.type'
import { AuthMutationType } from '../auth/Auth.mutation.type'
import { TblMutationType } from '../tbl/Tbl.mutation.type'
import { MoneyMutationType } from '../money/Money.mutation.type'

export namespace RootMutationType {
  export const locales = LocalesMutationType

  export const tasks = TasksMutationType
  export const auth = AuthMutationType
  export const tbl = TblMutationType
  export const money = MoneyMutationType
}
