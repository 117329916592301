import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_OperEdit = _resolveComponent("OperEdit")!
  const _component_MoneyHeader = _resolveComponent("MoneyHeader")!
  const _component_MoneyMenu = _resolveComponent("MoneyMenu")!
  const _component_Col = _resolveComponent("Col")!
  const _component_OperList = _resolveComponent("OperList")!
  const _component_Box = _resolveComponent("Box")!
  const _component_Row = _resolveComponent("Row")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_Loader, { class: "text-align-left" }, null, 512), [
      [_vShow, _ctx.loading]
    ]),
    _createVNode(_component_OperEdit),
    _createVNode(_component_MoneyHeader, {
      onFlipMenu: _ctx.flipMenu,
      menu: _ctx.menu,
      class: "text-align-left"
    }, null, 8, ["onFlipMenu", "menu"]),
    _createVNode(_component_Container, { mp: "p-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_Row, null, {
          default: _withCtx(() => [
            (_ctx.menu.showMenu)
              ? (_openBlock(), _createBlock(_component_Col, {
                  key: 0,
                  col: "12",
                  md: "4",
                  border: "right",
                  mp: "ps-0 pt-3"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MoneyMenu, {
                      menu: _ctx.menu,
                      onChangeFilter: _ctx.changeFilter,
                      class: "text-align-left"
                    }, null, 8, ["menu", "onChangeFilter"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_Col, {
              col: "12",
              md: _ctx.menu.showMenu ? '8' : '12',
              mp: "pe-0"
            }, {
              default: _withCtx(() => [
                (!_ctx.error.status)
                  ? (_openBlock(), _createBlock(_component_OperList, {
                      key: 0,
                      opers: _ctx.opers,
                      menu: _ctx.menu,
                      loading: _ctx.loading,
                      onEditOper: _ctx.onOperEdit,
                      onNewOper: _ctx.onNewOper
                    }, null, 8, ["opers", "menu", "loading", "onEditOper", "onNewOper"]))
                  : _createCommentVNode("", true),
                (_ctx.error.status!=0)
                  ? (_openBlock(), _createBlock(_component_Box, {
                      key: 1,
                      class: "text-align-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Box, {
                          fontsize: "2em",
                          bold: "",
                          color: "darkred",
                          mp: "100"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.error.status) + " - " + _toDisplayString(_ctx.error.statusText), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Box, {
                          fontsize: "1.5em",
                          bold: "",
                          width: "100%",
                          mp: "60,100,0,100"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.error.msg), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["md"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}