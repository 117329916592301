/* CRC:EED9EB57:1 */
/* Обертка для хранилища методов аутентификации */

import { rootStore, dispatchModuleAction } from '../root'
import { RootModuleNames, AuthStateInterface } from '@/models'


const authStore = {
  get state(): AuthStateInterface {
    return rootStore.state.authState
  },

  action<T>(actionName: string, params?: T): void {
    dispatchModuleAction(RootModuleNames.authState, actionName, params)
  }
}

// export our wrapper using the composition API convention (i.e. useXYZ)
export const useAuthStore = () => {
  return authStore //as unknown as Store
}
