/* CRC:5BC5D1A3:4 */
/* Реализация интерфейса TblApiClientInterface */

import { TblApiClientUrlsInterface } from './TblApiClientUrls.interface'
import { TblApiClientInterface } from './TblApiClient.interface'
import { SerializedTblState, TblInterface, TblStateInterface } from '@/models'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'
import { useTblStore } from '@/store/tbl'
//import { config } from '@/config'


export class TblApiClientModel implements TblApiClientInterface {
  private readonly urls!: TblApiClientUrlsInterface
  private readonly apiVersion!: string

  constructor(urls: TblApiClientUrlsInterface, apiVersion: string) {
    this.urls = urls
    this.apiVersion = apiVersion
  }

  // ---------------------------------------------------------------------------
  fetchTbls(filters?: object): Promise<SerializedTblState> {
    const getParameters: HttpRequestParamsInterface = {
      url: this.urls.fetchTbls,
      version: this.apiVersion,
      payload: {}
    }

    const menu = useTblStore().state.menu
    getParameters.payload = {
      'id': menu.items[menu.currentIndex].id
    }
    return HttpClient.get<SerializedTblState>(getParameters)
  }

  // ---------------------------------------------------------------------------
  fetchTtbl(id: string): Promise<TblInterface> {
    const getParameters: HttpRequestParamsInterface = {
      url: `${this.urls.fetchTbl}/${id}`,
      version: this.apiVersion,
      payload: {}
    }
    return HttpClient.get<TblInterface>(getParameters)
  }

  // ---------------------------------------------------------------------------
  // TODO: вместо object нужно возвращать ошибку и наверное сохраненную задачу
  saveTbl(tbl: TblInterface): Promise<object> {
    const menu = useTblStore().state.menu
    let id = menu.items[menu.currentIndex].id
    const putParameters: HttpRequestParamsInterface = {
      url: `${this.urls.saveTbl}/${id}`,
      version: this.apiVersion,
      payload: {
        box_plus: tbl.box[0],
        box_minus: tbl.box[1],
        pack_plus: tbl.pack[0],
        pack_minus: tbl.pack[1],
        one_plus: tbl.one[0],
        one_minus: tbl.one[1],
        desc: tbl.desc,
      }
    }
    return HttpClient.put<object>(putParameters)
  }
}
