/* CRC:E902160:4 */
/* Реализация интерфейсов аутентификации */

// TODO: разделить файл - отдельно мутации, отдельно действия
import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import {
  RootMutationType,
  RootStateInterface,
  AuthStateInterface } from '@/models'

import { initialAuthState } from './initialState'
import { initialUser } from './initialAuth'

import apiClient from '@/api-client'
import { getCookie } from '@/utils'
import jwtDecode from 'jwt-decode'

// TODO: сделано для logoutUser - в будущем убрать
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios'


// =============================================================================
export const mutations: MutationTree<AuthStateInterface> = {
  updateUser(state: AuthStateInterface) {
    let token = getCookie('access_token')
    state.isLogged = Boolean(token)

    // -------------------------------------------------------------------------
    if (token) {
      let accessToken = (jwtDecode(token) as any).identity
      state.user.id = accessToken.id
      state.user.email = accessToken.email
      state.user.enabled = accessToken.enabled
      state.user.f = accessToken.last_name
      state.user.i = accessToken.first_name
      if (accessToken.roles.indexOf(',') != -1) {
        state.user.roles = accessToken.roles.split(',')
      } else {
        state.user.roles = [accessToken.roles]
      }

    // -------------------------------------------------------------------------
    } else {
      state.user = JSON.parse(JSON.stringify(initialUser))
    }
  },
}

// =============================================================================
export const actions: ActionTree<AuthStateInterface, RootStateInterface> = {
  // ---------------------------------------------------------------------------
  updateUser({ commit }) {
    apiClient.auth.refreshTokens()
      .then(() => {
        commit(RootMutationType.auth.updateUser)
      })
      .catch((error: object)=> {
        console.log("ERROR", error)
      })
  },

  async logoutUser({ commit }) {
    // =========================================================================
    // =========================================================================
    // =========================================================================
    // =========================================================================
    // =========================================================================
    // TODO: ИСПРАВИТЬ (обращения к axios отсюда быть не должно)
    // TODO: почему то сразу после logout идет refresh и токены сначало
    // TODO: удаляются, а потом сразу пишутся снова. Такое ощущение, что
    // TODO: приходят 2 запроса в одном нет куки, а в другом есть
    await Axios.get('/auth/logout')
    // =========================================================================
    // =========================================================================
    // =========================================================================
    // =========================================================================
    // =========================================================================
    await apiClient.auth.logoutUser()
      .then(() => {
      })
      .catch((error: object)=> {
        console.log("ERROR", error)
      })
    commit(RootMutationType.auth.updateUser)
  },

  loginUser({ commit }) {
    apiClient.auth.loginUser()
      .then(() => {
        commit(RootMutationType.auth.updateUser)
      })
      .catch((error: object)=> {
        console.log("ERROR", error)
      })
  },
}

// =============================================================================
const namespaced: boolean = true
const state: AuthStateInterface = initialAuthState

export const authState: Module<AuthStateInterface, RootStateInterface> = {
  namespaced,
  state,
  actions,
  mutations
}
