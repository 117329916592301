import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Radio = _resolveComponent("Radio")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getValues, (option) => {
    return (_openBlock(), _createBlock(_component_Radio, {
      key: option.value,
      caption: option.text,
      inline: "",
      disabled: _ctx.disabled,
      value: option.value,
      modelValue: _ctx.cur_value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cur_value) = $event)),
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      group: _ctx.group
    }, null, 8, ["caption", "disabled", "value", "modelValue", "group"]))
  }), 128))
}