
import { computed, defineComponent } from "vue"

export default defineComponent({
  name: "Col",
  inheritAttrs: true,
  props: {
    col: { type: String },
    sm:  { type: String },
    md:  { type: String },
    lg:  { type: String },
    xl:  { type: String },
    offset:   { type: String },
    offsetSm: { type: String },
    offsetMd: { type: String },
    offsetLg: { type: String },
    offsetXl: { type: String },
    auto: {
      type: Boolean,
      default: false
    },
    top: { type: Boolean, default: false },
    bottom: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
    vcenter: { type: Boolean, default: false },
    left: { type: Boolean, default: false },
    right: { type: Boolean, default: false },
    mp: { type: String, default: '' },
    color: { type: String, default: '' },

    border: { type: String, default: '' },

},

  setup(props, { attrs }) {
    const cssClass = computed(() => {
      let classes = [
        props.col ? "col-" + props.col : "",
        props.sm ?  "col-sm-" + props.sm : "",
        props.md ?  "col-md-" + props.md : "",
        props.lg ?  "col-lg-" + props.lg : "",
        props.xl ?  "col-xl-" + props.xl : "",
        !props.col && !props.sm && !props.md && !props.lg && !props.xl
          ? "col"
          : "",
        props.offset ?   "offset-" + props.offset : "",
        props.offsetSm ? "offset-sm-" + props.offsetSm : "",
        props.offsetMd ? "offset-md-" + props.offsetMd : "",
        props.offsetLg ? "offset-lg-" + props.offsetLg : "",
        props.offsetXl ? "offset-xl-" + props.offsetXl : "",
        props.auto ?     "col-auto" : "",
        props.top ?      "align-self-start" : "",
        props.bottom ?   "align-self-end" : "",
        props.vcenter ?  "align-self-center" : "",
        props.center ?   "d-flex align-items-center justify-content-center":"",
        props.left ?   "d-flex justify-content-start" : "",
        props.right ?  "d-flex justify-content-end" : "",
        props.mp ?  props.mp : "",

        props.border ?  `border-${props.border}` : "",
      ]
      return classes.filter(Boolean).join(' ')
    })

    const cssStyle = computed(() => {
      let styles = ""
      if (props.color)
      {
        styles = `color: ${props.color};`
      }
      return styles
    })

    return {
      cssClass,
      cssStyle,
      props,
      attrs
    }
  }
})
