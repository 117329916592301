/* CRC:6C244AB8:3 */
/* Фиктивная реализация интерфейса ApiClientInterface */

import { ApiClientInterface } from '@/models/api-client/ApiClient.interface'
import tasksApiClient from './tasks'
import authApiClient from './auth'
import tblApiClient from './tbl'
import moneyApiClient from './money'

// create an instance of our main ApiClient that wraps the mock child clients
const apiMockClient: ApiClientInterface = {
  tasks: tasksApiClient,
  auth: authApiClient,
  tbl: tblApiClient,
  money: moneyApiClient
}
// export our instance
export default apiMockClient
