/* CRC:93F8005A:6 */
/* Обертка для хранилища задач */

import { rootStore, dispatchModuleAction } from '../root'
import { RootModuleNames, TasksStateInterface } from '@/models'

/**
* @name itemsStore
* @description
* The tasks store wrapper that returns the tasksState and exposes a generic
* action<T> method
*/

const tasksStore = {
  get state(): TasksStateInterface {
    return rootStore.state.tasksState
  },

  action<T>(actionName: string, params?: T): void {
    dispatchModuleAction(RootModuleNames.tasksState, actionName, params)
  }
}

// export our wrapper using the composition API convention (i.e. useXYZ)
export const useTasksStore = () => {
  return tasksStore //as unknown as Store
}
