
import { defineComponent, computed, PropType, ref, onMounted } from "vue"

import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import 'dayjs/locale/en'
import utc from 'dayjs/plugin/utc'

import { useI18n } from 'vue-i18n'
import { TaskInListInterface } from "@/models/tasks/Task.interface"
import { Container, Row, Col, Checkbox, Edit, FormGroup, FormLabel, DatePicker,
  TimePicker, Tabs, Tab, Table, Btn, Image, Text, Box }
  from '@/components/base-ui'
import '@/components/base-ui/common.scss'


export default defineComponent({
  name: 'Task',
  // ---------------------------------------------------------------------------
  props: {
    model: {
      type: Object as PropType<TaskInListInterface>,
    },
  },
  // ---------------------------------------------------------------------------
  components: {
    Container, Row, Col, Checkbox, Edit, FormGroup, FormLabel, DatePicker,
    TimePicker, Tabs, Tab, Table, Btn, Image, Text, Box
  },
  // ---------------------------------------------------------------------------
  emits: ['closeTask', 'editTask', 'updateTask'],
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {
    const i18n = useI18n()
    const mouseOnThis = ref(false)
    const subjFocused = ref(false)

    var taskDate = ref(dayjs(props.model?.date).format('YYYY-MM-DD'))
    var taskTime = ref(dayjs(props.model?.date).format('HH:mm'))
    if (!props.model?.closed && (
      !props.model?.is_deadline_date || !props.model?.is_deadline_time)) {
      //taskTime.value = "--:--"
      taskTime.value = ""
    }

    // -------------------------------------------------------------------------
    const onClickClose = () => {
      emit('closeTask', props.model?.id)
    }

    // -------------------------------------------------------------------------
    const subjStyle = () => {
      return props.model?.style
    }

    // -------------------------------------------------------------------------
    const subjColor = () => {
      var color = 'black'
      if (props.model?.style) {
        if (props.model.style.indexOf('color') != -1) {
          color = props.model.style.split(';')[0].split(':')[1]
        }
      }
      return color
    }

    // -------------------------------------------------------------------------
    const subjBold = () => {
      var bold = false
      if (props.model?.style) {
        bold = props.model.style.indexOf('font-weight: bold') != -1
      }
      return bold
    }

    // -------------------------------------------------------------------------
    const onClickEdit = () => {
      emit('editTask', props.model?.id)
    }

    var begSubj = '' // для отмены ввода (если будет введено пустой значение)

    // -------------------------------------------------------------------------
    const updateTask = () => {
      // TODO: иногда данная процедура вызывается 2 раза, сделать проверку
      // TODO: перед сохранением (prevSubj != subj && prevDate != date)
      if (!props.model) {
        return
      }
      if (!props.model.subj.trim().length) {
        props.model.subj = begSubj
      }

      var task: {[key: string]: any} = {
        subj: props.model.subj,
        id: props.model.id
      }

      // установка срока
      let isDeadlineDate = Boolean(taskDate.value)
      let isDeadlineTime = Boolean(taskTime.value) && Boolean(taskDate.value)
      if (isDeadlineDate) {
        dayjs.extend(utc)
        var dt = dayjs(taskDate.value)
        if (isDeadlineTime) {
          dt = dayjs(taskDate.value + " " + taskTime.value)
        }
        task['deadline'] = dt.utc().format()
        task['is_deadline_date'] = isDeadlineDate
        task['is_deadline_time'] = isDeadlineTime
      }

      emit('updateTask', task)
    }

    // -------------------------------------------------------------------------
    onMounted(() => {
      begSubj = props.model?.subj || ''
    });

    // -------------------------------------------------------------------------
    return {
      i18n,
      onClickClose,
      onClickEdit,
      subjStyle,
      subjColor,
      subjBold,
      subjFocused,
      mouseOnThis,
      taskDate,
      taskTime,
      dayjs,
      updateTask,
    };
  },
});
