/* CRC:352B2206:1 */
/* Фиктивная реализация интерфейса MoneyApiClientInterface */

// TODO: может стоит именно здесь делать логику, а не в [...]ApiClient.model

import {
  MoneyApiClientUrlsInterface,
  MoneyApiClientInterface,
  MoneyApiClientModel
} from '@/models/api-client/money'
import { config } from '@/config'

const urls: MoneyApiClientUrlsInterface = config.api.money.urls
const apiVersion: string = config.api.money.version


const moneyApiClient:
     MoneyApiClientInterface = new MoneyApiClientModel(urls, apiVersion)

// export our instance
export default moneyApiClient
