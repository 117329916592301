
import { defineComponent, computed, onMounted } from 'vue'
import { useRouter, useLink } from "vue-router"
import { useTasksStore } from '@/store/tasks'
import TasksListComponent from '@/components/tasks/TasksList.vue'
import TaskEdit from '@/components/tasks/TaskEdit.vue'
import TaskClose from '@/components/tasks/TaskClose.vue'
import {
  Container, Box, Image, Card, Btn, Row, Col, Text }from '@/components/base-ui'

import dayjs from 'dayjs'
import { getTextDate, isMobile } from '@/components/base-ui'
import { checkAccess } from '@/utils'

export default defineComponent({
  name: 'Dashboard',
  // ---------------------------------------------------------------------------
  components: {
    TasksListComponent,
    TaskEdit,
    TaskClose,
    Container, Box, Image, Card, Btn, Row, Col, Text
  },

  // ---------------------------------------------------------------------------
  setup() {
    const tasksStore = useTasksStore()
    const router = useRouter()

    // -------------------------------------------------------------------------
    const count_today = computed(() => {
      return tasksStore.state.count_today
    })

    // -------------------------------------------------------------------------
    const count_all = computed(() => {
      return tasksStore.state.count_all
    })

    // -------------------------------------------------------------------------
    const goTasks = () => {
      router.push({name: 'Tasks'})
    }

    // -------------------------------------------------------------------------
    const goNote = () => {
      router.push({name: 'Note'})
    }

    // -------------------------------------------------------------------------
    const goAuth = () => {
      window.location.href = '/auth/login'
    }

    // -------------------------------------------------------------------------
    const accessEnabled = (roles: string) => {
      return checkAccess(roles)
    }

    // -------------------------------------------------------------------------
    onMounted(() => {
    })

   // -------------------------------------------------------------------------
    return {
      dayjs,
      accessEnabled,
      getTextDate,
      count_today,
      count_all,
      goTasks,
      goNote,
      goAuth
    }
  }
})
