/* CRC:B63495C9:3 */
/* Реальная реализация интерфейса ApiClientInterface */

import { ApiClientInterface } from '@/models/api-client/ApiClient.interface'
import tasksApiClient from './tasks'
import authApiClient from './auth'
import tblApiClient from './tbl'
import moneyApiClient from './money'

// create an instance of our main ApiClient that wraps the mock child clients
const apiLiveClient: ApiClientInterface = {
  tasks: tasksApiClient,
  auth: authApiClient,
  tbl: tblApiClient,
  money: moneyApiClient
}
// export our instance
export default apiLiveClient
