
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "Container",
  inheritAttrs: true,
  props: {
    sm: { type: Boolean, default: false },
    md: { type: Boolean, default: false },
    lg: { type: Boolean, default: false },
    xl: { type: Boolean, default: false },
    xxl: { type: Boolean, default: false },
    fluid: { type: Boolean, default: false },
    mp: { type: String, default: '' },
    height: { type: String, default: '' },
  },
  setup(props, { attrs }) {

    const cssClass = computed(() => {
      var suffix = ""
      suffix = props.sm ? "-sm" : ""
      suffix = props.md ? "-md" : ""
      suffix = props.lg ? "-lg" : ""
      suffix = props.xl ? "-xl" : ""
      suffix = props.xxl ? "-xxl" : ""
      suffix = props.fluid ? "-fluid" : ""
      if (props.mp) {
        suffix += " " + props.mp
      }
      return "container" + suffix
    })

    const cssStyle = computed(() => {
      if (props.height) {
        return `height: ${props.height};`
      }
      return ""
    })

    return {
      cssClass,
      props,
      attrs,
      cssStyle
    };
  }
})
