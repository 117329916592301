

import { defineComponent, PropType, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { TasksMenuInterface } from '@/models/tasks/TasksState.interface'
import { Table, Container, Row, Col, DatePicker, FormGroup, Btn, Box,
        Checkbox, Select  } from '@/components/base-ui'
import { isMobile } from '@/components/base-ui'
import { getImage } from '@/utils'
import '@/components/base-ui/common.scss'


interface NavPage {
  page: string
  url: string
}

export default defineComponent({
  name: "TasksHeader",
  // ---------------------------------------------------------------------------
  components: {
    Container, Row, Col, DatePicker, Checkbox, FormGroup, Btn, Box,
    Table, Select
  },
  // ---------------------------------------------------------------------------
  props: {
    menu: {
      type: Object as PropType<TasksMenuInterface>,
    },
    navPages: { type: Array as PropType<NavPage[]>, default: [] }
  },
  // ---------------------------------------------------------------------------
  emits: ["flipMenu", "changePage"],
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {
    const i18n = useI18n()

    // -------------------------------------------------------------------------
    const captionFilter = computed(() => {
      let caption = i18n.t('tasks.Current filter') + ': '
      if (props.menu) {
        let desc = i18ru(props.menu.items[props.menu.currentIndex].desc)
        caption += `<b>${desc}</b>`
        caption += props.menu.showOK ? ` (${i18n.t('tasks.ALL')})` : ''
        caption += props.menu.isSearch ? ` [S: ${props.menu.searchText}]` : ''
      } else {
        caption += '<b>???????</b>'
      }
      return caption
    })

    // -------------------------------------------------------------------------
    const flipMenu = (index: number) => {
      emit("flipMenu", {currentIndex: index})
    }

    // -------------------------------------------------------------------------
    // TODO: текст пунктов меню должен приходить с API на выбранном языке
    const i18ru = (txt: string) => {
      if (txt.startsWith("На дату")) {
        return i18n.t('tasks.На дату') + txt.substring(7)
      }
      if (txt.startsWith("Загрузка")) {
        return i18n.t('tasks.Загрузка') + txt.substring(8)
      }
      return i18n.t('tasks.' + txt)
    }

    // -------------------------------------------------------------------------
    const changePage = (url: string) => {
      emit("changePage", url)
    }

    // -------------------------------------------------------------------------
    return {
      i18n,
      isMobile,
      getImage,
      captionFilter,
      flipMenu,
      changePage
    }
  }
  // ---------------------------------------------------------------------------
})
