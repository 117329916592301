/* CRC:CDAB8963:10 */
/* Экземпляр TasksStateInterface с начальными значениями */


import { TaskInterface, TaskInListInterface } from '@/models'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'


dayjs.extend(utc)


export const initialTask: TaskInterface = {
  id: 'no-id',
  subj: '',
  date_input: '',
  date_action: '',
  status: {
    id: '',
    name: '',
    sign_close_task: false
  },
  prioritet: {
    id: '',
    name: '',
  },
  important: false,
  is_deadline_date: true,
  is_deadline_time: true,
  // дата,время = сейчас + 1 час
  deadline: dayjs(new Date((new Date()).getTime() + 60*60*1000)).utc().format(),
  subsystem: 'WIGM.TASKS.newTask() v.2',
  style: { color: 'black', bold: false },
  content: '',
  in_wait: false,
  wait: [],
  repeat: {
    repeat: false,
    mode: "1",
    time: "10:00:00",
    numDay: 1,
    month: "1",
    week: [],
    days: 1,
    hours: 0,
    minutes: 0
  },
  sync_status: '',
  to_jrnl: false,
  remind: { remind: false, days: 0, hours: 0, minutes: 0 }
}

export const initialTaskInList: TaskInListInterface = {
  id: '',
  subj: '',
  date: '',
  closed: false,
  prioritet: 'NORMAL',
  important: false,
  in_wait: false,
  wait: '',
  style: '',
  repeat: '',
  is_deadline_date: true,
  is_deadline_time: true
}
