
import { defineComponent, computed, onMounted } from 'vue'
import { useTblStore } from '@/store/tbl'
import Loader from '@/components/shared/Loader.vue'
import TblList from '@/components/tbl/TblList.vue'
import TblEdit from '@/components/tbl/TblEdit.vue'
import TblMenu from '@/components/tbl/TblMenu.vue'
import TblHeader from '@/components/tbl/TblHeader.vue'
import { TblInterface } from '@/models/tbl/Tbl.interface'
import { RootMutationType } from '@/models'

import { Container, Row, Col, Checkbox, FormGroup, FormLabel, DatePicker,
  TimePicker, Tabs, Tab, Table, Btn, isMobile, Box } from '@/components/base-ui'

import { useI18n } from 'vue-i18n'
import '@/components/base-ui/common.scss'

interface Param {
    [key:string]: any;
}


export default defineComponent({
  name: 'Tbls',
  // ---------------------------------------------------------------------------
  components: {
    TblList,
    TblEdit,
    TblMenu,
    TblHeader,
    Loader,
    Container, Row, Col, Checkbox, FormGroup, FormLabel, DatePicker, Box,
    TimePicker, Tabs, Tab, Table, Btn
  },

  // ---------------------------------------------------------------------------
  setup() {
    const i18n = useI18n()
    const tblStore = useTblStore()

    // -------------------------------------------------------------------------
    const tbls = computed(() => {
      return tblStore.state.items
    })

    // -------------------------------------------------------------------------
    const currentTbl = computed(() => {
      return tblStore.state.currentItem
    })

    // -------------------------------------------------------------------------
    const menu = computed(() => {
      return tblStore.state.menu
    })

    // -------------------------------------------------------------------------
    const error = computed(() => {
      return tblStore.state.error
    })

    // -------------------------------------------------------------------------
    const showEdit = computed(() => {
      return tblStore.state.showEdit
    })

    // -------------------------------------------------------------------------
    const loading = computed(() => {
      return tblStore.state.loading
    })

    // -------------------------------------------------------------------------
    onMounted(() => {
      tblStore.action(RootMutationType.tbl.loadTbls)
    })

    // -------------------------------------------------------------------------
    const flipMenu = (filter: Param) => {
      tblStore.action(RootMutationType.tbl.updateMenu, {
        showMenu: !tblStore.state.menu.showMenu
      })
    }

    // -------------------------------------------------------------------------
    const changeFilter = (id: string) => {
      tblStore.action(RootMutationType.tbl.changeFilter,
        {currentIndex: id, isMobile: isMobile()})
    }

    // -------------------------------------------------------------------------
    const onIncTbl = (id: string) => {
      tblStore.action(RootMutationType.tbl.incTbl, {currentIndex: id})
    }

    // -------------------------------------------------------------------------
    const onSaveItem = (tbl: TblInterface) => {
      tblStore.action(RootMutationType.tbl.saveTbl, {tbl: tbl})
    }

    // -------------------------------------------------------------------------
    const onCancelEdit = () => {
      tblStore.action(RootMutationType.tbl.cancelEditTbl)
    }

    // -------------------------------------------------------------------------
    const onItemEdit = (id: string) => {
      tblStore.action(RootMutationType.tbl.loadTbl, {currentIndex: id})
    }

    // -------------------------------------------------------------------------
    return {
      i18n,
      tbls,
      loading,
      showEdit,
      onSaveItem,
      onCancelEdit,
      menu,
      changeFilter,
      flipMenu,
      onItemEdit,
      onIncTbl,
      error,
      currentTbl
    }
  }
})
