
import { defineComponent, PropType, ref, onMounted } from "vue"
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { getImage } from '@/utils'
import { Row, Col, Box } from '@/components/base-ui'
import { TblInterface } from "@/models/tbl/Tbl.interface"
import '@/components/base-ui/common.scss'


export default defineComponent({
  name: "Tbl",
  // ---------------------------------------------------------------------------
  props: {
    model: {
      type: Object as PropType<TblInterface>,
    },
  },
  // ---------------------------------------------------------------------------
  components: {
    Row, Col, Box
  },
  // ---------------------------------------------------------------------------
  emits: ['editTbl'],
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {
    const i18n = useI18n()
    const mouseOnThis = ref(false)

    const background = (desc: string) => {
      return desc.trim().length > 0 ? ' lightblue' : 'inherit'
    }

    // -------------------------------------------------------------------------
    onMounted(() => {
    });

    // -------------------------------------------------------------------------
    return {
      i18n,
      background,
      getImage,
      mouseOnThis,
      dayjs,
    };
  },
});
