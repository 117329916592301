
import '@/components/base-ui/common.scss'
import { defineComponent, onMounted, ref, computed, watch, PropType } from 'vue'

import Loader from '@/components/shared/Loader.vue'
import * as ui from '@/components/base-ui'

import { useTblStore } from '@/store/tbl'
import { RootMutationType, TblInterface } from '@/models'
import { useI18n } from 'vue-i18n'
import { isMobile } from '@/components/base-ui'
import { getImage } from'@/utils'


export default defineComponent({
  name: 'TblEdit',
  props: {
    msg: String,
    tbl: {
      type: Object as PropType<TblInterface>,
    },
  },
  // TODO: удалить неиспользуемые компоненты
  components: {
    Loader,

    // layout
    Container: ui.Container,
    Row: ui.Row,
    Col: ui.Col,
    Table: ui.Table,
    Box: ui.Box,

    // forms
    Number: ui.Number,
    Textarea: ui.Textarea,
    FormGroup: ui.FormGroup,
    FormLabel: ui.FormLabel,

    // components
    Btn: ui.Btn,
    Text: ui.Text,

    // modal
    ModalBody: ui.ModalBody,
    ModalTitle: ui.ModalTitle,
    ModalFooter: ui.ModalFooter,
    ModalHeader: ui.ModalHeader,
    Modal: ui.Modal,
  },
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {

    const i18n = useI18n()
    const tblStore = useTblStore()

    // -------------------------------------------------------------------------
    const loading = computed(() => {
      return tblStore.state.loading
    })

    // -------------------------------------------------------------------------
    const showEdit = computed(() => {
      return tblStore.state.showEdit
    })

    // -------------------------------------------------------------------------
    watch (
      () => tblStore.state.currentItem,
      () => {
        // TODO: что то там делаем
    })
    watch (() => tblStore.state.showEdit,
           () => { isChangeData.value = false })

    // -------------------------------------------------------------------------
    // TODO: "НЕУКЛЮЖЕЕ" решение (во все элементы пришлось добавить @input)
    // TODO: и кучу watch (там где @input не работает)
    const isChangeData = ref(false)
    const changeData = (element: string) => {
      isChangeData.value = true
    }

    // -------------------------------------------------------------------------
    const enabledSave = computed(() => {
      return isChangeData.value
    })

    // -------------------------------------------------------------------------
    const saveTbl = () => {
      tblStore.action(RootMutationType.tbl.saveTbl)
    }

    // -------------------------------------------------------------------------
    const cancelEdit = () => {
      tblStore.action(RootMutationType.tbl.cancelEditTbl)
    }

    // -------------------------------------------------------------------------
    onMounted(() => {  // TODO: может убрать ??
      //tasksStore.action(RootMutationType.tasks.loadTask, '---')
      //tasksStore.action(RootMutationType.tasks.cancelEditTask)
    });

    // -------------------------------------------------------------------------
    return {
      i18n,
      isMobile,
      getImage,
      loading,
      showEdit,
      saveTbl,
      cancelEdit,
      changeData,
      enabledSave,
    }
  }
})
