
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "ModalFooter",
  setup(props, { attrs }) {
    const className = computed(() => {
      return ["modal-footer"];
    });

    return {
      className,
      attrs,
      props
    };
  }
})
