/* CRC:4221A4D7:3 */
/* Экземпрляр LocalesStateInterface */

import { LocalesStateInterface } from '@/models'
/**
* @name initialLocalesState
* @description
* The Locales state instance
*/
export const initialLocalesState: LocalesStateInterface = {
  availableLocales: [
    {
      name: 'USA',
      locale: 'en-US',
      flag: 'us',
      selected: false
    },
    {
      name: 'Russian',
      locale: 'ru-RU',
      flag: 'ru',
      selected: true // this is selected by default
    }
  ]
}
