/* CRC:C78691F2:1 */
/* Фиктивная реализация интерфейса AuthApiClientInterface */


// TODO: может стоит именно здесь делать логику, а не в [...]ApiClient.model

import {
  AuthApiClientUrlsInterface,
  AuthApiClientInterface,
  AuthApiClientModel
} from '@/models/api-client/auth'
import { config } from '@/config'

const urls: AuthApiClientUrlsInterface = config.api.auth.urls
const apiVersion: string = config.api.auth.version


const authApiClient:
AuthApiClientInterface = new AuthApiClientModel(urls, apiVersion)

// export our instance
export default authApiClient
