/* CRC:9AA57526:4 */
/* Реализация интерфейса MoneyApiClientInterface */

import { MoneyApiClientUrlsInterface } from './MoneyApiClientUrls.interface'
import { MoneyApiClientInterface } from './MoneyApiClient.interface'
import {
  SerializedMoneyOper, MoneyOperInterface, SerializedMoneyState
} from '@/models'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'
import { useMoneyStore } from '@/store/money'


export class MoneyApiClientModel implements MoneyApiClientInterface {
  private readonly urls!: MoneyApiClientUrlsInterface
  private readonly apiVersion!: string

  constructor(urls: MoneyApiClientUrlsInterface, apiVersion: string) {
    this.urls = urls
    this.apiVersion = apiVersion
  }

  // ---------------------------------------------------------------------------
  fetchOpers(filters?: object): Promise<SerializedMoneyState> {
    const getParameters: HttpRequestParamsInterface = {
      url: this.urls.fetchOpers,
      version: this.apiVersion,
      payload: {}
    }

    const menu = useMoneyStore().state.menu
    getParameters.payload = {
      'id': menu.items[menu.currentIndex].id
    }
    return HttpClient.get<SerializedMoneyState>(getParameters)
  }

  // ---------------------------------------------------------------------------
  fetchOper(id: string): Promise<SerializedMoneyOper> {
    const getParameters: HttpRequestParamsInterface = {
      url: `${this.urls.fetchOper}/${id}`,
      version: this.apiVersion,
      payload: {}
    }
    return HttpClient.get<SerializedMoneyOper>(getParameters)
  }

  // ---------------------------------------------------------------------------
  // TODO: вместо object нужно возвращать ошибку и наверное сохраненную задачу
  saveOper(oper: MoneyOperInterface): Promise<object> {

    let id = oper.id

    const putParameters: HttpRequestParamsInterface = {
      url: `${this.urls.saveOper}/${id}`,
      version: this.apiVersion,
      payload: {'operation': oper}
    }
    return HttpClient.put<object>(putParameters)
    }
}
