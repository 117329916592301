/* CRC:4BD38C5B:24 */
/* Реализация интерфейсов хранилища задач */

// TODO: разделить файл - отдельно мутации, отдельно действия

import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'

import {
  RootMutationType,
  RootStateInterface,
  TasksStateInterface,
  SerializedTasksState,
  SerializedTask,
  TaskInterface,
  TaskErrorInterface,
  TasksMenuInterface,
  TaskWaitGroupsInterface
} from '@/models'

import { initialTasksState } from './initialState'
import { initialTask } from './initialTask'
import { getCookie, doesHttpOnlyCookieExist } from '@/utils'
import dayjs from 'dayjs'

import apiClient from '@/api-client'


// =============================================================================
export const mutations: MutationTree<TasksStateInterface> = {
  loadingTasks(state: TasksStateInterface) {
    state.error =  { status: 0, statusText: '', msg: '' }
    state.loading = true
    state.tasks = []
  },
  loadedTasks(state: TasksStateInterface, data: SerializedTasksState) {
    state.tasks = data.data
    state.count_overdue = data.count_overdue
    state.navigation = {
      'total': data.total,
      'pages': data.pages,
      'current_page': data.current_page,
      'next': data.next,
      'prev': data.prev,
      'nav_pages': data.nav_pages
    }
    state.menu.items = data.menu
    state.loading = false
  },
  selectTask(state: TasksStateInterface, params: {
    id: string
    in_wait: boolean
  }) {
    const { id, in_wait } = params
    const task = state.tasks.find(o => o.id === id)
    if (task) {
      task.in_wait = in_wait
    }
  },
  /*     TODO: оставлено пока для примера поиска
  editTask(state: TasksStateInterface, params: { id: string }) {
    state.showEdit = !state.showEdit
    state.currentTask = initialTask
    const task = state.tasks.find(o => o.id === params.id)
    if (task) {
      state.currentTask = task
    }
    //state.currentTask.id = params.id
  }*/
  loadingTask(state: TasksStateInterface) {
    state.currentTask = initialTask
    state.showEdit = true
    state.loadingTask = true
  },
  loadedTask(state: TasksStateInterface, data: SerializedTask) {
    state.currentTask = data.task
    state.statuses = data.statuses
    state.prioritets = data.prioritets
    state.wait_groups = data.wait_groups || []
    state.loadingTask = false
  },
  cancelEditTask(state: TasksStateInterface) {
    state.showEdit = false
  },
  // TODO: не доделано
  saveTask(state: TasksStateInterface, task: TaskInterface) {
    // TODO: task не нужно передавать (изменения уже в хранилище)
    //  хотя наверное все таки лучше передавать явно
    //console.log('MUTATIONS: saveTask')
    //console.log('BEGIN: mutations:saveTask')
    /*
    if (state.currentTask) {
      console.log(state.currentTask.id, '--------', state.currentTask.subj)
    }
    console.log(task.id, '--------', task.subj)
    console.log({...task})
    */
    state.showEdit = false
    //console.log('END: mutations:saveTask')
  },
  // ===========================================================================
  closingTask(state: TasksStateInterface) {
    //console.log('MUTATIONS: closingTask')
    state.currentTask = initialTask
    state.showCloseTask = true
    state.loadingTask = true
  },
  // ---------------------------------------------------------------------------
  closeTask(state: TasksStateInterface, task: TaskInterface) {
    //console.log('MUTATIONS: closeTask', task)
    state.showCloseTask = false
  },
  // ---------------------------------------------------------------------------
  cancelClose(state: TasksStateInterface) {
    //console.log('MUTATIONS: cancelClose')
    state.showCloseTask = false
  },
  // ---------------------------------------------------------------------------
  removeTask(state: TasksStateInterface, task: TaskInterface) {
    //console.log('MUTATIONS: removeTask', task)
    state.showCloseTask = false
  },
  // ---------------------------------------------------------------------------
  deleteTask(state: TasksStateInterface, task: TaskInterface) {
    //console.log('MUTATIONS: deleteTask', task)
    state.showCloseTask = false
  },
  // ===========================================================================
  setCountOverdue(state: TasksStateInterface,
                  data: {
                    count_overdue: number,
                    count_today?: number,
                    count_all?: number} ) {
    state.count_overdue = data.count_overdue
    state.count_today = data.count_today ? data.count_today: 0
    state.count_all = data.count_all ? data.count_all: 0
  },

  // ===========================================================================
  updateError(state: TasksStateInterface, error: TaskErrorInterface ) {
    state.error = error
  },

  // ===========================================================================
  updateMenu(state: TasksStateInterface, menu: TasksMenuInterface ) {
    if (menu && menu.items != undefined) {
      state.menu.items = menu.items
    }
    if (menu && menu.currentIndex != undefined) {
      state.menu.currentIndex = menu.currentIndex >= 0 ? menu.currentIndex : 0
    }
    if (menu && menu.showOK != undefined) {
      state.menu.showOK = menu.showOK;
    }
    if (menu && menu.isSearch != undefined) {
      state.menu.isSearch = menu.isSearch;
    }
    if (menu && menu.searchText != undefined) {
      state.menu.searchText = menu.searchText;
    }
    if (menu && menu.showMenu != undefined) {
      state.menu.showMenu = menu.showMenu;
    }
    if (menu && menu.date != undefined) {
      state.menu.date = menu.date;
    }
    // корректируем текущий элемент (в основном необходимо при возникновении
    //  просроченных задач)
    if (state.menu.currentIndex >= state.menu.items.length){
      state.menu.currentIndex = 0
    }
  },
}

// =============================================================================
export const actions: ActionTree<TasksStateInterface, RootStateInterface> = {
  // ---------------------------------------------------------------------------
  loadTasks({ commit, dispatch }, filter) {
    commit(RootMutationType.tasks.loadingTasks)

    apiClient.tasks.fetchTasks(filter).then((data: SerializedTasksState) => {
      commit(RootMutationType.tasks.loadedTasks, data)
      commit(RootMutationType.tasks.updateMenu)

      // чтобы обновить кол-во задач на начальной сранице
      dispatch(RootMutationType.tasks.checkOverdue)
    })
    // TODO: сделать хранение ошибки вне подсистемы и обновлять ошибку
    // TODO: нужно на APIClient (может вообще на HTTPClient)
    .catch(err => {
      commit(RootMutationType.tasks.updateError, {
        status: err.response.status,
        statusText: err.response.statusText,
        msg: err.response.data.error,
    })})
  },
  // ---------------------------------------------------------------------------
  selectTask({ commit }, params: { id: string, selected: boolean }) {
    commit(RootMutationType.tasks.selectTask, params)
  },
  // ---------------------------------------------------------------------------
  loadTask({ commit }, id) {
  commit(RootMutationType.tasks.loadingTask)
    apiClient.tasks.fetchTask(id).then((
      data: SerializedTask) => {
        commit(RootMutationType.tasks.loadedTask, data)
    })
    // TODO: сделать хранение ошибки вне подсистемы и обновлять ошибку
    // TODO: нужно на APIClient (может вообще на HTTPClient)
    .catch(err => {
      commit(RootMutationType.tasks.updateError, {
        status: err.response.status,
        statusText: err.response.statusText,
        msg: err.response.data.error,
    })})
  },
  // ---------------------------------------------------------------------------
  cancelEditTask({ commit }) {
    commit(RootMutationType.tasks.cancelEditTask)
  },
  // ---------------------------------------------------------------------------
  // async наверно стоит
  saveTask({ commit, dispatch }, data: {task: TaskInterface, action?: string,
                                 wait_delete?: TaskWaitGroupsInterface[] }) {

    apiClient.tasks.saveTask(data.task, data.action, data.wait_delete)
      .then((data: object) => {
        //console.log("SAVE OK", data)
        dispatch(RootMutationType.tasks.loadTasks)
      })
      // TODO: сделать хранение ошибки вне подсистемы и обновлять ошибку
      // TODO: нужно на APIClient (может вообще на HTTPClient)
      .catch(err => {
        commit(RootMutationType.tasks.updateError, {
          status: err.response.status,
          statusText: err.response.statusText,
          msg: err.response.data.error,
      })})
    commit(RootMutationType.tasks.saveTask, data.task)
  },

  // ===========================================================================
  // TODO: если не ошибаюсь задачу можно не грузить (т.е. не нужен fetchTask),
  // TODO: поле repeat вроде есть в списке задач
  // TODO: соотв. можно просто записать значение id в currentTask
  // TODO: не забыть только после выставить state.loadingTask = false
  closingTask({ commit }, id) {
    commit(RootMutationType.tasks.closingTask)
    apiClient.tasks.fetchTask(id).then((
      data: SerializedTask) => {
        commit(RootMutationType.tasks.loadedTask, data)
    })
    // TODO: сделать хранение ошибки вне подсистемы и обновлять ошибку
    // TODO: нужно на APIClient (может вообще на HTTPClient)
    .catch(err => {
      commit(RootMutationType.tasks.updateError, {
        status: err.response.status,
        statusText: err.response.statusText,
        msg: err.response.data.error,
    })})

  },
  // ---------------------------------------------------------------------------
  closeTask({ commit, dispatch }, data: {task: TaskInterface, action: string}) {
    // TODO: назвать по другому (т.к. кроме закрытия, выполняет создание
    // TODO: повторов, удаление и снятие задач)
    commit(RootMutationType.tasks.closeTask, data.task)
    dispatch(RootMutationType.tasks.saveTask, data)
  },
  // ---------------------------------------------------------------------------
  cancelClose({ commit }) {
    commit(RootMutationType.tasks.cancelClose)
  },
  // ---------------------------------------------------------------------------
  removeTask({ commit }, task: TaskInterface ) {
    // TODO: действие наверное можно удалить, соотв. мутацию тоже(нигде не исп.)
    commit(RootMutationType.tasks.removeTask, task)
  },
  // ---------------------------------------------------------------------------
  deleteTask({ commit }, task: TaskInterface ) {
    // TODO: действие наверное можно удалить, соотв. мутацию тоже(нигде не исп.)
    commit(RootMutationType.tasks.deleteTask, task)
  },

  // ---------------------------------------------------------------------------
  newTask({ commit }) {
    commit(RootMutationType.tasks.loadingTask)
    apiClient.tasks.fetchTask('no-id').then((
      data: SerializedTask) => {
        data.task = JSON.parse(JSON.stringify(initialTask))
        data.task.deadline = dayjs(new Date((new Date()).getTime() +
                                   60*60*1000)).utc().format()
        commit(RootMutationType.tasks.loadedTask, data)
    })
    // TODO: сделать хранение ошибки вне подсистемы и обновлять ошибку
    // TODO: нужно на APIClient (может вообще на HTTPClient)
    .catch(err => {
      commit(RootMutationType.tasks.updateError, {
        status: err.response.status,
        statusText: err.response.statusText,
        msg: err.response.data.error,
    })})
  },

  // ===========================================================================
  setCountOverdue({ commit }, count: number ) {
    commit(RootMutationType.tasks.setCountOverdue, count)
  },


  // ===========================================================================
  checkOverdue({ commit, dispatch }) {

    if (doesHttpOnlyCookieExist('refresh_token')) {

      apiClient.tasks.checkOverdue()
        .then((data: object ) => {
          commit(RootMutationType.tasks.setCountOverdue, data)

          let count = (data as {count_overdue: number}).count_overdue
          let firstItem = {
            name: state.menu.items.length > 0 ? state.menu.items[0].name : '',
            count: state.menu.items.length > 0 ? state.menu.items[0].count : 0
          }

          // активен список просроченных задач, но кол-во задач изменилось
          let cond1 = firstItem.name == 'overdue' && firstItem.count != count
          // активен список просроченных задач, но просроченных больше нет
          let cond2 = firstItem.name == 'overdue' && count == 0
          // НЕ активен список просроченных задач, но есть просроченные
          let cond3 = firstItem.name != 'overdue' && count > 0

          if (cond1 || cond2 || cond3) {
              dispatch(RootMutationType.tasks.loadTasks)
          }

        })
        .catch((error: object)=> {
          // TODO: в отличие от пред. ошибок , эту стоит обрабатывать как то
          // TODO: по другому (например вывести сообщение в заголовке, о том,
          // TODO: что не удалось обновить состояние задач)
          console.log("Check Overdue - ERROR", error)
        })
    }
  },

  // ===========================================================================
  transferOverdue({ commit, dispatch }, timeInc: number ) {
    commit(RootMutationType.tasks.loadingTasks)
    apiClient.tasks.transferOverdue(timeInc)
      .then((data: object) => {
        //console.log("Transfer Overdue - OK", data)
        dispatch(RootMutationType.tasks.loadTasks)
      })
      // TODO: сделать хранение ошибки вне подсистемы и обновлять ошибку
      // TODO: нужно на APIClient (может вообще на HTTPClient)
      .catch(err => {
        console.log("Transfer Overdue - ERROR", err)
        commit(RootMutationType.tasks.updateError, {
          status: err.response.status,
          statusText: err.response.statusText,
          msg: err.response.data.error,
      })})
    dispatch(RootMutationType.tasks.loadTasks)
  },

  // ===========================================================================
  updateMenu({ commit }, menu: TasksMenuInterface ) {
    commit(RootMutationType.tasks.updateMenu, menu)
  },
}


// =============================================================================
export const getters: GetterTree<TasksStateInterface, RootStateInterface> = {
}


// =============================================================================
// create our Tasks store instance
const namespaced: boolean = true
const state: TasksStateInterface = initialTasksState

export const tasksState: Module<TasksStateInterface, RootStateInterface> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
