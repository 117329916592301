
import { defineComponent, computed } from 'vue'
import { getUID } from '../getUID'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'Radio',
  inheritAttrs:true,

  props: {
    caption: { type: String, default: 'default radio' },
    modelValue: { type: String, default: '' },
    value: { type: String, default: 'on' },
    group: { type: String, default: 'group' },
    inline: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    mp: { type: String, default: '' },
    center: { type: Boolean, default: false },
  },

  setup(props) {
    const i18n = useI18n()

    const uid = getUID('Radio-')

    const cssClass = computed(() => {
      let classes = ['form-check']
      if (props.inline) {
        classes.push('form-check-inline')
      }
      if (props.mp) {
        classes.push(props.mp)
      }
      if (props.center) {
        classes.push('align-items-center')
      }
      return classes.join(' ')
    })

    const cssLabel = computed(() => {
      let classes = ['form-check-label']
      if (props.disabled) {
        classes.push('disabled')
      }
      return classes.join(' ')
    })

    return {
      i18n,
      uid,
      cssClass,
      cssLabel
    };
  }
})
