/* CRC:3F1D5875:3 */
/* Константы для наименований мутаций */

// group our constants in a namespace
export namespace TblMutationType {
  // TODO: get... вроде нигде не используются
  export const getTbl: string = 'getTbl'
  export const getTbls: string = 'getTbls'

  export const changeFilter: string = 'changeFilter'
  export const loadTbls: string = 'loadTbls'
  export const loadingTbls: string = 'loadingTbls'
  export const loadedTbls: string = 'loadedTbls'

  export const loadTbl: string = 'loadTbl'
  export const loadingTbl: string = 'loadingTbl'
  export const loadedTbl: string = 'loadedTbl'
  export const cancelEditTbl: string = 'cancelEditTbl'
  export const saveTbl: string = 'saveTbl'
  export const incTbl: string = 'incTbl'

  export const updateMenu: string = 'updateMenu'
  export const updateError: string = 'updateError'
}
