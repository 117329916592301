
import
  { defineComponent, ref, inject, watch, onBeforeMount, computed } from "vue"

export default defineComponent({
  name: "Tab",
  inheritAttrs: true,
  props: {
    title: { type: String, default: 'Tab' },
    color: { type: String, default: '#0062b2' },
    mp: { type: String, default: '' },
  },
  setup(props, { attrs }) {
    const index = ref(0)
    const isActive = ref(false)

    const tabs: any = inject("TabsProvider", null)

    watch (
      () => tabs ? tabs.selectedIndex : null,
      () => { isActive.value = index.value === tabs.selectedIndex }
    )

    watch (
      () => props.color,
      () => { tabs.colors[index.value] = props.color }
    )

    onBeforeMount(() => {
      if (tabs) {
        index.value = tabs.count
        tabs.count++
        isActive.value = index.value === tabs.selectedIndex
        tabs.colors[index.value] = props.color
      }
    })

    const cssClass = computed(() => {
      let classes = [
        "tab-pane fade",
        props.mp || "",
        isActive.value ? "active show": ""
      ]
      return classes.join(' ').trim()
    })

    return {
      attrs,
      isActive,
      cssClass
    }
  }
})
