<!-- CRC:85C5C73:12 -->
<!-- Тестовая страница TinyMCE -->

<template>
<span> <!-- ВНИМАНИЕ!  без <span> будет ошибка -->
<tinymce v-model="myValue"
  :init="{
    //--------------------------------------------------------------------------
    //height: 'calc(100vh - 300px)', /* TODO: сделать расчет отступа */
    height: '580px', /* TODO: сделать расчет отступа */

    //--------------------------------------------------------------------------
    // определение меню
    menu: tinymceMenu,
    menubar: false, //tinymceMenubar,

    //--------------------------------------------------------------------------
    // язык
    language_url: './tinymce/ru.js',//languagePath,
    language: lang, //'ru',

    //--------------------------------------------------------------------------
    content_css: false,
    skin: false,

    //--------------------------------------------------------------------------
    // плагины
    plugins: [
      //'print fullpage paste importcss searchreplace autolink autosave ',
      'print fullpage paste importcss searchreplace autolink ',
      'save code visualblocks fullscreen image link template codesample ',
      'table hr nonbreaking anchor insertdatetime lists advlist ',
      'wordcount imagetools textpattern noneditable spellchecker'
    ],

    //--------------------------------------------------------------------------
    // определение элементов панели инструментов
    __toolbar: 'allmenu | save | fontselect fontsizeselect | forecolor ' +
                'backcolor bold italic hr table ' +
                '| numlist bullist outdent indent | image',
    toolbar: 'allmenu | fontselect fontsizeselect | forecolor ' +
              'backcolor bold italic hr table ' +
              '| numlist bullist outdent indent | image',

    //--------------------------------------------------------------------------
    // размеры шрифта
    fontsize_formats: '8pt 10pt 12pt 13pt 14pt 18pt 24pt 36pt',
    importcss_append: true, // для поддержки меню форматирование
    branding: false,        // убираем 'При поддержке Tiny' в статус баре

    //--------------------------------------------------------------------------
    // Spellchecker

    /*  <!--spellchecker_languages : '+Russian=ru,English=en',
    spellchecker_rpc_url : 'http://speller.yandex.net/services/tinyspell';,
    spellchecker_word_separator_chars :'\\s!#$%&()*+,./:;<=>?@[\]^_{|}\xa7'+
    '\xa9\xab\xae\xb1\xb6\xb7\xb8\xbb\xbc\xbd\xbe\u00bf\xd7\xf7\xa4\u201d'+
    '\u201c',  еще в разделителях должна быть двойная кавычка  -->*/

    //--------------------------------------------------------------------------
    // закрепляет панель инструментов и меню в верхней части экрана при
    // прокрутке веб-страницы
    toolbar_sticky: true,

    //--------------------------------------------------------------------------
    // должен ли редактор запрашивать у пользователя уведомление о том, что
    // у него есть несохраненные изменения при попытке закрыть текущее окно
    autosave_ask_before_unload: false, //true,

    //--------------------------------------------------------------------------
    // параметры сохранения данных в локальном хранилище
    //autosave_interval: '30s',
    //autosave_prefix: '{path}{query}-{id}-',
    //autosave_restore_when_empty: false,
    //autosave_retention: '2m',

    //--------------------------------------------------------------------------
    // добавляет вкладку «Дополнительно» в диалоговое окно изображения
    image_advtab: true,

    //--------------------------------------------------------------------------
    // для будущего использования - реакция на нажатие кнопки мыши
    /*file_picker_callback: function (callback, value, meta) {
      / * Provide file and text for the link dialog * /
      if (meta.filetype === 'file') {
        callback('https://www.google.com/logos/google.jpg',
        { text: 'My text' });
      }
    },*/

    //--------------------------------------------------------------------------
    // пока просто примеры
    templates: [
      { title: 'New Table',
        description: 'creates a new table',
        content: templateNewTable, },
      { title: 'Starting my story',
        description: 'A cure for writers block',
        content: 'Once upon a time...', },
      { title: 'New list with dates',
        description: 'New List with dates',
        content: templateNewListWithDate, },
    ],
    template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
    template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',

    //--------------------------------------------------------------------------
    image_caption: true, // позволяет включать подписи к изображениям
    // положение панели инструментов, при нажатии на ...
    toolbar_drawer: 'sliding',
    toolbar_mode: 'sliding',

    //--------------------------------------------------------------------------
    //contextmenu: false,
    contextmenu: 'table',  // работает только при щелчке на таблице
    table_toolbar: true,
    table_default_attributes: {
      border: '1',
      cellspacing:'0',
      cellpadding:'3'
    },

    relative_urls: false,
    link_context_toolbar: true,

    // чтобы курсор при нажатии Enter перепрыгивал на следущую строчку,
    // а не делал большой отступ, показывая создание нового параграфа
    //content_style: 'p, span { margin: 0; padding: 0; }' +
    //'html, body {    background: #f5f5f5 !important; font-size: 12pt;}',
    content_style: 'p, span { margin: 0; padding: 0; }' +
    'html, body {font-size: 12pt;}',

    images_upload_handler: imagesUploadHandler,

    setup: setup
  }"

/></span>
</template>

<script lang="js">
import 'tinymce/tinymce.min'
import 'tinymce/themes/silver/theme.min'
import 'tinymce/icons/default/icons.min'

import 'tinymce/plugins/advlist/plugin.min'
import 'tinymce/plugins/autolink/plugin.min'
import 'tinymce/plugins/lists/plugin.min'
import 'tinymce/plugins/link/plugin.min'
import 'tinymce/plugins/image/plugin.min'
import 'tinymce/plugins/charmap/plugin.min'
import 'tinymce/plugins/print/plugin.min'
import 'tinymce/plugins/preview/plugin.min'
import 'tinymce/plugins/anchor/plugin.min'
import 'tinymce/plugins/searchreplace/plugin.min'
import 'tinymce/plugins/visualblocks/plugin.min'
import 'tinymce/plugins/code/plugin.min'
import 'tinymce/plugins/fullscreen/plugin.min'
import 'tinymce/plugins/insertdatetime/plugin.min'
import 'tinymce/plugins/media/plugin.min'
import 'tinymce/plugins/table/plugin.min'
import 'tinymce/plugins/paste/plugin.min'
import 'tinymce/plugins/help/plugin.min'
import 'tinymce/plugins/wordcount/plugin.min'
import 'tinymce/plugins/fullpage/plugin.min'
import 'tinymce/plugins/importcss/plugin.min'
import 'tinymce/plugins/autosave/plugin.min'
import 'tinymce/plugins/save/plugin.min'
import 'tinymce/plugins/template/plugin.min'
import 'tinymce/plugins/codesample/plugin.min'
import 'tinymce/plugins/hr/plugin.min'
import 'tinymce/plugins/nonbreaking/plugin.min'
import 'tinymce/plugins/imagetools/plugin.min'
import 'tinymce/plugins/textpattern/plugin.min'
import 'tinymce/plugins/noneditable/plugin.min'
import 'tinymce/plugins/spellchecker/plugin.min'

import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'

import Editor from '@tinymce/tinymce-vue'

import { defineComponent } from "vue"

export default defineComponent({
  name: 'TinyMCE',
  components: {
    'tinymce': Editor
  },
  props: {
    value: { type: String, default: '' },
    lang:  { type: String, default: '' },
  },
  data() {
    return {
      show: true,
      //------------------------------------------------------------------------
      // содержание меню
      tinymceMenubar: 'file edit insert format tools table',

      //------------------------------------------------------------------------
      // содержание элементов меню (подменю)
      tinymceMenu: {
        file: {
          title: 'File',
          items: 'print', //'savemenu | print',
        },
        edit: {
          title: 'Edit',
          items: 'undo redo | cut copy paste pastetext | ' +
                 'selectall | searchreplace',
        },
        insert: {
          title: 'Insert',
          items: 'image link template codesample | hr | ' +
                 'nonbreaking anchor | insertdatetime',
        },
        format: {
          title: 'Format',
          items: 'bold italic underline strikethrough superscript subscript ' +
                 'codeformat | formats blockformats fontsizes ' +
                 'fontformats align | forecolor backcolor | removeformat',
        },
        tools: {
          title: 'Tools',
          items: 'spellchecker | code wordcount | visualblocks',
        },
        table: {
          title: 'Table',
          items: 'inserttable | cell row column | tableprops deletetable',
        },
      },
      myValue: this.value,
    }
  },
  computed: {
    //--------------------------------------------------------------------------
    templateNewTable: function() {
      return '<div class="mceTmpl"><table width="98%%"  border="0" ' +
             'cellspacing="0" cellpadding="0"><tr><th scope="col"> </th>' +
             '<th scope="col"> </th></tr><tr><td> </td><td> </td></tr>' +
             '</table></div>'
    },
    templateNewListWithDate: function() {
      return '<div class="mceTmpl"><span class="cdate">cdate</span>' +
             '<br /><span class="mdate">mdate</span><h2>My List</h2><ul>' +
             '<li></li><li></li></ul></div>'
    },
  },
  watch: {
    value (newValue) {
      this.myValue = newValue
    },
    myValue (newValue) {
      this.$emit('input', newValue)
    },
    lang (newValue) {
      // TODO: сделать замену языка на лету, сейчас только после перезагрузки
      // TODO: страницы, на которой находится редактор (напр. при смене route)
      // console.log(this.lang, newValue)
    }
  },
  methods: {

    imagesUploadHandler: function (blobInfo, success, failure) {
       // no upload, just return the blobInfo.blob() as base64 data
       success('data:' + blobInfo.blob().type +';base64,' + blobInfo.base64());
       if (failure) {
         //
       }
    },

    setup: function (editor) {

      // раскрываем на полное окно при старте
      editor.on('init', function () {
        /*editor.execCommand('mceFullScreen');*/
      });

      // кнопка save в меню
      /*
      editor.ui.registry.addMenuItem('savemenu',  {
        icon: 'save',
        text: 'Save',
        onAction: () => editor.execCommand('mceSave'),
        onSetup: (buttonApi) => {buttonApi.setDisabled(!editor.isDirty());},
      });
      */

      /*// при нажатии клавиши
      editor.on('keypress', function (e) {
          var view = document.getElementById("view");
          view.innerHTML = editor.getContent();
      });*/

      // -------------------------------------------------------------------------
      // добавлям кнопку с меню
      const menuBar = this.tinymceMenubar;
      const menu = this.tinymceMenu;
      editor.ui.registry.addMenuButton('allmenu', {
        text: '☰',
        fetch: function (callback) {
          // получим список меню
          const menuItems = editor.ui.registry.getAll().menuItems;

          const items = [];
          const subMenuItems = {};

          // for (let menuName of this.tinymceMenubar.split(' ')) {
          for (const menuName of menuBar.split(' ')) {
            const subMenuItemsName = menu[menuName].items.split(' ');
            subMenuItems[menuName] = [];
            for (const subMenu of subMenuItemsName) {
              if (subMenu != '|') {
                subMenuItems[menuName].push(menuItems[subMenu]);
              } else {
                subMenuItems[menuName].push({ type: 'separator', });
              }
            }

            items.push({
              type: 'nestedmenuitem',
              text: menuName,
              getSubmenuItems: function () {
                return subMenuItems[menuName];
              },
            });
          }

          callback(items);
        },
      });
    }
  }
})
</script>

<style scoped lang="scss">
</style>
