/* CRC:1430AC44:2 */
/* Константы для наименований мутаций */

// group our constants in a namespace
export namespace AuthMutationType {
  export const updateUser: string = 'updateUser'
  export const refreshTokens: string = 'refreshTokens'
  export const logoutUser: string = 'logoutUser'
  export const logintUser: string = 'loginUser'
}
