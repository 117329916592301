/* CRC:789C523:1 */
/* Экземпляр TblStateInterface с начальными значениями */


import { TblStateInterface } from '@/models'



export const initialTblState: TblStateInterface = {
  loading: false,
  items: [],

  menu: {
    showMenu: true,
    currentIndex: 0,
    items: [
      { id: '', desc: 'Загрузка...', count_day: 0, ost_one: 0, ost_all: 0}, ],
    currentItem:
      { id: '', desc: '', count_day: 0, ost_one: 0, ost_all: 0}
    },

  currentItem: null,
  showEdit: false,

  error: {
    status: 0,
    statusText: '',
    msg: ''
  }
}
