

// TODO: есть общий код с Text.vue, Time.vue, сделать mixin

import { defineComponent, computed } from 'vue'
import { getUID } from '../getUID'
import { isMobile } from '../checkDevice'

export default defineComponent({
  name: 'DatePicker',
  inheritAttrs: true,

  props: {
    modelValue: { type: String, default: '' },
    image: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    clearButton: { type: Boolean, default: false },
    intable: { type: Boolean, default: false },
    sm: { type: Boolean, default: false },
    mp: { type: String, default: '' },
    color: { type: String, default: '' },
  },

  setup(props, { emit }) {
    const uid = getUID('Date-')

    const cssButton = computed(() => {
      let classes = ['btn btn-outline-danger']
      return classes.join(' ')
    })

    const cssInput = computed(() => {
      let classes = ['form-control']

      if (props.disabled) {
        classes.push('disabled')
      }
      if (props.intable) {
        classes.push('sm')
      }
      classes.push(props.intable ? 'sm': 'md')
      if (props.sm || props.intable) {
        classes.push('form-control-sm')
      }
      if (props.intable) {
        classes.push('border-0')
      }

      return classes.join(' ')
    })

    const cssClass = computed(() => {
      let classes = [
        "d-flex justify-content-center",
        props.mp ?  props.mp : "",
      ]
      return classes.filter(Boolean).join(' ')
    })

    const cssStyle = computed(() => {
      let styles = ""
      if (props.color)
      {
        styles = `color: ${props.color};`
      }
      return styles
    })

    const reset = () => {
      emit('update:modelValue', '')
      // TODO: а зачем здесь вообще return ?
      //return typeof(value) !== 'undefined' ? value : null
    }

    return {
      uid,
      cssButton,
      cssInput,
      cssClass,
      cssStyle,
      reset,
      isMobile
    };
  }
})
