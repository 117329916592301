
import { defineComponent, computed } from 'vue'
import Box from './Box.vue'


export default defineComponent({
  name: 'Card',
  // ---------------------------------------------------------------------------
  components: {
    Box
  },
  // ---------------------------------------------------------------------------
  props: {
    header: { type: Boolean, default: false },
    body: { type: Boolean, default: false },
    footer: { type: Boolean, default: false },
  },

  // ---------------------------------------------------------------------------
  setup(props) {

    // -------------------------------------------------------------------------
    return {
      props,
    }
  }
})
