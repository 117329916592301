
import { defineComponent, onMounted, computed } from 'vue'

import * as ui from '@/components/base-ui'

import { useTasksStore } from '@/store/tasks'
import { useRouter } from "vue-router"
import { RootMutationType } from '@/models'
import { useI18n } from 'vue-i18n'
import { getTextDate, isMobile } from '@/components/base-ui'
import '@/components/base-ui/common.scss'


export default defineComponent({
  name: 'TasksOverdue',
  props: {
  },
  components: {
    Container: ui.Container, Box: ui.Box
  },
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {

    const i18n = useI18n()
    const tasksStore = useTasksStore()
    const router = useRouter()

    // -------------------------------------------------------------------------
    const countOverdue = computed(() => {
      return tasksStore.state.count_overdue
    })

    // -------------------------------------------------------------------------
    const goTask = () => {
      router.push({ name: 'Tasks' })
    }

    // -------------------------------------------------------------------------
    onMounted(() => {
    });

    // -------------------------------------------------------------------------
    return {
      i18n,
      isMobile,
      countOverdue,
      getTextDate,
      goTask,
    }
  }
})
