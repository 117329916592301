/* CRC:AD1B5A2D:3 */
/* Константы для наименований мутаций */

// group our constants in a namespace
export namespace MoneyMutationType {
  // TODO: get... вроде нигде не используются
  export const getOper: string = 'getOper'
  export const getOpers: string = 'getOpers'
  export const changeFilter: string = 'changeFilter'

  export const loadOpers: string = 'loadOpers'
  export const loadingOpers: string = 'loadingOpers'
  export const loadedOpers: string = 'loadedOpers'

  export const loadOper: string = 'loadOper'
  export const loadingOper: string = 'loadingOper'
  export const loadedOper: string = 'loadedOper'
  export const cancelEditOper: string = 'cancelEditOper'
  export const saveOper: string = 'saveOper'

  export const updateMenu: string = 'updateMenu'
  export const updateError: string = 'updateError'

}
