import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-872dfbe8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tags" }
const _hoisted_2 = { class: "tag" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_Edit = _resolveComponent("Edit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedTags, (tag) => {
        return (_openBlock(), _createElementBlock("li", { key: tag }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(tag), 1),
          (!_ctx.disabled)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "delete-tag",
                onClick: ($event: any) => (_ctx.deleteTag(tag))
              }, " × ", 8, _hoisted_3))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createVNode(_component_Select, {
      sm: "",
      modelValue: _ctx.selectedTag,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTag) = $event)),
      options: _ctx.availableTags,
      disabled: _ctx.availableTags.length == 0 || _ctx.disabled,
      caption: _ctx.i18n.t('task.Select object from list')
    }, null, 8, ["modelValue", "options", "disabled", "caption"]),
    _createVNode(_component_Edit, {
      sm: "",
      modelValue: _ctx.inputTag,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputTag) = $event)),
      onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.enterClicked()), ["enter"])),
      disabled: _ctx.disabled,
      placeholder: _ctx.i18n.t('task.New object - press enter')
    }, null, 8, ["modelValue", "disabled", "placeholder"])
  ]))
}