

import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import 'dayjs/locale/en'

import { defineComponent, PropType, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { TasksMenuInterface } from '@/models/tasks/TasksState.interface'
import { Table, Container, Row, Col, DatePicker, FormGroup, Edit, Btn, Box,
        Checkbox, Select, Image } from '@/components/base-ui'
import { getImage } from '@/utils'
import '@/components/base-ui/common.scss'


export default defineComponent({
  name: "TasksMenu",
  // ---------------------------------------------------------------------------
  components: {
    Container, Row, Col, DatePicker, Checkbox, FormGroup, Edit, Btn, Box,
    Table, Select, Image
  },
  // ---------------------------------------------------------------------------
  props: {
    menu: {
      type: Object as PropType<TasksMenuInterface>,
    },
  },
  // ---------------------------------------------------------------------------
  emits: ["changeFilter", "newTask", "transferOverdue"],
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {
    const i18n = useI18n()
    const searchText = ref(props.menu?.searchText)
    const transferSelected = ref('60')
    const mouseOnThis = ref(false)

    var tempDate = ref(dayjs(new Date()).format('YYYY-MM-DD'))

    // -------------------------------------------------------------------------
    const isActive = (name: string) => {
      if (!props.menu) {
        return false
      }
      return props.menu.items[props.menu.currentIndex].name == name
    }

    // -------------------------------------------------------------------------
    const changeFilter = (index: number) => {
      emit("changeFilter", {currentIndex: index})
    }

    // -------------------------------------------------------------------------
    watch(() => props.menu?.showOK, () => { emit("changeFilter") })
    watch(() => tempDate.value, (value) => {
      emit("changeFilter", {
        date: value,
        currentIndex: props.menu?.items.findIndex(i => i.name === "on_date")
      })
    })
    // -------------------------------------------------------------------------
    const search = () => {
      if (!searchText.value) {
        return
      }
      let txt = searchText.value.trim()
      if (txt.length > 0) {
        emit("changeFilter", {'searchText': txt, 'isSearch': true})
      }
    }

    // -------------------------------------------------------------------------
    // TODO: текст пунктов меню должен приходить с API на выбранном языке
    const i18ru = (txt: string) => {
      if (txt.startsWith("На дату")) {
        return i18n.t('tasks.На дату') + txt.substring(7)
      }
      if (txt.startsWith("Загрузка")) {
        return i18n.t('tasks.Загрузка') + txt.substring(8)
      }
      return i18n.t('tasks.' + txt)
    }

    // -------------------------------------------------------------------------
    const clearSearch = () => {
      emit("changeFilter", {'isSearch': false})
    }

    // -------------------------------------------------------------------------
    const newTask = () => {
      emit("newTask")
    }

    // -------------------------------------------------------------------------
    const transferOverdue = () => {
      emit("transferOverdue", Number(transferSelected.value))
    }

    // -------------------------------------------------------------------------
    return {
      i18n,
      getImage,
      searchText,
      changeFilter,
      search,
      clearSearch,
      isActive,
      tempDate,
      newTask,
      transferOverdue,
      transferSelected,
      i18ru,
      mouseOnThis
    }
  }
  // ---------------------------------------------------------------------------
})
