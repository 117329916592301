/* CRC:2A86C101:3 */
/* Экземпляр RootStateInterface с начальными значениями */


import { RootStateInterface } from '@/models'
/**
* @name initialRootState
* @description
* The Root state instance with the initial default values
*/
export const initialRootState: RootStateInterface = {
  loading: false
}
