
import { defineComponent, PropType, computed } from "vue";
import { useI18n } from "vue-i18n";
import {
  Table,
  Container,
  Row,
  Col,
  Box,
  DatePicker,
  FormGroup,
  Btn,
  Checkbox,
  Select,
  Image,
} from "@/components/base-ui";
import { isMobile } from "@/components/base-ui";
import { useLocalesStore } from "@/store/locales";
import { LocaleInfoInterface } from "@/models/locales/LocaleInfo.interface";
import LocaleSelector from "@/components/locale-selector/LocaleSelector.vue";
import { RootMutationType } from "@/models";
import { checkAccess } from "@/utils";
import { useAuthStore } from "@/store/auth";
import { useRouter } from "vue-router";

interface NavPage {
  page: string;
  url: string;
}

export default defineComponent({
  name: "MainHeader",
  // ---------------------------------------------------------------------------
  components: {
    Container,
    Row,
    Col,
    Box,
    DatePicker,
    Checkbox,
    FormGroup,
    Btn,
    Table,
    Select,
    LocaleSelector,
    Image,
  },
  // ---------------------------------------------------------------------------
  props: {
    currentItem: { type: String },
    navPages: { type: Array as PropType<NavPage[]>, default: [] },
  },
  // ---------------------------------------------------------------------------
  emits: ["selectFilter", "newTask"],
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {
    const i18n = useI18n();
    const authStore = useAuthStore();
    const localesStore = useLocalesStore();
    const router = useRouter();

    const user = computed(() => {
      return authStore.state.user;
    });

    const availableLocales = computed(() => {
      return localesStore.state.availableLocales;
    });

    const onLocaleClicked = (localeInfo: LocaleInfoInterface) => {
      localesStore.action(
        RootMutationType.locales.selectLocale,
        localeInfo.locale
      );
    };
    // -------------------------------------------------------------------------
    const accessEnabled = (roles: string) => {
      return checkAccess(roles);
    };

    // -------------------------------------------------------------------------
    const goLink = (link: string) => {
      if (link != "#") {
        router.push({ name: link });
      }
    };
    //

    // -------------------------------------------------------------------------
    return {
      i18n,
      isMobile,
      accessEnabled,
      user,
      availableLocales,
      onLocaleClicked,
      goLink,
    };
  },
  // ---------------------------------------------------------------------------
});
