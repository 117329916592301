
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: "Loader",
  props: {
    show: {
      type: Boolean
    }
  },
  // ---------------------------------------------------------------------------
  setup() {
    const i18n = useI18n()
    return { i18n }
  }
})
