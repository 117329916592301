/* CRC:62DA7DF3:1 */
/* Формирование даты или времени в "человеческом" формате */


export const isMobile = () => {
  const lstMobile = 'Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|' +
                    'IEMobile|Windows Phone|Kindle|Silk|Opera Mini'
  const regexp = new RegExp(lstMobile, 'i')
  return regexp.test(navigator.userAgent)
}
