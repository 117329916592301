
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: "FormLabel",
  props: {
    disabled: { type: Boolean, default: false },
    caption: { type: String, default: '' },
    mp: { type: String, default: '' },
  },
  setup(props) {
    const i18n = useI18n()

    const cssClass = computed(() => {
      let classes = props.disabled ? 'disabled ' : ''
      if (props.mp) {
        classes += props.mp || ''
      }
      return classes
    })
    
    return {
      cssClass,
      i18n
    }
  }
})


