<!-- CRC:F012C42F:6 -->
<!-- Компонент - поле ввода числа -->

<!-- Использование:

   <Number v-model=".." :disabled=".." :caption=".." :placeholder=".."/>
   <Number v-model=".." disabled=".." caption=".." placeholder=".."/>

   <Number [ sm | md | lg ] width="50" .....

-->

<template>
<div :class="cssClass">

  <label :for="uid" v-if="caption" class="form-label">{{caption}}</label>

  <!-- TODO: вместо input type="number" сделать, что то навроде
             input type="text" pattern="[0-9]+([\.][0-9]{1,2})?"
             иначе иногда при нажатии точки поле просто стирается -->
  <input type="number" :class="cssInput" v-bind="$attrs"
         :id="uid" :placeholder="placeholder"
         @input="$emit('update:modelValue', Number($event.target.value))"
         :value="modelValue" :disabled="disabled" :style="cssStyle">

</div>
</template>


<script lang="js">

// TODO: в коде много общего с Text.vue, сделать mixin

import { defineComponent, computed } from 'vue'
import { getUID } from '../getUID'

export default defineComponent({
  name: 'Number',
  inheritAttrs:true,

  props: {
    caption: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    modelValue: { type: [Number, String], default: 0},
    disabled: { type: Boolean, default: false },

    sm:  { type: Boolean, default: false },
    md:  { type: Boolean, default: false },
    lg:  { type: Boolean, default: false },

    width: { type: [Number, String], default: 35 },
    mp: { type: String, default: '' },
    background: { type: String, default: '' },
  },

  setup(props) {
    const uid = getUID('Number-')

    const cssClass = computed(() => {
      let classes = ['']
      if (props.mp) {
        classes.push(props.mp)
      }
      return classes.join(' ')
    })

    const cssInput = computed(() => {
      let classes = ['form-control form-control-sm']
      var suffix = ''
      suffix = props.sm ? 'form-control-sm' : suffix
      suffix = props.md ? 'form-control-md' : suffix
      suffix = props.lg ? 'form-control-lg' : suffix
      return 'form-control' + (suffix ? (' ' + suffix) : '')
    })

    const inputWidth = computed(() => {
      if (typeof(props.width) == String) {
        return props.width
      }
      return props.width.toString() + 'px'
    })

    const cssStyle = computed(() => {
      var styles = `width:${inputWidth.value};`
      if (props.background) {
        styles += `background-color: ${props.background};`
      }
      return styles
    })

    return {
      uid,
      cssClass,
      cssInput,
      inputWidth,
      cssStyle
    };
  }
})
</script>


<style scoped lang="scss">

.disabled {
  color: #6c757d;
  opacity: 1 !important;
}

input[type=number]{
  text-align: center;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

</style>
