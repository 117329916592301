/* CRC:E0BEA8ED:3 */
/* Плагин для локализации */

import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n'


interface LocalesDataInterface {
  datetimeFormats: any
  numberFormats: any
  messages: LocaleMessages<VueMessageType>
}

/**
* @name: getLocalesData
* @description: Helper to load the locale json files ...
*/
const getLocalesData = (): LocalesDataInterface => {

  // create the instance that will hold the loaded data
  const localeData: LocalesDataInterface = {
    datetimeFormats: {},
    numberFormats: {},
    messages: {}
  }
  /* TODO: ниже находится универсальный код, но он не работает в JEST
  // ---------------------------------------------------------------------------
  // use require.context to get all the .json files ...
  const files = (require as any).context(
    './locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)

  // loop through all the files
  const keys: string[] = files.keys()
  keys.forEach((key: string) => {
    // extract name without extension
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const localeId = matched[1]
      // from each file, set the related messages property
      localeData.datetimeFormats[localeId] = files(key).datetimeFormats
      localeData.numberFormats[localeId] = files(key).numberFormats
      localeData.messages[localeId] = files(key).messages
    }
  })
  // ---------------------------------------------------------------------------
  */
  // TODO: код работающий и на сайте и в тестах
  let ru = require('./locales/ru-RU.json')
  localeData.datetimeFormats['ru-RU'] = ru.datetimeFormats
  localeData.numberFormats['ru-RU'] = ru.numberFormats
  localeData.messages['ru-RU'] = ru.messages
  let en = require('./locales/en-US.json')
  localeData.datetimeFormats['en-US'] = en.datetimeFormats
  localeData.numberFormats['en-US'] = en.numberFormats
  localeData.messages['en-US'] = en.messages

  return localeData
}

const data: LocalesDataInterface = getLocalesData()

export const i18n = createI18n({
  locale: 'ru-RU',
  fallbackLocale: 'en-US',
  messages: data.messages,
  datetimeFormats: data.datetimeFormats,
  numberFormats: data.numberFormats
})
