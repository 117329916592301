

import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { MoneyMenuInterface } from '@/models/money/MoneyState.interface'
import { Table, Container, Row, Col, Image, Box } from '@/components/base-ui'
import '@/components/base-ui/common.scss'


export default defineComponent({
  name: "MoneyMenu",
  // ---------------------------------------------------------------------------
  components: {
    Container, Row, Col, Table, Image, Box
  },
  // ---------------------------------------------------------------------------
  props: {
    menu: {
      type: Object as PropType<MoneyMenuInterface>,
    },
  },
  // ---------------------------------------------------------------------------
  emits: ["changeFilter"],
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {
    const i18n = useI18n()

    // -------------------------------------------------------------------------
    const isActive = (name: string) => {
      if (!props.menu) {
        return false
      }
      return props.menu.items[props.menu.currentIndex].name == name
    }

// -------------------------------------------------------------------------
    const classMenuItem = (name: string) => {
      if (!props.menu) {
        return 'link'
      }
      let current = props.menu.items[props.menu.currentIndex].name == name
      return current ? 'link active':' link'
    }

    // -------------------------------------------------------------------------
    const changeFilter = (index: number) => {
      emit("changeFilter", index)
    }

    // -------------------------------------------------------------------------
    // TODO: текст пунктов меню должен приходить с API на выбранном языке
    const i18ru = (txt: string) => {
      // TODO: ПОПРАВИТЬ (подиситема Tbl, не Tasks) - может вообще функция не нужна
      if (txt.startsWith("Загрузка")) {
        return i18n.t('tasks.Загрузка') + txt.substring(8)
      }
      return i18n.t('tasks.' + txt)
    }

    // -------------------------------------------------------------------------
    // TODO: вынести в утилиты
    const formatPrice = (value: number) => {
        let val = (value/1).toFixed(2);//.replace('.', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' руб.'
    }

    // -------------------------------------------------------------------------
    return {
      i18n,
      changeFilter,
      classMenuItem,
      i18ru,
      formatPrice,
      isActive,
    }
  }
  // ---------------------------------------------------------------------------
})
