/* CRC:AB419F2B:1 */
/* Обертка для хранилища Tbl */

import { rootStore, dispatchModuleAction } from '../root'
import { RootModuleNames, TblStateInterface } from '@/models'


const tblStore = {
  get state(): TblStateInterface {
    return rootStore.state.tblState
  },

  action<T>(actionName: string, params?: T): void {
    dispatchModuleAction(RootModuleNames.tblState, actionName, params)
  }
}

// export our wrapper using the composition API convention (i.e. useXYZ)
export const useTblStore = () => {
  return tblStore //as unknown as Store
}
