

import { defineComponent, PropType, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { TblMenuInterface } from '@/models/tbl/TblState.interface'
import { Container, Box } from '@/components/base-ui'
import { isMobile } from '@/components/base-ui'
import { getImage } from '@/utils'
import '@/components/base-ui/common.scss'


export default defineComponent({
  name: "TblHeader",
  // ---------------------------------------------------------------------------
  components: {
    Container, Box
  },
  // ---------------------------------------------------------------------------
  props: {
    menu: {
      type: Object as PropType<TblMenuInterface>,
    }
  },
  // ---------------------------------------------------------------------------
  emits: ["flipMenu"],
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {
    const i18n = useI18n()

    // -------------------------------------------------------------------------
    const captionFilter = computed(() => {
      let caption = i18n.t('tasks.Current filter') + ': '
      if (props.menu) {
        let desc = i18ru(props.menu.items[props.menu.currentIndex].desc)
        caption += `<b>${desc}</b>`
      } else {
        caption += '<b>???????</b>'
      }
      return caption
    })

    // -------------------------------------------------------------------------
    const flipMenu = (index: number) => {
      emit("flipMenu", {currentIndex: index})
    }

    // -------------------------------------------------------------------------
    // TODO: текст пунктов меню должен приходить с API на выбранном языке
    const i18ru = (txt: string) => {
      // TODO: ПОПРАВИТЬ (подиситема Tbl, не Tasks) - может вообще функция не нужна
      if (txt.startsWith("Загрузка")) {
        return i18n.t('tasks.Загрузка') + txt.substring(8)
      }
      return txt //i18n.t('tasks.' + txt)
    }

    // -------------------------------------------------------------------------
    return {
      i18n,
      isMobile,
      getImage,
      captionFilter,
      flipMenu,
    }
  }
  // ---------------------------------------------------------------------------
})
