/* CRC:442B93F2:1 */
/* Экземпляр TblInterface с начальными значениями */


import { TblInterface } from '@/models'


export const initialTbl: TblInterface = {
    date_oper: '',
    box: [0, 0, 0],
    pack: [0, 0, 0],
    one: [0, 0, 0],
    ost: 0,
    desc: ''
}
