/* CRC:5BBABDA1:1 */
/* Обертка для хранилища подсистемы Финансы */

import { rootStore, dispatchModuleAction } from '../root'
import { RootModuleNames, MoneyStateInterface } from '@/models'


const moneyStore = {
  get state(): MoneyStateInterface {
    return rootStore.state.moneyState
  },

  action<T>(actionName: string, params?: T): void {
    dispatchModuleAction(RootModuleNames.moneyState, actionName, params)
  }
}

// export our wrapper using the composition API convention (i.e. useXYZ)
export const useMoneyStore = () => {
  return moneyStore //as unknown as Store
}
