/* CRC:CCA2CD96:9 */
/* Константы для наименований мутаций */

// group our constants in a namespace
export namespace TasksMutationType {
  export const loadTasks: string = 'loadTasks'
  export const loadingTasks: string = 'loadingTasks'
  export const loadedTasks: string = 'loadedTasks'

  export const selectTask: string = 'selectTask'

  export const loadTask: string = 'loadTask'
  export const loadingTask: string = 'loadingTask'
  export const loadedTask: string = 'loadedTask'
  export const cancelEditTask: string = 'cancelEditTask'
  export const saveTask: string = 'saveTask'

  export const closingTask: string = 'closingTask'
  export const closeTask: string = 'closeTask'
  export const cancelClose: string = 'cancelClose'
  export const removeTask: string = 'removeTask'
  export const deleteTask: string = 'deleteTask'
  export const newTask: string = 'newTask'

  export const updateMenu: string = 'updateMenu'

  export const setCountOverdue: string = 'setCountOverdue'
  export const transferOverdue: string = 'transferOverdue'
  export const checkOverdue: string = 'checkOverdue'

  export const updateError: string = 'updateError'
}
