
// TODO: перед отображением диалога запрашивается задача с сервера, по идее
// TODO: это не нужно (все есть в списке, в том числе вычисленная дата повтора)
// TODO: нужно переделать компонент Task.vue (TaskMobile.vue) в нем вместе
// TODO: с событием closeTask передавать задачу, а не ее ID
import { defineComponent, onMounted, ref, reactive, computed, watch } from 'vue'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import 'dayjs/locale/en'
import utc from 'dayjs/plugin/utc'

import Editor from '@/components/shared/TinyMCE.vue'
import Loader from '@/components/shared/Loader.vue'
import * as ui from '@/components/base-ui'

import { useTasksStore } from '@/store/tasks'
import { RootMutationType } from '@/models'
import { useI18n } from 'vue-i18n'
import { getTextDate, isMobile } from '@/components/base-ui'
import { getNextDate } from'./utils'
import '@/components/base-ui/common.scss'


export default defineComponent({
  name: 'TaskClose',
  props: {
    msg: String
  },
  components: {
    'editor': Editor,
    Loader,

    // layout
    Container: ui.Container,
    Row: ui.Row,
    Col: ui.Col,
    Table: ui.Table,
    Box: ui.Box,

    // forms
    Number: ui.Number,
    Textarea: ui.Textarea,
    FormGroup: ui.FormGroup,
    FormLabel: ui.FormLabel,
    DatePicker: ui.DatePicker,
    TimePicker: ui.TimePicker,
    Select: ui.Select,
    Radio: ui.Radio,
    RadioGroup: ui.RadioGroup,
    Checkbox: ui.Checkbox,
    CheckboxGroup: ui.CheckboxGroup,

    // components
    Btn: ui.Btn,
    Tabs: ui.Tabs,
    Tab: ui.Tab,
    Tags: ui.Tags,
    Text: ui.Text,
    FontStyle: ui.FontStyle,

    // modal
    ModalBody: ui.ModalBody,
    ModalTitle: ui.ModalTitle,
    ModalFooter: ui.ModalFooter,
    ModalHeader: ui.ModalHeader,
    Modal: ui.Modal,
  },
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {
    const showConfirmDelete = ref(false)

    const i18n = useI18n()
    const tasksStore = useTasksStore()

    // -------------------------------------------------------------------------
    const task = computed( () => {
      return tasksStore.state.currentTask
    })

    // -------------------------------------------------------------------------
    const loading = computed(() => {
      return tasksStore.state.loadingTask
    })

    // -------------------------------------------------------------------------
    const showCloseTask = computed(() => {
      return tasksStore.state.showCloseTask
    })

    // -------------------------------------------------------------------------
    var tempDate = ref( dayjs(new Date()).add(1, 'days').format('YYYY-MM-DD'))
    var tempTime = ref(dayjs(new Date()).format('HH:mm'))

    // -------------------------------------------------------------------------
    const closeTask = () => {
      if (!tasksStore.state.currentTask) {
        return
      }
      let action = signCreateRepeat.value ? 'repeat' : 'done'

      var task: {[key: string]: any} = {
        id: tasksStore.state.currentTask.id
      }

      // установка срока
      let isDeadlineDate = signCreateRepeat.value
      let isDeadlineTime = isDeadlineDate && Boolean(tempTime.value.trim())

      task['is_deadline_time'] = isDeadlineTime

      if (isDeadlineDate) {
        dayjs.extend(utc)
        var dt = dayjs(tempDate.value)
        if (isDeadlineTime) {
          dt = dayjs(tempDate.value + " " + tempTime.value)
        }
        task['deadline'] = dt.utc().format()
      }

      tasksStore.action(RootMutationType.tasks.closeTask,
                       { task: task, action: action })
    }

    // -------------------------------------------------------------------------
    const cancelClose = () => {
      showConfirmDelete.value = false
      tasksStore.action(RootMutationType.tasks.cancelClose)
    }

    // -------------------------------------------------------------------------
    const removeTask = () => {
      // tasksStore.action(RootMutationType.tasks.removeTask, task)
      if (tasksStore.state.currentTask) {
        tasksStore.action(RootMutationType.tasks.closeTask,
          { task: { id: tasksStore.state.currentTask.id}, action: 'remove' })
      }
    }

    // -------------------------------------------------------------------------
    const confirmDelete = () => {
      tasksStore.action(RootMutationType.tasks.cancelClose)
      showConfirmDelete.value = true
    }

    // -------------------------------------------------------------------------
    const deleteTask = () => {
      showConfirmDelete.value = false
      // tasksStore.action(RootMutationType.tasks.deleteTask, task)
      if (tasksStore.state.currentTask) {
        tasksStore.action(RootMutationType.tasks.closeTask,
          { task: { id: tasksStore.state.currentTask.id}, action: 'delete' })
      }
    }

    var signCreateRepeat = ref(false)

    // -------------------------------------------------------------------------
    watch (
      () => tasksStore.state.currentTask,
      () => {
        // срок (дата и время, а также его наличие)
        if (tasksStore.state.currentTask?.repeat){
          let dt = dayjs(getNextDate(tasksStore.state.currentTask.repeat))
          tempDate.value = dt.format('YYYY-MM-DD')
          tempTime.value = dt.format('HH:mm')

           signCreateRepeat.value = Boolean(
             tasksStore.state.currentTask.repeat.repeat)
        }
    })

    // -------------------------------------------------------------------------
    onMounted(() => {
    });

    // -------------------------------------------------------------------------
    return {
      i18n,
      isMobile,

      task,
      showCloseTask,
      loading,

      closeTask,
      cancelClose,
      removeTask,
      deleteTask,

      confirmDelete,
      showConfirmDelete,

      tempDate,
      tempTime,
      getTextDate,
      signCreateRepeat,
    }
  }
})
