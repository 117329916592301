import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2584f6dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "placeholder", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Box = _resolveComponent("Box")!

  return (_openBlock(), _createBlock(_component_Box, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.cssClass),
        style: _normalizeStyle(_ctx.cssStyle)
      }, [
        (_ctx.caption)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              for: _ctx.uid,
              class: "form-label"
            }, _toDisplayString(_ctx.caption), 9, _hoisted_1))
          : _createCommentVNode("", true),
        _createElementVNode("input", _mergeProps({
          type: "text",
          class: _ctx.cssInput
        }, _ctx.$attrs, {
          id: _ctx.uid,
          placeholder: _ctx.placeholder,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
          value: _ctx.modelValue,
          disabled: _ctx.disabled,
          style: _ctx.cssStyle
        }), null, 16, _hoisted_2)
      ], 6)
    ]),
    _: 1
  }))
}