/* CRC:D6BB2ABC:14 */
/* Реализация интерфейса HttpClientInterface */

// !! вместо axios импортируем Axios (чтобы создать переменную axios)
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { HttpRequestParamsInterface } from './HttpRequestParams.interface'
import { HttpClientInterface } from './HttpClient.interface'
import { config } from '@/config'
import router from '@/router'


// =============================================================================
const axios = Axios.create({
  timeout: 5 * 60 * 1000,
})

axios.interceptors.response.use(undefined, (error) => {
  if (error.response && error.response.status === 401) {
    var originalRequest = error.config

    const replyRequest = async () => {

      await Axios.get(config.api.auth.urls.refreshTokens)
        .then()
        .catch(errors => { console.log(errors) })

      // признак того, что запрос повторный
      if (!originalRequest.params) {
        originalRequest.params = {}
      }
      originalRequest.params.repeated = 1

      return new Promise((resolve, reject) => {  // повтор запроса
        axios(originalRequest)
        .then(response => { resolve(response) })
        .catch(err => { reject(err) })
      })
    }

    if (originalRequest.params && originalRequest.params.repeated) {
      // если запрос повторный, то переходим на страницу входа
      router.push({ name: 'Login' })
    }

    return replyRequest()
  }
  return Promise.reject(error)
});


// =============================================================================
export class HttpClientModel implements HttpClientInterface {

  // ---------------------------------------------------------------------------
  constructor() {
  }

  // ---------------------------------------------------------------------------
  async get<T>(parameters: HttpRequestParamsInterface): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, version, payload } = parameters

      const options: AxiosRequestConfig = {
        headers: { 'version': version },
        params: payload
      }
      axios.get(url, options)
        .then((response: AxiosResponse) => { resolve(response.data as T) })
        .catch((response: AxiosResponse) => { reject(response) })
    })
  }

  // ---------------------------------------------------------------------------
  put<T>(parameters: HttpRequestParamsInterface): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, version, payload } = parameters

      const options: AxiosRequestConfig = {
        headers: { 'version': version },
      }

      axios.put(url, payload, options)
        .then((response: AxiosResponse) => { resolve(response.data as T) })
        .catch((response: AxiosResponse) => { reject(response) })
    })
  }

  // ---------------------------------------------------------------------------
  post<T>(parameters: HttpRequestParamsInterface): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, version, payload } = parameters

      const options: AxiosRequestConfig = {
        headers: { 'version': version },
      }

      axios.post(url, payload, options)
        .then((response: AxiosResponse) => { resolve(response.data as T) })
        .catch((response: AxiosResponse) => { reject(response) })
    })
  }
}
