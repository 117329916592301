
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'Image',

  // ---------------------------------------------------------------------------
  props: {
    path: { type: String, default: '' },                // путь к картинке
    width: { type: [Number, String], default: '' },     // ширина
    height: { type: [Number, String], default: '' },    // высота
  },

  // ---------------------------------------------------------------------------
  setup(props) {

    // -------------------------------------------------------------------------
    const cssStyle = computed(() => {
      var style: { [key: string]: string } = {}
      if (props.width) {
        style.width = `${props.width}px`

      }
      if (props.height) {
        style.height = `${props.height}px`
      }

      return style
    })

    // -------------------------------------------------------------------------
    const imagePath = computed(() => {
      let img = require(`@/assets/${props.path}`)
      /* в тестах img получается пустое, условие сделано именно из за тестов */
      return img ? img: props.path
    })

    // -------------------------------------------------------------------------
    return {
      cssStyle,
      imagePath,
    }
  }
})
