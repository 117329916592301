
import { defineComponent, onMounted, ref, computed, watch } from 'vue'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import 'dayjs/locale/en'
import utc from 'dayjs/plugin/utc'

import Editor from '@/components/shared/TinyMCE.vue'
import Loader from '@/components/shared/Loader.vue'
import * as ui from '@/components/base-ui'

import { useTasksStore } from '@/store/tasks'
import { RootMutationType } from '@/models'
import { useI18n } from 'vue-i18n'
import { getTextDate, isMobile } from '@/components/base-ui'
import { getImage } from'@/utils'
import { getNextDate } from'./utils'



export default defineComponent({
  // ------------------------------------------------------------
  name: 'TaskEdit',
  // ------------------------------------------------------------
  props: {
    msg: String
  },
  components: {
    'tinymce': Editor,
    Loader,

    // layout
    Container: ui.Container,
    Row: ui.Row,
    Col: ui.Col,
    Table: ui.Table,
    Box: ui.Box,

    // forms
    Number: ui.Number,
    Textarea: ui.Textarea,
    FormGroup: ui.FormGroup,
    FormLabel: ui.FormLabel,
    DatePicker: ui.DatePicker,
    TimePicker: ui.TimePicker,
    Select: ui.Select,
    Radio: ui.Radio,
    RadioGroup: ui.RadioGroup,
    Checkbox: ui.Checkbox,
    CheckboxGroup: ui.CheckboxGroup,

    // components
    Btn: ui.Btn,
    Tabs: ui.Tabs,
    Tab: ui.Tab,
    Tags: ui.Tags,
    FontStyle: ui.FontStyle,

    // modal
    ModalBody: ui.ModalBody,
    ModalTitle: ui.ModalTitle,
    ModalFooter: ui.ModalFooter,
    ModalHeader: ui.ModalHeader,
    Modal: ui.Modal,
  },
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {

    const i18n = useI18n()
    const tasksStore = useTasksStore()

    // -------------------------------------------------------------------------
    const task = computed(() => {
      return tasksStore.state.currentTask
    })

    // -------------------------------------------------------------------------
    const loading = computed(() => {
      return tasksStore.state.loadingTask
    })

    // -------------------------------------------------------------------------
    const showEdit = computed(() => {
      return tasksStore.state.showEdit
    })

    // -------------------------------------------------------------------------
    const chaos = ref(false)
    var oldStatusId = ''
    const changeChaos = (checkbox: boolean) => {
      if (!task.value) {
        return
      }
      if (!checkbox) {  // смена статуса из списка (вкладка "Инфо")
        let obj = refs.value.statuses.find(o => o.id == task.value?.status.id)
        if (obj) {
          chaos.value = obj.name == 'CHAOS'
        }
        changeTask.value = true
        return
      }
      // смена состояния чекбокса - Хаос (не обработано)
      if (chaos.value) {
          let obj = refs.value.statuses.find(o => o.name == 'CHAOS')
          if (obj && tasksStore.state.currentTask) {
            task.value.status.id = obj.id
            tasksStore.state.currentTask.status.id = obj.id
          }
      } else {
        task.value.status.id = oldStatusId
      }
      changeTask.value = true
    }

    // -------------------------------------------------------------------------
    const weekdays = computed( () => {  // список дней недели
      return [
        {text: "week.Mon",   value:  "1"},
        {text: "week.Tue",   value:  "2"},
        {text: "week.Wed",   value:  "3"},
        {text: "week.Thu",   value:  "4"},
        {text: "week.Fri",   value:  "5"},
        {text: "week.Sat",   value:  "6"},
        {text: "week.Sun",   value:  "7"}
    ]})

    // -------------------------------------------------------------------------
    const month = () => {  // список месяцев
      const arr = []
      dayjs.locale(i18n.t("days-js.lang"))
      for (let i=1; i<=12; i++) {
        arr.push({
          "text": dayjs(`${i}.${i}.01`).format('D MMMM').split(' ')[1],
          "value": i.toString(),
        })
      }
      return arr
    };

    // -------------------------------------------------------------------------
    const textTabStyle = computed(() => {
      let content = tasksStore.state.currentTask?.content
      if (content && content.trim().length > 0 &&
          content.trim() != '<html></html>') {
        return '#0062b2'
      }
      return 'gray'
    })

    // -------------------------------------------------------------------------
    const refs = computed(() => {
      return {
        'statuses': tasksStore.state.statuses,
        'prioritets': tasksStore.state.prioritets,
        'wait_groups': tasksStore.state.wait_groups,
    }})

    // -------------------------------------------------------------------------
    const statuses = computed(() => {
      // TODO: текст на нужном языке должен приходить из БД
      return Array.from(tasksStore.state.statuses,
        el => {
          return { value: el.id, text: i18n.t(`tasks.status.${el.name}` || '') }
    })})

    // -------------------------------------------------------------------------
    const prioritets = computed(() => {
      // TODO: текст на нужном языке должен приходить из  БД
      return Array.from(tasksStore.state.prioritets,
        el => {
          return { value: el.id, text: `tasks.prioritet.${el.name}` }
    })})

    const prioritet = ref(
      tasksStore.state.currentTask?.prioritet.id || 'id=????'
    )

    // -------------------------------------------------------------------------
    var tempDate = ref(dayjs(new Date()).format('YYYY-MM-DD'))
    var tempTime = ref(dayjs(new Date()).format('HH:mm'))

    // -------------------------------------------------------------------------
    watch (
      () => tasksStore.state.currentTask,
      () => {
        prioritet.value = tasksStore.state.currentTask?.prioritet.id || ''

        // срок (дата и время, а также его наличие)
        let dt = dayjs(tasksStore.state.currentTask?.deadline)
        let is_dt = tasksStore.state.currentTask?.is_deadline_date
        let is_dt_time = is_dt && tasksStore.state.currentTask?.is_deadline_time
        tempDate.value = is_dt ? dt.format('YYYY-MM-DD') : ""
        tempTime.value = is_dt_time ? dt.format('HH:mm') : ""

        changeTask.value = false

        oldStyle = JSON.stringify(tasksStore.state.currentTask?.style)
        oldWaitLen = tasksStore.state.currentTask?.wait.length || 0
        waitDelete.value = []
        deleteWaitItem.value = ''

        // состояние - Хаос (не обработано)
        if (tasksStore.state.currentTask?.status.name == 'CHAOS') {
          chaos.value = true
          let obj = refs.value.statuses.find(o => o.name == 'CREATED')
          oldStatusId = obj ? obj.id : '--'
        } else {
          chaos.value = false
          oldStatusId = tasksStore.state.currentTask?.status.id || '--'
        }
    })

    // -------------------------------------------------------------------------
    watch (
      () => prioritet.value,
      () => {
        if (tasksStore.state.currentTask &&
            prioritet.value != tasksStore.state.currentTask.prioritet.id) {
          changeTask.value = true
    }})

    var oldStyle = '', oldWaitLen = 0

    // -------------------------------------------------------------------------
    watch (
      () => [task.value?.style.color, task.value?.style.bold],
      () => {
        if (oldStyle != JSON.stringify(task.value?.style))
          changeTask.value = true
    })

    // -------------------------------------------------------------------------
    watch (
      () => task.value?.wait.length,
      () => {
        if (oldWaitLen != task.value?.wait.length) {
          changeTask.value = true
        }
    })

    var waitDelete = ref<string[]>([])
    var deleteWaitItem = ref('')

    // -------------------------------------------------------------------------
    const availableMayDeleteWaitOptions = computed(() => {
      // групппы ожидания доступные для удаления
      let st = tasksStore.state

      var fData = st.wait_groups
      // оставляем только те, которые можно удалить
      fData = fData.filter(o => o.may_delete)
      // убираем, те которые есть в ожидании текущей задачи
      fData = fData.filter(o => !st.currentTask?.wait.find(w => w.id == o.id))
      // убираем, уже выбранные для удаления
      fData = fData.filter(o => !waitDelete.value.find(w => w == o.id))

      return Array.from(fData, el => {return { value: el.id,  text: el.name }})
    })

    // -------------------------------------------------------------------------
    const refs_wait_groups = computed(() => {
      return tasksStore.state.wait_groups
             .filter(o => !waitDelete.value.find(w => w == o.id))
    })

    // -------------------------------------------------------------------------
    const deleteWait = () => {
      if (deleteWaitItem.value) {
        waitDelete.value.push(deleteWaitItem.value)
        changeTask.value = true
      }
    }

    // -------------------------------------------------------------------------
    // TODO: "НЕУКЛЮЖЕЕ" решение (во все элементы пришлось добавить @input)
    // TODO: и кучу watch (там где @input не работает)
    const changeTask = ref(false)
    const changeData = (element: string) => {
      //console.log(element)
      changeTask.value = true
    }

    // -------------------------------------------------------------------------
    const enabledSave = computed(() => {
      var emptySubj = true
      if (tasksStore.state.currentTask) {
        emptySubj = !tasksStore.state.currentTask.subj.trim()
      }
      return changeTask.value && !emptySubj
    })

    // -------------------------------------------------------------------------
    const saveText = (value: string) => {
      if (task.value) {
        if (task.value.content != value) {
          changeTask.value = true
          task.value.content = value
        }
      }
    }

    // -------------------------------------------------------------------------
    const saveTask = () => {
      if (!task.value) {
        return
      }

      // установка значения для приоритета
      let obj = prioritets.value.find(o => o.value === prioritet.value)
      if (obj) {
        task.value.prioritet.name = obj.text.split('.').pop() || '??'
        task.value.prioritet.id = obj.value
      }

      // установка срока (с учетом часового пояса)
      task.value.is_deadline_date = Boolean(tempDate.value)
      task.value.is_deadline_time = Boolean(tempDate.value) &&
                                    Boolean(tempTime.value)
      if (task.value.is_deadline_date) {
        dayjs.extend(utc)
        var dt = dayjs(tempDate.value)
        if (task.value.is_deadline_time) {
          dt = dayjs(tempDate.value + " " + tempTime.value)
        }
        task.value.deadline = dt.utc().format()
      }

      // ----------------------------------------------------
      // запись данных
      tasksStore.action(RootMutationType.tasks.saveTask,
        {task: task.value, wait_delete: waitDelete.value})
    }

    // -------------------------------------------------------------------------
    const cancelEdit = () => {
      tasksStore.action(RootMutationType.tasks.cancelEditTask)
    }

    // -------------------------------------------------------------------------
    onMounted(() => {  // TODO: может убрать ??
      //tasksStore.action(RootMutationType.tasks.loadTask, '---')
      //tasksStore.action(RootMutationType.tasks.cancelEditTask)
    });

    // -------------------------------------------------------------------------
    return {
      i18n,
      isMobile,
      getImage,
      dayjs,
      task,
      loading,
      showEdit,
      refs,
      textTabStyle,
      saveTask,
      cancelEdit,
      saveText,
      month,
      weekdays,
      tempDate,
      tempTime,
      chaos,
      prioritets,
      prioritet,
      statuses,
      availableMayDeleteWaitOptions,
      getTextDate,
      getNextDate,
      changeData,
      enabledSave,
      changeChaos,
      refs_wait_groups,
      deleteWait,
      deleteWaitItem,
    }
  }
})
