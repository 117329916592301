

import { defineComponent, computed } from 'vue'
import Box, {parseMP} from '../layout/Box.vue'


export default defineComponent({
  name: 'Text',
  components: {
    Box
  },
  inheritAttrs:true,

  props: {
    mp: { type: String, default: '' },
    color: { type: String, default: '' },
    background: { type: String, default: '' },
    bold:  { type: Boolean, default: false },
    height: { type: String, default: '' },
    fontsize: { type: String, default: '' },
  },

  setup(props) {

    const cssClass = computed(() => {
      let classes: string[] = []
      return classes.join(' ')
    })

    const cssStyle = computed(() => {
      var styles = ''
      if (props.background) {
        styles += `background-color: ${props.background};`
      }
      if (props.bold) {
        styles += `font-weight: bold;`
      }
      if (props.color) {
        styles += `color: ${props.color};`
      }
      if (props.height) {
        styles += `height: ${props.height};`
        styles += `max-height: ${props.height};`
        styles += `min-height: ${props.height};`
      }
      if (props.fontsize) {
        styles += `font-size: ${props.fontsize};`
      }
      const obj = parseMP(props.mp)
      styles += Array.from(Object.keys(obj), k=>[`${k}:${obj[k]};`]).join('')
      return styles
    })

    return {
      cssClass,
      cssStyle,
    };
  }
})
