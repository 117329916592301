

import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { Container, Box, Text } from "@/components/base-ui"

export default defineComponent({
  name: 'Errors',

  components: {
    Container, Box, Text
  },

  metaInfo: {
    title: 'LiGM - органайзер [ERROR]',
  },

  setup() {
    const route = useRoute()
    const i18n = useI18n()

    const err = computed(() => {
      return String(route.params.err)
    })

    return {
      i18n,
      err
    }
  }
})
