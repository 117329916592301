import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ded0cf8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _mergeProps({ class: _ctx.cssClass }, _ctx.attrs), [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cols, (col, index) => {
      return (_openBlock(), _createElementBlock("col", {
        key: index,
        width: col
      }, null, 8, _hoisted_1))
    }), 128)),
    _createElementVNode("tbody", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 16))
}