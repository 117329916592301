/* CRC:52F62E42:6 */
/* Реализация интерфейсов общего хранилища приложения */

import { createStore, StoreOptions } from 'vuex'
import {
  RootStateInterface,
  RootStoreModel,
  LocalesStateInterface
} from '@/models'
import { initialRootState } from './initialState'

// import each Vuex module
import { tasksState } from '@/store/tasks/module'
import { tblState } from '@/store/tbl/module'
import { moneyState } from '@/store/money/module'
import { authState } from '@/store/auth/module'
import { localesState } from '@/store/locales/module'

// Vuex store options to build our modularized namespaced store
const storeOptions: StoreOptions<RootStateInterface> = {
  state: initialRootState,

  modules: {
    tasksState,
    tblState,
    moneyState,
    authState,
    localesState
  }
}

// Vuex Root store instance
export const rootStore:
  RootStoreModel<RootStateInterface> = <any>createStore(storeOptions)

// Private helper to dispatch an action to a Vuex module from one place so
// we keep the string interpolation for `${moduleName}/${actionName}` in
// one place only and be able to dispatch action with less code in a
// strongly-type way
export function dispatchModuleAction<T>(
  moduleName: string, actionName: string, params?: T): void {
    // rename store.dispatch here to rootStore.dispatch
    rootStore.dispatch(`${moduleName}/${actionName}`, params)
  }
