/* CRC:B4DF9E75:4 */
/* Реальная реализация интерфейса TasksApiClientInterface */

// TODO: может стоит именно здесь делать логику, а не в [...]ApiClient.model

import {
  TasksApiClientUrlsInterface,
  TasksApiClientInterface,
  TasksApiClientModel
} from '@/models/api-client/tasks'
import { config } from '@/config'

const urls: TasksApiClientUrlsInterface = config.api.tasks.urls
const apiVersion: string = config.api.tasks.version


const tasksApiClient:
     TasksApiClientInterface = new TasksApiClientModel(urls, apiVersion)

// export our instance
export default tasksApiClient
