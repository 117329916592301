import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-757a0d63"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "date-button"
}
const _hoisted_2 = ["value", "id"]
const _hoisted_3 = ["for"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["id", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.image)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("input", {
            type: "date",
            class: "input-date-button",
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
            value: _ctx.modelValue,
            id: _ctx.uid
          }, null, 40, _hoisted_2),
          _createElementVNode("label", { for: _ctx.uid }, [
            _createElementVNode("img", {
              src: _ctx.image,
              class: "image-date-button"
            }, null, 8, _hoisted_4)
          ], 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.image)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.cssClass),
          style: _normalizeStyle(_ctx.cssStyle)
        }, [
          _createElementVNode("input", _mergeProps({
            type: "date",
            class: _ctx.cssInput
          }, _ctx.$attrs, {
            id: _ctx.uid,
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
            value: _ctx.modelValue,
            disabled: _ctx.disabled,
            style: _ctx.cssStyle
          }), null, 16, _hoisted_5),
          (_ctx.clearButton && !_ctx.intable && !_ctx.isMobile())
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: _normalizeClass(_ctx.cssButton),
                type: "button",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.reset()))
              }, "✖ ", 2))
            : _createCommentVNode("", true)
        ], 6))
      : _createCommentVNode("", true)
  ], 64))
}