/* CRC:8DB10716:2 */
/* Экземпляр AuthStateInterface с начальными значениями */


import { AuthStateInterface } from '@/models'


export const initialAuthState: AuthStateInterface = {
  isLogged: false,
  user:
  {
    id: '',
    email: '',
    enabled: true,
    f: '',
    i: '',
    o: '',
    roles: []
  }
}
