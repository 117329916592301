import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BtnClose = _resolveComponent("BtnClose")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: _ctx.className }, _ctx.context.attrs, {
    style: _ctx.headerStyle()
  }), [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.close)
      ? (_openBlock(), _createBlock(_component_BtnClose, {
          key: 0,
          white: _ctx.closeWhite,
          onClick: _withModifiers(_ctx.closeModal, ["prevent"])
        }, null, 8, ["white", "onClick"]))
      : _createCommentVNode("", true)
  ], 16))
}