
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "ModalTitle",
  props: {
    tag: {
      type: String,
      default: "h5"
    },
    bold: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { attrs }) {
    const className = computed(() => {
      return ["modal-title", props.bold && "font-weight-bold"]
    });

    return {
      className,
      attrs,
      props
    };
  }
})
