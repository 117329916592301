/* CRC:F50B6B4F:5 */
/* Все имена пространств Vuex */

// group our constants in a namespace:
export namespace RootModuleNames {
  export const localesState: string = 'localesState'

  export const tasksState: string = 'tasksState'
  export const authState: string = 'authState'
  export const tblState: string = 'tblState'
  export const moneyState: string = 'moneyState'
}
