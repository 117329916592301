/* CRC:23D30A55:4 */
/* Обертка модулей локализации */

import { rootStore, dispatchModuleAction } from '../root'
import {
  RootMutationType,
  RootModuleNames,
  LocalesStateInterface
} from '@/models'

import { LocalStorageKeys } from '@/models/local-storage/LocalStorageKeys'

// The locales store wrapper that returns the localesState and exposes a
// generic action<T> method
const localesStore = {
  get state(): LocalesStateInterface {
    return rootStore.state.localesState
  },
  action<T>(actionName: string, params?: T): void {
    dispatchModuleAction(RootModuleNames.localesState, actionName, params)
  }
}

// export our wrapper using the composition API convention (i.e. useXYZ)
export const useLocalesStore = () => {
  return localesStore
}
