
import { defineComponent, computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { RootMutationType } from '@/models'
import { Container, Row, Col } from '@/components/base-ui'
import { isMobile } from '@/components/base-ui'

export default defineComponent({

  name: 'Next',
  components: {
    Container, Row, Col
  },
  // ---------------------------------------------------------------------------
  setup() {

    const i18n = useI18n()

    const cssContainer = computed(() => {
      if (isMobile()) {
        return 'p-3'
      }
      return 'p-0'
    })

    // -------------------------------------------------------------------------
    // TODO: сделать глобальный метод (ф-ия встречается в разных местах)
    const getImage = (name: string) => {
      return require(`@/assets/${name}`)
    };

    return {
      i18n,
      cssContainer,
      getImage,
    }
  }
  })
