/* CRC:39AF5024:13 */
/* Реализация интерфейса TasksApiClientInterface */

import { TasksApiClientUrlsInterface } from './TasksApiClientUrls.interface'
import { TasksApiClientInterface } from './TasksApiClient.interface'
import { SerializedTasksState, SerializedTask, TaskInterface,
         TaskWaitGroupsInterface } from '@/models'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'
import { useTasksStore } from '@/store/tasks'
//import { config } from '@/config'

/**
* @Name ItemsApiClientModel
* @description
* Implements the ItemsApiClientInterface interface
*/
export class TasksApiClientModel implements TasksApiClientInterface {
  private readonly urls!: TasksApiClientUrlsInterface
  private readonly apiVersion!: string

  constructor(urls: TasksApiClientUrlsInterface, apiVersion: string) {
    this.urls = urls
    this.apiVersion = apiVersion
  }

  // ---------------------------------------------------------------------------
  fetchTasks(filters?: {}): Promise<SerializedTasksState> {
    const getParameters: HttpRequestParamsInterface = {
      url: this.urls.fetchTasks,
      version: this.apiVersion,
      payload: {}
    }

    const url = (filters as {[k: string]: any})?.url
    if (url) {
      getParameters.url = url
    }

    const menu = useTasksStore().state.menu
    getParameters.payload = {
      'filter': menu.items[menu.currentIndex].name,
      'show-ok': menu.showOK,
      'search-text': menu.isSearch ? menu.searchText.trim() : '',
      'timedifference': new Date().getTimezoneOffset(),
      'date': menu.date
    }

    return HttpClient.get<SerializedTasksState>(getParameters)
  }

  // ---------------------------------------------------------------------------
  fetchTask(id: string): Promise<SerializedTask> {
    const getParameters: HttpRequestParamsInterface = {
      url: `${this.urls.fetchTask}/${id}`,
      version: this.apiVersion,
      payload: {}
    }
    return HttpClient.get<SerializedTask>(getParameters)
  }

  // ---------------------------------------------------------------------------
  // TODO: вместо object нужно возвращать ошибку и наверное сохраненную задачу
  saveTask(task: TaskInterface, action?: string,
           wait_delete?: TaskWaitGroupsInterface[]): Promise<object> {
    const putParameters: HttpRequestParamsInterface = {
      url: `${this.urls.saveTask}/${task.id}`,
      version: this.apiVersion,
      payload: {'task': task, 'wait_delete': wait_delete, 'action': action}
    }
    return HttpClient.put<object>(putParameters)
    }

  // ---------------------------------------------------------------------------
  // TODO: вместо object нужно возвращать какой то результат
  transferOverdue(timeInc: number): Promise<object> {
    const putParameters: HttpRequestParamsInterface = {
      url: `${this.urls.transferOverdue}`,
      version: this.apiVersion,
      payload: {'time_inc': timeInc}
    }
    return HttpClient.put<object>(putParameters)
  }

  // ---------------------------------------------------------------------------
  // TODO: вместо object нужно возвращать какой то результат
  checkOverdue(): Promise<object> {
    const putParameters: HttpRequestParamsInterface = {
      url: `${this.urls.checkOverdue}`,
      version: this.apiVersion,
      payload: {'timedifference': new Date().getTimezoneOffset()}
    }
    return HttpClient.get<object>(putParameters)
  }
}
