import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Box = _resolveComponent("Box")!
  const _component_Text = _resolveComponent("Text")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, null, {
    default: _withCtx(() => [
      _createVNode(_component_Box, { mp: "60" }),
      ('403,500'.indexOf(_ctx.err) == -1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createVNode(_component_Text, {
              fontsize: "30px",
              align: "center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.i18n.t('error.Page not found')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Text, {
              fontsize: "16px",
              align: "center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.i18n.t('error.Error')) + " 400 ", 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.err == '403')
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _createVNode(_component_Text, {
              fontsize: "30px",
              align: "center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.i18n.t('error.Access denied')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Text, {
              fontsize: "16px",
              align: "center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.i18n.t('error.Error')) + " 400 ", 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.err == '500')
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createVNode(_component_Text, {
              fontsize: "30px",
              align: "center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.i18n.t('error.Unexpected error on the server')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Text, {
              fontsize: "16px",
              align: "center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.i18n.t('error.Error')) + " 400 ", 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}