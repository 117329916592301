
import { defineComponent, onMounted, ref, computed, watch, reactive } from 'vue'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import 'dayjs/locale/en'
import utc from 'dayjs/plugin/utc'

import Loader from '@/components/shared/Loader.vue'
import * as ui from '@/components/base-ui'

import { useMoneyStore } from '@/store/money'
import { RootMutationType } from '@/models'
import { useI18n } from 'vue-i18n'
import { getTextDate, isMobile } from '@/components/base-ui'
import { getImage } from'@/utils'
import '@/components/base-ui/common.scss'


export default defineComponent({
  name: 'OperEdit',
  props: {
    msg: String
  },
  // TODO: удалить неиспользуемые компоненты
  components: {
    Loader,

    // layout
    Container: ui.Container,
    Row: ui.Row,
    Col: ui.Col,
    Box: ui.Box,
    Table: ui.Table,

    // forms
    Number: ui.Number,
    Edit: ui.Edit,
    Textarea: ui.Textarea,
    FormGroup: ui.FormGroup,
    FormLabel: ui.FormLabel,
    DatePicker: ui.DatePicker,
    TimePicker: ui.TimePicker,
    Select: ui.Select,
    Radio: ui.Radio,
    RadioGroup: ui.RadioGroup,
    Checkbox: ui.Checkbox,
    CheckboxGroup: ui.CheckboxGroup,

    // components
    Btn: ui.Btn,
    Tabs: ui.Tabs,
    Tab: ui.Tab,
    Tags: ui.Tags,
    Text: ui.Text,
    FontStyle: ui.FontStyle,

    // modal
    ModalBody: ui.ModalBody,
    ModalTitle: ui.ModalTitle,
    ModalFooter: ui.ModalFooter,
    ModalHeader: ui.ModalHeader,
    Modal: ui.Modal,
  },
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {

    const i18n = useI18n()
    const moneyStore = useMoneyStore()

    // -------------------------------------------------------------------------
    const typeOpers = () => {
      return [
        {text: "Приход",   value:  1},
        {text: "Расход",  value:  2},
        {text: "Перевод",    value:  3},
        {text: "Корректировка",   value:  4},
      ]
    }

    // -------------------------------------------------------------------------
    const oper = computed(() => {
      return moneyStore.state.currentItem
    })

    // -------------------------------------------------------------------------
    const loading = computed(() => {
      return moneyStore.state.loading
    })

    // -------------------------------------------------------------------------
    const showEdit = computed(() => {
      return moneyStore.state.showEdit
    })

    // -------------------------------------------------------------------------
    const refs = computed(() => {
      return {
        'accounts': moneyStore.state.accounts,
        'products': moneyStore.state.products
    }})

    // -------------------------------------------------------------------------
    const accounts = computed(() => {
      return Array.from(moneyStore.state.accounts,
        el => {
          return { value: el.id, text: el.name }
    })})

    // -------------------------------------------------------------------------
    const products = computed(() => {
      return Array.from(moneyStore.state.products,
        el => {
          return { value: el.id, text: el.name }
    })})

    // -------------------------------------------------------------------------
    watch (
      () => moneyStore.state.currentItem,
      () => {
        accPlusID.value = oper.value?.acc_plus?.id || ''
        accMinusID.value = oper.value?.acc_minus?.id || ''
        if (oper.value?.tp != 2) {
          summa.value  = oper.value?.sum_plus || 0
        } else {
          summa.value  = oper.value?.sum_minus || 0
        }

        for (let i=0; i<5; i++) {
          check[i] = {id: '', cnt: '', price: ''}
        }
        if (oper.value && oper.value.check) {
          for (let i in oper.value.check) {
            if (Number(i) < 5) {
              check[Number(i)].id = oper.value.check[i].product.id
              check[Number(i)].cnt = oper.value.check[i].cnt.toString()
              check[Number(i)].price = oper.value.check[i].price.toString()
            }
        }}
      })
    watch (() => moneyStore.state.showEdit,
           () => {
            isChangeData.value = false
    })

    // -------------------------------------------------------------------------
    // TODO: "НЕУКЛЮЖЕЕ" решение (во все элементы пришлось добавить @input)
    // TODO: и кучу watch (там где @input не работает)
    const isChangeData = ref(false)
    const changeData = (element?: string) => {
      //console.log(element)
      isChangeData.value = true
    }

    // -------------------------------------------------------------------------
    const enabledSave = computed(() => {
      return isChangeData.value
    })

    const accPlusID = ref('')
    const accMinusID = ref('')
    const summa = ref(0)
    const showConfirmDelete = ref(false)

    const check = reactive([
        {id: '', cnt: '', price: ''},
        {id: '', cnt: '', price: ''},
        {id: '', cnt: '', price: ''},
        {id: '', cnt: '', price: ''},
        {id: '', cnt: '', price: ''},
    ])

    // -------------------------------------------------------------------------
    const saveOper = () => {
      if (!oper.value) {
        return
      }

      // установка значения для операции
      oper.value.tp = Number(oper.value?.tp || 1)
      oper.value.sum_plus = summa.value
      oper.value.sum_minus = summa.value
      oper.value.acc_plus = {id: accPlusID.value, name: ''}
      oper.value.acc_minus = {id: accMinusID.value, name: ''}
      if (oper.value.tp == 1 || oper.value.tp == 4) {
        oper.value.sum_minus = 0
        oper.value.acc_minus = null
      }
      if (oper.value.tp == 2) {
        oper.value.sum_plus = 0
        oper.value.acc_plus = null
      }

      oper.value.check = []
      for (let i=0; i<5; i++) {
        if (check[i].id && Number(check[i].cnt) * Number(check[i].price)) {
          oper.value.check.push({
            product: {id: check[i].id, name: ''},
            cnt: Number(check[i].cnt),
            price: Number(check[i].price)
          })
        }
      }

      // ----------------------------------------------------
      // запись данных
      moneyStore.action(RootMutationType.money.saveOper, {oper: oper.value})
    }

    // -------------------------------------------------------------------------
    const cancelEdit = () => {
      moneyStore.action(RootMutationType.money.cancelEditOper)
    }

    // -------------------------------------------------------------------------
    const confirmDelete = () => {
      moneyStore.action(RootMutationType.money.cancelEditOper)
      showConfirmDelete.value = true
    }

    // -------------------------------------------------------------------------
    const cancelDelete = () => {
      showConfirmDelete.value = false
      //tasksStore.action(RootMutationType.tasks.cancelClose)
    }

    // -------------------------------------------------------------------------
    const deleteOper = () => {
      showConfirmDelete.value = false
      if (oper.value) {
        oper.value.deleted = true
        saveOper()
      }
    }

    // -------------------------------------------------------------------------
    // TODO: вынести в утилиты
    const formatPrice = (value: number) => {
        let val = (value/1).toFixed(2);//.replace('.', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")// + ' руб.'
    }

    // -------------------------------------------------------------------------
    onMounted(() => {  // TODO: может убрать ??
      //moneysStore.action(RootMutationType.moneys.loadmoney, '---')
      //moneysStore.action(RootMutationType.moneys.cancelEditmoney)
    });

    // -------------------------------------------------------------------------
    return {
      i18n,
      isMobile,
      getImage,
      dayjs,
      oper,
      loading,
      showEdit,
      refs,
      saveOper,
      cancelEdit,
      getTextDate,
      changeData,
      enabledSave,
      typeOpers,
      accPlusID,
      accMinusID,
      summa,
      accounts,
      products,
      check,
      formatPrice,
      showConfirmDelete,
      confirmDelete,
      cancelDelete,
      deleteOper,
    }
  }
})
