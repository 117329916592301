import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Box = _resolveComponent("Box")!
  const _component_Image = _resolveComponent("Image")!

  return (_ctx.model)
    ? (_openBlock(), _createBlock(_component_Box, {
        key: 0,
        tr: "",
        background: _ctx.background,
        onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mouseOnThis=true)),
        onMouseout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mouseOnThis=false))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Box, {
            td: "",
            align: "middle",
            border: ",1,,,lightgray",
            mp: ",10,,10"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.dayjs(_ctx.model.date_oper).format('DD.MM.YYYY')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Box, {
            td: "",
            class: "text-align-right",
            color: _ctx.colorSum,
            align: "middle",
            border: ",1,,,lightgray",
            mp: ",10,,10",
            nowrap: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatSum), 1)
            ]),
            _: 1
          }, 8, ["color"]),
          (!_ctx.isMobile() && _ctx.model.acc_plus && _ctx.model.acc_minus)
            ? (_openBlock(), _createBlock(_component_Box, {
                key: 0,
                td: "",
                class: "text-align-center",
                border: ",1,,,lightgray",
                mp: ",10,,10",
                fontsize: "0.8em",
                nowrap: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Box, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.model.acc_plus), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Box, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.model.acc_minus), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.isMobile() && !_ctx.model.acc_plus && _ctx.model.acc_minus)
            ? (_openBlock(), _createBlock(_component_Box, {
                key: 1,
                td: "",
                class: "text-align-center",
                border: ",1,,,lightgray",
                mp: ",10,,10",
                nowrap: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.model.acc_minus), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.isMobile() && _ctx.model.acc_plus && !_ctx.model.acc_minus)
            ? (_openBlock(), _createBlock(_component_Box, {
                key: 2,
                td: "",
                class: "text-align-center",
                border: ",1,,,lightgray",
                mp: ",10,,10",
                nowrap: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.model.acc_plus), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.isMobile() && !_ctx.model.acc_plus && !_ctx.model.acc_minus)
            ? (_openBlock(), _createBlock(_component_Box, {
                key: 3,
                td: "",
                class: "text-align-center",
                border: ",1,,,lightgray",
                mp: ",10,,10",
                fontsize: "0.8em",
                nowrap: ""
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_Box, {
            td: "",
            class: "text-align-left",
            align: "middle",
            border: ",1,,,lightgray",
            mp: ",10,,10",
            nowrap: !_ctx.isMobile()
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.model.desc), 1)
            ]),
            _: 1
          }, 8, ["nowrap"]),
          _createElementVNode("td", null, [
            _withDirectives(_createVNode(_component_Image, {
              onClick: _ctx.onClickEdit,
              class: "cursor-pointer",
              path: "img/tasks/t-edit.png",
              height: "20"
            }, null, 8, ["onClick"]), [
              [_vShow, _ctx.mouseOnThis]
            ]),
            _withDirectives(_createVNode(_component_Image, {
              onClick: _ctx.onClickEdit,
              class: "cursor-pointer",
              path: "img/tasks/m-empty.png",
              height: "20"
            }, null, 8, ["onClick"]), [
              [_vShow, !_ctx.mouseOnThis]
            ])
          ])
        ]),
        _: 1
      }, 8, ["background"]))
    : _createCommentVNode("", true)
}