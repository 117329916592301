

import { defineComponent, computed, PropType } from 'vue'
import Checkbox from './Checkbox.vue'


interface Option {
  text: string
  value: string
  cur_value?: boolean
}

export default defineComponent({
  name: 'CheckboxGroup',
  inheritAttrs:true,

  props: {
    modelValue: { type: Array as PropType<Array<String>>, default: [] },
    disabled: { type: Boolean, default: false },
    options: { type: Array as PropType<Array<Option>>, default: [] },
  },

  components: {
    Checkbox
  },

  setup(props, { emit }) {

    // TODO: "хреновая" реализация, переделать
    const setValues = (option: string, checked: boolean) => {
      if (!checked) {
        while (props.modelValue.indexOf(option) !== -1) {
          props.modelValue.splice(props.modelValue.indexOf(option), 1);
        }
      } else {
        if (props.modelValue.indexOf(option) == -1) {
          props.modelValue.push(option)
        }
      }
      emit('update:modelValue', props.modelValue.sort())
    }

    // TODO: "хреновая" реализация, переделать
    const getValues = computed(() => {
      var values: Array<Option> = []
      for (let i in props.options) {
        var val = {
          'text': props.options[i].text,
          'value': props.options[i].value,
          'cur_value': false
        }
        for (let j in props.modelValue) {
          if (props.modelValue[j] == props.options[i].value) {
            val.cur_value = true
          }
        }
        values.push(val)
      }
      return values
    })


    return {
      getValues,
      setValues
    };
  }
})
