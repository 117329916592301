/* CRC:1789B7E7:17 */
/* Роутер */

import { createRouter, createWebHashHistory,
         RouteRecordRaw, NavigationGuardNext } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/#Login.vue'
import Note from '../views/#Note.vue'
import Register from '../views/#Register.vue'
import Next from '../views/#Next.vue'
import Tasks from '../views/Tasks.vue'
import Tbls from '../views/Tbls.vue'
import Money from '../views/Money.vue'
import Errors from '@/components/shared/Errors.vue'
import { config } from '@/config'
import { useAuthStore } from '@/store/auth'
import { useTasksStore } from '@/store/tasks'
import { RootMutationType } from '@/models'
import { getCookie, checkAccess } from '@/utils'


const routes: Array<RouteRecordRaw> = [

  { path: '/', name: 'Home', component: Dashboard },

  { path: '/tasks', name: 'Tasks', component: Tasks,
    meta: { authorize: 'EVERYONE' },
  },
  { path: '/money', name: 'Money', component: Money,
    meta: { authorize: 'EVERYONE' },
  },
  { path: '/tbls', name: 'Tbls', component: Tbls,
    meta: { authorize: 'RukavishnikovVV@mail.ru' },
  },

  // TODO: Экспериментальные маршруты
  { path: '/next', name: 'Next', component: Next,
    children: [
      { path: 'login', name: 'TempLogin', component: Login },
      { path: 'register', name: 'TempRegister', component: Register  },
      { path: '', name: 'Note', component: Note  },
    ]
  },

  // -------------------- ссылки на LiGM-AUTH ----------------------------------
  { path: '/', name: 'Profile',
    beforeEnter: (to, from, next) => auth(config.api.auth.urls.editUser, next),
    component: { template: '<div></div>'}
  },

  { path: '/', name: 'Password',
    beforeEnter: (to, from, next) =>
                 auth(config.api.auth.urls.changePassword, next),
    component: { template: '<div></div>'}
  },

  { path: '/', name: 'Login',
    beforeEnter: (to, from, next) => {
      auth(config.api.auth.urls.login, next)
      useAuthStore().action(RootMutationType.auth.updateUser)
    },
    component: { template: '<div></div>'}
  },

  { path: '/', name: 'Logout',
    beforeEnter: (to, from, next) => {
      useAuthStore().action(RootMutationType.auth.logoutUser)
      useTasksStore().action(RootMutationType.tasks.setCountOverdue, 0)
      next('/')
    },
    component: { template: '<div></div>'}
  },
  //

  // -------------------- ссылки на dev-Plan -----------------------------------
  {
    path: '/', name: 'DevPlan',
    beforeEnter() { location.href = config.api.dev.urls.devPlan },
    component: { template: '<div></div>'}
  },

  // ----------------------------- ОШИБКИ --------------------------------------
  // TODO: убрать путь  /errors/:err - сделать path: '/' и в компонент Errors
  // TODO: передавать параметр (номер ошибки)
  // TODO: иначе если произошла ошибка, то текущий путь будет, что то навроде
  // TODO: https://127.0.0.1/tst#/errors/403 и соответственно при обновлении
  // TODO: страницы - обновляется страница с ошибкой. а не целевая страница
  { path: '/errors/:err', name: 'Errors', component: Errors, },
  { path: "/:catchAll(.*)", component: Errors },   // для страница не найдена

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


// -----------------------------------------------------------------------------
router.beforeEach((to, from, next) => {

  var authorize = to.meta.authorize as string || ''

  useAuthStore().action(RootMutationType.auth.updateUser)


  // ===========================================================================
  // ===========================================================================
  // ===========================================================================
  /* TODO: вместо следующего блока должно стоять, что то навроде
     TODO:   if (useAuthStore().state.isLogged && authorize) {
     TODO:     return auth(config.api.auth.urls.login, next)
     TODO:   }
     TODO: но на самом деле, после перезагрузки страницы, updateUser не успевает
     TODO: выполнится и всегда выходит сообщение об ошибке, наверное стоит
     TODO: сделать какой либо признак, что все загрузилось или поставить
     TODO: задержку перед опросом useAuthStore().state.isLogged
  */
  if (!useAuthStore().state.isLogged) {
    return next()
  }
  // ===========================================================================
  // ===========================================================================
  // ===========================================================================

  if (!checkAccess(authorize) && to.name != 'Errors') {
    console.info('authorize: НЕТ ДОСТУПА.')
    return next('/errors/403')
  }

  if (getCookie('access_token')) {
    if (!useAuthStore().state.user.enabled && to.name != 'Errors') {
      console.info('authorize: НЕТ ДОСТУПА (блокировка пользователя).')
      return next('/errors/403')
    }
  }

  next()
})


// -----------------------------------------------------------------------------
function auth(link: string, next: NavigationGuardNext) {
  let next_path = router.currentRoute.value.path
  var url = `${link}?next=${next_path}`
  // TODO: непонятно почему, но работает только c window.location.href,
  // TODO: если просто использовать next, то форма ВХОДА не показывается,
  // TODO: хотя переход по ссылке происходит (от flask ответ есть).
  window.location.href = url
  return next({ path: link, query: { next: next_path } })
}


export default router
