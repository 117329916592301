/* CRC:D984333:4 */
/* Реализация интерфейса AuthApiClientInterface */

import { AuthApiClientUrlsInterface } from './AuthApiClientUrls.interface'
import { AuthApiClientInterface } from './AuthApiClient.interface'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'
import { useAuthStore } from '@/store/auth'
//import { config } from '@/config'


export class AuthApiClientModel implements AuthApiClientInterface {
  private readonly urls!: AuthApiClientUrlsInterface
  private readonly apiVersion!: string

  constructor(urls: AuthApiClientUrlsInterface, apiVersion: string) {
    this.urls = urls
    this.apiVersion = apiVersion
  }

  // ---------------------------------------------------------------------------
  // TODO: вместо object нужно возвращать какой то результат
  refreshTokens(): Promise<object> {
    const getParameters: HttpRequestParamsInterface = {
      url: `${this.urls.refreshTokens}`,
      version: this.apiVersion,
      payload: {}
    }
    return HttpClient.get<object>(getParameters)
  }

  // ---------------------------------------------------------------------------
  // TODO: вместо object нужно возвращать какой то результат
  logoutUser(): Promise<object> {
    const getParameters: HttpRequestParamsInterface = {
      url: `${this.urls.logout}`,
      version: this.apiVersion,
      payload: {}
    }
    return HttpClient.get<object>(getParameters)
  }

  // ---------------------------------------------------------------------------
  // TODO: вместо object нужно возвращать какой то результат
  loginUser(): Promise<object> {
    const getParameters: HttpRequestParamsInterface = {
      url: `${this.urls.logout}`,
      version: this.apiVersion,
      payload: {}
    }
    return HttpClient.get<object>(getParameters)
  }

}
