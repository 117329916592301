
import { useI18n } from 'vue-i18n'
import { defineComponent, computed, watch, onMounted } from 'vue'
import { RootMutationType } from '@/models'
import { useLocalesStore } from '@/store/locales'
import { LocaleInfoInterface } from '@/models/locales/LocaleInfo.interface'
import LocaleSelector from '@/components/locale-selector/LocaleSelector.vue'
import TasksOverdue from '@/components/tasks/TasksOverdue.vue'
import MainHeader from '@/components/main/MainHeader.vue'
import { useTasksStore } from '@/store/tasks'
import { useAuthStore } from '@/store/auth'

import { Container, Row, Col, Box } from '@/components/base-ui'
import { isMobile } from '@/components/base-ui'
import { getImage } from '@/utils'


export default defineComponent({
  name: 'App',
  components: {
    LocaleSelector,
    Container, Row, Col, Box,
    TasksOverdue,
    MainHeader
  },
  // ---------------------------------------------------------------------------
  setup() {
    //const state = reactive({ display: true })

    const i18n = useI18n()
    const localesStore = useLocalesStore()
    const tasksStore = useTasksStore()
    const authStore = useAuthStore()

    const availableLocales = computed(() => {
      return localesStore.state.availableLocales
    })

    const onLocaleClicked = (localeInfo: LocaleInfoInterface) => {
      localesStore.action(
        RootMutationType.locales.selectLocale,
        localeInfo.locale
      )
    }

    const cssContainer = computed(() => {
      if (isMobile()) {
        return 'p-3'
      }
      return 'p-0'
    })

    // -------------------------------------------------------------------------
    onMounted(() => {
      // обновление информации о пользователе
      authStore.action(RootMutationType.auth.updateUser)

      // запуск таймера для проверки наличия просроченных задач
      tasksStore.state.overdueTasks.timer = setInterval(
        () => tasksStore.action(RootMutationType.tasks.checkOverdue),
              tasksStore.state.overdueTasks.interval
      )
    })

    // -------------------------------------------------------------------------
    watch (
      () => authStore.state.isLogged,
      () => { tasksStore.action(RootMutationType.tasks.checkOverdue) })

    // -------------------------------------------------------------------------
    return {
      i18n,
      availableLocales,
      onLocaleClicked,
      cssContainer,
      getImage,
    }
  }
  })
