/* CRC:88EAFCA7:3 */
/* Реализация интерфейсов хранилища TBL */

// TODO: разделить файл - отдельно мутации, отдельно действия

import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'

import {
  RootMutationType,
  RootStateInterface,
  TblStateInterface,
  SerializedTblState,
  TblInterface,
  TblErrorInterface,
  TblMenuInterface
} from '@/models'

import { initialTblState } from './initialState'
import { initialTbl } from './initialTbl'
import { getCookie } from '@/utils'

import apiClient from '@/api-client'


// =============================================================================
export const mutations: MutationTree<TblStateInterface> = {
  loadingTbls(state: TblStateInterface) {
    state.error =  { status: 0, statusText: '', msg: '' }
    state.loading = true
    state.items = []
  },
  loadedTbls(state: TblStateInterface, data: SerializedTblState) {
    state.items = data.opers
    state.menu.items = data.menu
    state.loading = false
  },
  loadingTbl(state: TblStateInterface) {
    state.currentItem = initialTbl
    state.showEdit = true
  },
  loadedTbl(state: TblStateInterface, data: TblInterface) {
    state.currentItem = data
  },
  cancelEditTbl(state: TblStateInterface) {
    state.showEdit = false
  },
  // TODO: не доделано
  saveTbl(state: TblStateInterface, tbl: TblInterface) {

    // TODO: tbl не нужно передавать (изменения уже в хранилище)
    //  хотя наверное все таки лучше передавать явно
    //console.log('MUTATIONS: saveTask')
    //console.log('BEGIN: mutations:saveTask')
    /*
    if (state.currentTask) {
      console.log(state.currentTask.id, '--------', state.currentTask.subj)
    }
    console.log(task.id, '--------', task.subj)
    console.log({...task})
    */
    state.showEdit = false
    //console.log('END: mutations:saveTask')
  },

  // ===========================================================================
  updateError(state: TblStateInterface, error: TblErrorInterface ) {
    state.error = error
  },

  // ===========================================================================
  updateMenu(state: TblStateInterface, menu: TblMenuInterface ) {
    if (menu && menu.items != undefined) {
      state.menu.items = menu.items
    }
    if (menu && menu.currentIndex != undefined) {
      state.menu.currentIndex = menu.currentIndex >= 0 ? menu.currentIndex : 0
    }
    if (menu && menu.showMenu != undefined) {
      state.menu.showMenu = menu.showMenu;
    }
    // корректируем текущий элемент (на всякий случай)
    if (state.menu.currentIndex >= state.menu.items.length){
      state.menu.currentIndex = 0
    }
  },
}

// TODO: слишком часто встречается, вынести куданибудь в общие классы
interface Param {
  [key:string]: any;
}

// =============================================================================
export const actions: ActionTree<TblStateInterface, RootStateInterface> = {
  // ---------------------------------------------------------------------------
  loadTbls({ commit, dispatch }, filter) {
    commit(RootMutationType.tbl.loadingTbls)

    apiClient.tbl.fetchTbls(filter).then((data: SerializedTblState) => {
      commit(RootMutationType.tbl.loadedTbls, data)
      commit(RootMutationType.tbl.updateMenu)
    })
    // TODO: сделать хранение ошибки вне подсистемы и обновлять ошибку
    // TODO: нужно на APIClient (может вообще на HTTPClient)
    .catch(err => {
      commit(RootMutationType.tbl.updateError, {
        status: err.response.status,
        statusText: err.response.statusText,
        msg: err.response.data.error,
    })})
  },
  // ---------------------------------------------------------------------------
  loadTbl({ commit, dispatch }, param: Param) {
    commit(RootMutationType.tbl.loadingTbl)

    commit(RootMutationType.tbl.updateMenu, param)
    let id = state.menu.items[state.menu.currentIndex].id

    apiClient.tbl.fetchTtbl(id).then((
      data: TblInterface) => {
        commit(RootMutationType.tbl.loadedTbl, data)
        dispatch(RootMutationType.tbl.loadTbls)
    })
    // TODO: сделать хранение ошибки вне подсистемы и обновлять ошибку
    // TODO: нужно на APIClient (может вообще на HTTPClient)
    .catch(err => {
      commit(RootMutationType.tbl.updateError, {
        status: err.response.status,
        statusText: err.response.statusText,
        msg: err.response.data.error,
    })})
  },
  // ---------------------------------------------------------------------------
  cancelEditTbl({ commit, dispatch }) {
    commit(RootMutationType.tbl.cancelEditTbl)
  },
  // ---------------------------------------------------------------------------
  incTbl({ commit, dispatch }, param: Param) {

    commit(RootMutationType.tbl.updateMenu, param)
    let id = state.menu.items[state.menu.currentIndex].id

    apiClient.tbl.fetchTtbl(id).then((
      data: TblInterface) => {
        commit(RootMutationType.tbl.loadedTbl, data)
        dispatch(RootMutationType.tbl.loadTbls)
        if (state.currentItem) {
          state.currentItem.one[1] += 1  // увеличиваем расход
          dispatch(RootMutationType.tbl.saveTbl)
        }
      })
    // TODO: сделать хранение ошибки вне подсистемы и обновлять ошибку
    // TODO: нужно на APIClient (может вообще на HTTPClient)
    .catch(err => {
      commit(RootMutationType.tbl.updateError, {
        status: err.response.status,
        statusText: err.response.statusText,
        msg: err.response.data.error,
    })})
  },
  // ---------------------------------------------------------------------------
  changeFilter({ commit, dispatch }, param: Param) {
    commit(RootMutationType.tbl.updateMenu, param)
    dispatch(RootMutationType.tbl.loadTbls)
    /*
    if (param.isMobile? param.isMobile : false) {
      commit(RootMutationType.tbl.updateMenu, { showMenu: false })
    }
    */
  },

  // ---------------------------------------------------------------------------
  saveTbl({ commit, dispatch }) {
    if (!state.currentItem) {
      return
    }

    apiClient.tbl.saveTbl(state.currentItem)
      .then((data: object) => {
        dispatch(RootMutationType.tbl.loadTbls)
      })
      // TODO: сделать хранение ошибки вне подсистемы и обновлять ошибку
      // TODO: нужно на APIClient (может вообще на HTTPClient)
      .catch(err => {
        commit(RootMutationType.tbl.updateError, {
          status: err.response.status,
          statusText: err.response.statusText,
          msg: err.response.data.error,
      })})
    commit(RootMutationType.tbl.saveTbl)
  },

  // ===========================================================================
  updateMenu({ commit }, menu: TblMenuInterface ) {
    commit(RootMutationType.tbl.updateMenu, menu)
  },
}


// =============================================================================
export const getters: GetterTree<TblStateInterface, RootStateInterface> = {
}


// =============================================================================
const namespaced: boolean = true
const state: TblStateInterface = initialTblState

export const tblState: Module<TblStateInterface, RootStateInterface> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
