
import { defineComponent, computed, onMounted } from 'vue'
import { useMoneyStore } from '@/store/money'
import Loader from '@/components/shared/Loader.vue'
import OperList from '@/components/money/OperList.vue'
import OperEdit from '@/components/money/OperEdit.vue'
import MoneyMenu from '@/components/money/MoneyMenu.vue'
import MoneyHeader from '@/components/money/MoneyHeader.vue'
import { MoneyOperInterface } from '@/models/money/Money.interface'
import { RootMutationType } from '@/models'

import { Container, Row, Col, Checkbox, FormGroup, FormLabel, DatePicker,
  TimePicker, Tabs, Tab, Table, Btn, isMobile, Box } from '@/components/base-ui'

import { useI18n } from 'vue-i18n'
import '@/components/base-ui/common.scss'

interface Param {
    [key:string]: any;
}


export default defineComponent({
  name: 'Money',
  // ---------------------------------------------------------------------------
  components: {
    OperList,
    OperEdit,
    MoneyMenu,
    MoneyHeader,
    Loader,
    Container, Row, Col, Checkbox, FormGroup, FormLabel, DatePicker, Box,
    TimePicker, Tabs, Tab, Table, Btn
  },

  // ---------------------------------------------------------------------------
  setup() {
    const i18n = useI18n()
    const moneyStore = useMoneyStore()

    // -------------------------------------------------------------------------
    const opers = computed(() => {
      return moneyStore.state.items
    })

    // -------------------------------------------------------------------------
    const menu = computed(() => {
      return moneyStore.state.menu
    })

    // -------------------------------------------------------------------------
    const error = computed(() => {
      return moneyStore.state.error
    })

    // -------------------------------------------------------------------------
    const showEdit = computed(() => {
      return moneyStore.state.showEdit
    })

    // -------------------------------------------------------------------------
    const loading = computed(() => {
      return moneyStore.state.loading
    })

    // -------------------------------------------------------------------------
    onMounted(() => {
      moneyStore.action(RootMutationType.money.loadOpers)
      moneyStore.action(RootMutationType.tbl.updateMenu,
                        { showMenu: !isMobile() })
    })

    // -------------------------------------------------------------------------
    const flipMenu = (filter: Param) => {
      moneyStore.action(RootMutationType.tbl.updateMenu, {
        showMenu: !moneyStore.state.menu.showMenu
      })
    }

    // -------------------------------------------------------------------------
    const changeFilter = (idx: string) => {
      moneyStore.action(RootMutationType.money.changeFilter,
        {currentIndex: idx, isMobile: isMobile()})
    }

    // -------------------------------------------------------------------------
    const onNewOper = () => {
      moneyStore.action(RootMutationType.money.loadOper, 'no-id')
    }

    // -------------------------------------------------------------------------
    const onSaveOper = (oper: MoneyOperInterface) => {
      moneyStore.action(RootMutationType.money.saveOper, {oper: oper})
    }

    // -------------------------------------------------------------------------
    const onCancelEdit = () => {
      moneyStore.action(RootMutationType.money.cancelEditOper)
    }

    // -------------------------------------------------------------------------
    const onOperEdit = (id: string) => {
      // console.log('EDIT', id)
      moneyStore.action(RootMutationType.money.loadOper, id)
    }


    // -------------------------------------------------------------------------
    return {
      i18n,
      opers,
      loading,
      showEdit,
      onSaveOper,
      onCancelEdit,
      onNewOper,
      menu,
      changeFilter,
      flipMenu,
      onOperEdit,
      error
    }
  }
})
