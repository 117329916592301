import { renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Box = _resolveComponent("Box")!

  return (_openBlock(), _createBlock(_component_Box, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _mergeProps({
        class: _ctx.cssClass,
        style: _ctx.cssStyle
      }, _ctx.$attrs), [
        _renderSlot(_ctx.$slots, "default")
      ], 16)
    ]),
    _: 3
  }))
}