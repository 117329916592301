

import { defineComponent, computed, PropType, watch, ref } from 'vue'
import { getUID } from '../getUID'
import Radio from './Radio.vue'


interface Option {
  text: string
  value: string
  cur_value?: boolean
}

export default defineComponent({
  name: 'RadioGroup',
  inheritAttrs:true,

  props: {
    modelValue: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    options: { type: Array as PropType<Array<Option>>, default: [] },
  },

  components: {
    Radio
  },

  setup(props, { emit }) {
    const group = getUID('group-')
    const cur_value = ref(props.modelValue)

    // TODO: может стоит переделать передачу значений - передавать словарь
    // TODO: {id, ...}
    // вместо @input="$emit('.... использовать
    // @input="setValues(option.value, $event.target.checked)"
    /*const setValues = (option: string, checked: boolean) => {
      if (!checked) {
        while (props.modelValue.indexOf(option) !== -1) {
          props.modelValue.splice(props.modelValue.indexOf(option), 1);
        }
      } else {
        if (props.modelValue.indexOf(option) == -1) {
          props.modelValue.push(option)
        }
      }
      emit('update:modelValue', props.modelValue.sort())
    }*/

    // TODO: "хреновая" реализация, переделать
    const getValues = computed(() => {
      var values: Array<Option> = []
      for (let i in props.options) {
        var val = {
          'text': props.options[i].text,
          'value': props.options[i].value,
          'cur_value': false
        }
        values.push(val)
      }
      return values
    })

    watch (
      () => props.modelValue,
      () => { cur_value.value=props.modelValue }
    )

    return {
      getValues,
      cur_value,
      group,
      //setValues
    };
  }
})
