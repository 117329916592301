
import { defineComponent, computed, PropType, ref, onMounted } from "vue"

import dayjs from 'dayjs'

import { useI18n } from 'vue-i18n'
import { MoneyOperInListInterface } from "@/models/money/Money.interface"
import { isMobile, Image, Box } from '@/components/base-ui'
import '@/components/base-ui/common.scss'


export default defineComponent({
  name: "Oper",
  // ---------------------------------------------------------------------------
  props: {
    model: {
      type: Object as PropType<MoneyOperInListInterface>,
    },
  },
  // ---------------------------------------------------------------------------
  components: {
    Image, Box
  },
  // ---------------------------------------------------------------------------
  emits: ['editOper'],
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {
    const i18n = useI18n()
    const mouseOnThis = ref(false)

    var taskDate = ref(dayjs(new Date()).format('YYYY-MM-DD'))

    const background = computed(() => {
      if (props.model && props.model.not_completed) {
        return 'rgb(251, 231, 231)'
      }
      return 'inherit'
    })

    // -------------------------------------------------------------------------
    const onClickEdit = () => {
      emit('editOper', props.model?.id)
    }

    // -------------------------------------------------------------------------
    onMounted(() => {
    })

    // -------------------------------------------------------------------------
    // TODO: вынести в утилиты
    const formatPrice = (value: number) => {
        let val = (value/1).toFixed(2);//.replace('.', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' руб.'
    }

    // -------------------------------------------------------------------------
    const formatSum = computed(() => {
      if (!props.model) {
        return ""
      }
      if (props.model.sum_plus && props.model.sum_minus) {
        return "±" + formatPrice(props.model.sum_minus)
      }
      if (!props.model.sum_plus && props.model.sum_minus) {
        return "-" + formatPrice(props.model.sum_minus)
      }
      if (props.model.sum_plus && !props.model.sum_minus) {
        return "+" + formatPrice(props.model.sum_plus)
      }
      return ""
    })

    // -------------------------------------------------------------------------
    const colorSum = computed(() => {
      if (!props.model) {
        return "black"
      }
      if (!props.model.sum_plus && props.model.sum_minus) {
        return "#8B0000"    // расход
      }
      if (props.model.sum_plus && !props.model.sum_minus) {
        return "darkgreen"  // приход
      }
      return "black"
    })

    // -------------------------------------------------------------------------
    return {
      i18n,
      onClickEdit,
      mouseOnThis,
      taskDate,
      dayjs,
      isMobile,
      formatSum,
      colorSum,
      background,
    }
  },
})
