
import { computed, defineComponent } from "vue"

export default defineComponent({
  name: "Btn",
  props: {
    color: { type: String, default: "" },
    textcolor: { type: String, default: "" },
    background: { type: String, default: "" },
    bold: { type: Boolean, default: false },
    size:  { type: String, default: "" },
    outline:  { type: String, default: "" },
    block: { type: Boolean, default: false },
    mp: { type: String, default: '' },

    disabled: { type: Boolean, default: false },

    normal: { type: Boolean, default: false },
    cancel: { type: Boolean, default: false },
  },
  inheritAttrs: false,

  setup(props, { attrs }) {

    const className = computed(() => {
      return [
        'btn',
        props.color && `btn-${props.color}`,
        props.size && `btn-${props.size}`,
        props.outline && `btn-outline-${props.outline}`,
        props.block && "btn-block",
        props.normal && "normal",
        props.cancel && "cancel",
        props.disabled && "disabled",
        props.mp || "",
        props.bold && "bold",
      ]
    })

    const styleBtn = computed(() => {
      return (props.textcolor && `color: ${props.textcolor};`) +
             (props.background && `background-color: ${props.background};`)
    })

    return {
      className,
      attrs,
      props,
      styleBtn
    }
  }
})
