
import { defineComponent, computed, PropType, ref, onMounted } from "vue"

import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import 'dayjs/locale/en'
import utc from 'dayjs/plugin/utc'

import { useI18n } from 'vue-i18n'
import { TaskInListInterface } from "@/models/tasks/Task.interface"
import {
  Container, Row, Col, Checkbox, FormGroup, FormLabel, DatePicker,
  TimePicker, Tabs, Tab, Table, Btn, Card, Image, Box
} from '@/components/base-ui'
import '@/components/base-ui/common.scss'
import { getImage } from '@/utils'


export default defineComponent({
  name: "TaskMobile",
  // ---------------------------------------------------------------------------
  props: {
    model: {
      type: Object as PropType<TaskInListInterface>,
    },
  },
  // ---------------------------------------------------------------------------
  components: {
    Container, Row, Col, Checkbox, FormGroup, FormLabel, DatePicker,
    TimePicker, Tabs, Tab, Table, Btn, Card, Image, Box
  },
  // ---------------------------------------------------------------------------
  emits: ["closeTask", "editTask", "updateTask"],
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {
    const i18n = useI18n()
    const mouseOnThis = ref(false)

    var taskDate = ref(dayjs(props.model?.date).format('YYYY-MM-DD'))
    var taskTime = ref(dayjs(props.model?.date).format('HH:mm'))
    if (!props.model?.closed && (
      !props.model?.is_deadline_date || !props.model?.is_deadline_time)) {
      taskTime.value = "--:--"
    }
    // -------------------------------------------------------------------------
    const onClick = () => {
      emit('closeTask', props.model?.id)
    }
    // -------------------------------------------------------------------------
    const subjColor = () => {
      var color = 'black'
      if (props.model?.style) {
        if (props.model.style.indexOf('color') != -1) {
          color = props.model.style.split(';')[0].split(':')[1]
        }
      }
      return color
    }
    // -------------------------------------------------------------------------
    const subjBold = () => {
      var bold = false
      if (props.model?.style) {
        bold = props.model.style.indexOf('font-weight: bold') != -1
      }
      return bold
    }
    // -------------------------------------------------------------------------
    const onClickEdit = () => {
      emit('editTask', props.model?.id)
    };

    // -------------------------------------------------------------------------
    const updateTask = (field?: string) => {
      // TODO: иногда данная процедура вызывается 2 раза, сделать проверку
      // TODO: перед сохранением (prevSubj != subj && prevDate != date)
      if (!props.model) {
        return
      }
      var task: {[key: string]: any} = {id: props.model.id}

      // установка срока
      let isDeadlineDate = Boolean(taskDate.value)
      let isDeadlineTime = Boolean(taskTime.value) && Boolean(taskDate.value)
      if (isDeadlineDate) {
        dayjs.extend(utc)
        var dt = dayjs(taskDate.value)
        if (isDeadlineTime) {
          dt = dayjs(taskDate.value + " " + taskTime.value)
        }
        task['deadline'] = dt.utc().format()
        if (field == "date") {
          task['is_deadline_date'] = isDeadlineDate
        }
        if (field == "time") {
          task['is_deadline_time'] = isDeadlineTime
        }
      }
      emit('updateTask', task)
    }

    // -------------------------------------------------------------------------
    return {
      i18n,
      onClick,
      onClickEdit,
      getImage,
      mouseOnThis,
      taskDate,
      taskTime,
      dayjs,
      updateTask,
      subjColor,
      subjBold
    };
  },
});
