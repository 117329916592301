
import { defineComponent, reactive, computed, onMounted } from "vue"
import { Container, Row, Col, Table, Image } from "@/components/base-ui"

export default defineComponent({

  name: "Note",

  components: {
    Container,
    Row,
    Col,
    Table,
    Image,
  },

  // ---------------------------------------------------------------------------
  setup(props, { emit }) {
    const title = "Введите Ваши учетные данные";
    const userDetails = {
      email: null,
      password: null,
    }

    const tst = () => {
      console.log("TST")
    };

    // -------------------------------------------------------------------------
    return {
      title,
      userDetails,
    }
  },
})
