import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "locale-selector" }
const _hoisted_2 = { class: "locale-radio-group" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LocaleFlagRadio = _resolveComponent("LocaleFlagRadio")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.availableLocales)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableLocales, (localeInfo, index) => {
              return (_openBlock(), _createBlock(_component_LocaleFlagRadio, {
                key: index,
                localeInfo: localeInfo,
                onClicked: _ctx.onFlagClicked
              }, null, 8, ["localeInfo", "onClicked"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}