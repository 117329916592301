
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { TaskInterface } from '@/models/tasks/Task.interface'
import Task from './Task.vue'
import TaskMobile from './TaskMobile.vue'
import Loader from '@/components/shared/Loader.vue'
import { Table, Container, Row, Col, Box, Text } from '@/components/base-ui'
import { isMobile } from '@/components/base-ui'
import '@/components/base-ui/common.scss'


export default defineComponent({
  name: "TasksList",
  // ---------------------------------------------------------------------------
  components: {
    Task,
    TaskMobile,
    Container, Row, Col, Box, Table, Text,
    Loader,
  },
  // ---------------------------------------------------------------------------
  props: {
    tasks: {
      type: Array as PropType<TaskInterface[]>
    },
    loading: {
      type: Boolean
    }
  },
  // ---------------------------------------------------------------------------
  emits: ['closeTask', 'editTask', 'updateTask'],
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {
    const i18n = useI18n()
    const onCloseTask = (task: TaskInterface) => {
      emit('closeTask', task)
    }
    const onEditTask = (task: TaskInterface) => {
      emit('editTask', task)
    }
    const onUpdateTask = (task: TaskInterface) => {
      emit('updateTask', task)
    }
    // -------------------------------------------------------------------------
    return {
      onCloseTask,
      onEditTask,
      onUpdateTask,
      i18n,
      isMobile
    }
  }
  // ---------------------------------------------------------------------------
})
