/* CRC:D96EF06C:3 */
/* Различные функции, утилиты общего доступа */

/* jshint esversion: 8 */

import { useAuthStore } from '@/store/auth'


// =============================================================================
export const getCookie = (cname: string) => {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }

    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }

  return ''
}


// =============================================================================
// TODO: в auth сделать использование данной функции для установки isLogged
// TODO: isLogged = doesHttpOnlyCookieExist('refresh_token')
export const doesHttpOnlyCookieExist = (cookiename: string): boolean => {
  var d = new Date()
  d.setTime(d.getTime() + (1000))
  var expires = "expires=" + d.toUTCString()

  document.cookie = cookiename + "=new_value;path=/;" + expires
  if (document.cookie.indexOf(cookiename + '=') == -1) {
    return true
  } else {
    return false
  }
}


// =============================================================================
/* проверка доступа */
export const checkAccess = (roles: string) => {

  var authorize: string[] = []//roles
  let isLogged = useAuthStore().state.isLogged
  let userEmail = useAuthStore().state.user.email
  let userRoles = useAuthStore().state.user.roles


  if (roles) {
    authorize = roles.toUpperCase().split(',')
    authorize = authorize.map(function (x) { return x.trim().toUpperCase(); })
    //console.info('checkAccess: Доступ разрешен для: ', authorize)
  } else {
    // console.info('checkAccess: Ограничений доступа нет.')
  }

  // если правила нет, то доступ у всех
  var accessEnabled = Boolean(!authorize.length)

  if (isLogged && authorize.length > 0) {  // все авторизованные
    accessEnabled = authorize.indexOf('EVERYONE') != -1
  }

  if (!isLogged && authorize.length > 0) {
    accessEnabled = false
  }

  if (!accessEnabled && isLogged) {  // проверка доступа по email
    let email = userEmail.toUpperCase()
    accessEnabled = authorize.indexOf(email) != -1
  }

  if (!accessEnabled && isLogged) {  // проверка групп
    for (let i in userRoles) {
      let role = userRoles[i]
      if (authorize.indexOf(role) != -1) {
        accessEnabled = true
        break
      }
    }
  }

  return accessEnabled
}

// для изменения интерфейса в зависимости от ширины экрана
// (в частности для мобильников)
export function smallWidth() {
  return document.documentElement.clientWidth < 800
}

export const getImage = (name: string) => {
  return require(`@/assets/${name}`)
}
