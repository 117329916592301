/* CRC:81605216:3 */
/* Реализация интерфейсов хранилища подсистемы Финансы */

// TODO: разделить файл - отдельно мутации, отдельно действия

import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'

import {
  RootMutationType,
  RootStateInterface,
  MoneyStateInterface,
  SerializedMoneyState,
  MoneyOperInterface,
  SerializedMoneyOper,
  MoneyErrorInterface,
  MoneyMenuInterface
} from '@/models'

import { initialMoneyState } from './initialState'
import { initialMoneyOper } from './initialMoney'
import { getCookie } from '@/utils'

import apiClient from '@/api-client'


// =============================================================================
export const mutations: MutationTree<MoneyStateInterface> = {
  loadingOpers(state: MoneyStateInterface) {
    state.error =  { status: 0, statusText: '', msg: '' }
    state.loading = true
    state.items = []
  },
  loadedOpers(state: MoneyStateInterface, data: SerializedMoneyState) {
    state.items = data.opers
    state.menu.items = data.menu
    state.loading = false
  },
  loadingOper(state: MoneyStateInterface) {
    state.currentItem = initialMoneyOper
    state.showEdit = true
  },
  loadedOper(state: MoneyStateInterface, data: SerializedMoneyOper) {
    state.currentItem = data.operation
    state.accounts = data.accounts
    state.products = data.products
  },
  cancelEditOper(state: MoneyStateInterface) {
    state.showEdit = false
  },
  // TODO: не доделано
  saveOper(state: MoneyStateInterface, tbl: MoneyOperInterface) {

    // TODO: tbl не нужно передавать (изменения уже в хранилище)
    //  хотя наверное все таки лучше передавать явно
    //console.log('MUTATIONS: saveTask')
    //console.log('BEGIN: mutations:saveTask')
    /*
    if (state.currentTask) {
      console.log(state.currentTask.id, '--------', state.currentTask.subj)
    }
    console.log(task.id, '--------', task.subj)
    console.log({...task})
    */
    state.showEdit = false
    //console.log('END: mutations:saveTask')
  },

  // ===========================================================================
  updateError(state: MoneyStateInterface, error: MoneyErrorInterface ) {
    state.error = error
  },

  // ===========================================================================
  updateMenu(state: MoneyStateInterface, menu: MoneyMenuInterface ) {
    if (menu && menu.items != undefined) {
      state.menu.items = menu.items
    }
    if (menu && menu.currentIndex != undefined) {
      state.menu.currentIndex = menu.currentIndex >= 0 ? menu.currentIndex : 0
    }
    if (menu && menu.showMenu != undefined) {
      state.menu.showMenu = menu.showMenu;
    }
    // корректируем текущий элемент (на всякий случай)
    if (state.menu.currentIndex >= state.menu.items.length){
      state.menu.currentIndex = 0
    }
  },
}


// TODO: слишком часто встречается, вынести куданибудь в общие классы
interface Param {
  [key:string]: any;
}


// =============================================================================
export const actions: ActionTree<MoneyStateInterface, RootStateInterface> = {
  // ---------------------------------------------------------------------------
  loadOpers({ commit, dispatch }, filter) {
    commit(RootMutationType.money.loadingOpers)

    apiClient.money.fetchOpers(filter).then((data: SerializedMoneyState) => {
      commit(RootMutationType.money.loadedOpers, data)
      commit(RootMutationType.money.updateMenu)
    })
    // TODO: сделать хранение ошибки вне подсистемы и обновлять ошибку
    // TODO: нужно на APIClient (может вообще на HTTPClient)
    .catch(err => {
      commit(RootMutationType.money.updateError, {
        status: err.response.status,
        statusText: err.response.statusText,
        msg: err.response.data.error,
    })})
  },
  // ---------------------------------------------------------------------------
  loadOper({ commit }, id) {
    commit(RootMutationType.money.loadingOper)
    apiClient.money.fetchOper(id).then((
      data: SerializedMoneyOper) => {
        commit(RootMutationType.money.loadedOper, data)
    })
    // TODO: сделать хранение ошибки вне подсистемы и обновлять ошибку
    // TODO: нужно на APIClient (может вообще на HTTPClient)
    .catch(err => {
      commit(RootMutationType.money.updateError, {
        status: err.response.status,
        statusText: err.response.statusText,
        msg: err.response.data.error,
    })})
  },
  // ---------------------------------------------------------------------------
  cancelEditOper({ commit }) {
    commit(RootMutationType.money.cancelEditOper)
  },
  // ---------------------------------------------------------------------------
  saveOper({ commit, dispatch }, data: {oper: MoneyOperInterface }) {

    apiClient.money.saveOper(data.oper)
      .then((data: object) => {
        // console.log("SAVE OK", data)
        dispatch(RootMutationType.money.loadOpers)
      })
      // TODO: сделать хранение ошибки вне подсистемы и обновлять ошибку
      // TODO: нужно на APIClient (может вообще на HTTPClient)
      .catch(err => {
        commit(RootMutationType.money.updateError, {
          status: err.response.status,
          statusText: err.response.statusText,
          msg: err.response.data.error,
      })})
    commit(RootMutationType.money.saveOper, data.oper)
  },

  // ---------------------------------------------------------------------------
  newOper({ commit }) {
    commit(RootMutationType.money.loadingOper)
    apiClient.money.fetchOper('no-id').then((
      data: SerializedMoneyOper) => {
        data.operation = JSON.parse(JSON.stringify(initialMoneyOper))
        commit(RootMutationType.money.loadedOper, data)
    })
    // TODO: сделать хранение ошибки вне подсистемы и обновлять ошибку
    // TODO: нужно на APIClient (может вообще на HTTPClient)
    .catch(err => {
      commit(RootMutationType.money.updateError, {
        status: err.response.status,
        statusText: err.response.statusText,
        msg: err.response.data.error,
    })})
  },

  // ---------------------------------------------------------------------------
  updateMenu({ commit }, menu: MoneyMenuInterface ) {
    commit(RootMutationType.money.updateMenu, menu)
  },

  // ---------------------------------------------------------------------------
  changeFilter({ commit, dispatch }, param: Param) {
    commit(RootMutationType.money.updateMenu, param)
    dispatch(RootMutationType.money.loadOpers)
    if (param.isMobile? param.isMobile : false) {
      commit(RootMutationType.money.updateMenu, { showMenu: false })
    }
  },
}


// =============================================================================
export const getters: GetterTree<MoneyStateInterface, RootStateInterface> = {
}


// =============================================================================
const namespaced: boolean = true
const state: MoneyStateInterface = initialMoneyState

export const moneyState: Module<MoneyStateInterface, RootStateInterface> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
