/* CRC:C33119CD:1 */
/* Экземпляр MoneyOperInterface с начальными значениями */


import { MoneyOperInterface, MoneyOperInListInterface } from '@/models'
import dayjs from 'dayjs'


export const initialMoneyOper: MoneyOperInterface = {
  id: 'no-id',
  dt: dayjs(new Date()).format('YYYY-MM-DD'),
  tp: 2, // расход
  sum_plus: 0,
  acc_minus: {id: '', name: ''},
  sum_minus: 0,
  position: 1,
  txt: '',
  rem: '',
  not_completed: false,
  to_control: false,
  deleted: false,
  check: [],
  bac: '',
  owner: 'unknown'
}

export const initialMoneyOperInList: MoneyOperInListInterface = {
  id: '',
  date_oper: '',
  position: 0,
  acc_plus: '',
  sum_plus: 0,
  acc_minus: '',
  sum_minus: 0,
  desc: '',
  not_completed: false
}
