import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.localeInfo)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createElementVNode("label", {
          role: "radio",
          class: _normalizeClass(`locale-radio ${_ctx.localeInfo.selected ? 'selected' : ''}`.trim())
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(`flag-icons ${_ctx.localeInfo.flag}`)
          }, null, 2),
          _withDirectives(_createElementVNode("input", {
            type: "radio",
            class: "icon-button",
            name: "locale",
            value: _ctx.localeInfo.selected,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localeInfo.selected) = $event)),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
          }, null, 8, _hoisted_2), [
            [_vModelRadio, _ctx.localeInfo.selected]
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}