

import { defineComponent } from 'vue'
import { Container, Row, Col, Image } from "@/components/base-ui"

export default defineComponent({

  name: 'Login',

  components: {
    Container,
    Row,
    Col,
    Image,
  },

  // ---------------------------------------------------------------------------
  setup(props, { emit }) {

    const title = "Введите Ваши учетные данные"
    const userDetails = {
          email: null,
          password: null
    }

    /*
      showNotification(msg, alertType) {
        this.$notify({
          group: "foo",
          text: `<div class="alert ${alertType}" role="alert">${msg}</div> `,
          position: "top left",
          duration: 10000
        });
      },
    */

    const googleLogin = () => {
        let that = this;
        //var token = "result.credential.accessToken";
        var user = "result.user;";
        /*
        that.$notify({
          group: "foo",
          text: `<div class="alert alert-success" role="alert">
                            <p> Welcome ${user.displayName}</p>
                    You are logged in as ${user.email}
                </div> `,

          position: "top left",
          duration: 10000
        });
        that.sendDataToStore(user);
        that.$router.push("a1-todo");
        */
    }

    // -------------------------------------------------------------------------
    return {
      title,
      userDetails,
    }
  }

})
