import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5aa6c482"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!
  const _component_Col = _resolveComponent("Col")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Row = _resolveComponent("Row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Row, {
      mp: "p-1",
      vcenter: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Col, {
          col: "3",
          sm: "1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Btn, {
              normal: "",
              onClick: _ctx.toggleTabColors
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.i18n.t('task.Color')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_Col, {
          col: "9",
          sm: "4",
          center: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              style: _normalizeStyle(_ctx.colorStyle())
            }, _toDisplayString(_ctx.i18n.t('task.Example of the task text')), 5)
          ]),
          _: 1
        }),
        _createVNode(_component_Col, {
          col: "12",
          sm: "1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Checkbox, {
              switch: "",
              caption: "task.bold",
              modelValue: _ctx.modelValue.bold,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.bold) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_Row, { mp: "p-1 ps-1" }, {
      default: _withCtx(() => [
        (_ctx.showColorTab)
          ? (_openBlock(), _createBlock(_component_Col, {
              key: 0,
              col: "12",
              sm: "12"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.COLORS, (value, index) => {
                  return (_openBlock(), _createElementBlock("span", {
                    size: "sm",
                    style: _normalizeStyle(_ctx.colorsStyle(value)),
                    class: "mr-5 btn-color",
                    key: index,
                    onClick: ($event: any) => (_ctx.setColor(value))
                  }, null, 12, _hoisted_1))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}