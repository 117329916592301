
import { defineComponent, computed } from "vue"

export default defineComponent({
  name: "FormGroup",
  props: {
    // TODO: сделать, что нибудь навроде mixin, данные свойства нужны везде
    small: { type: Boolean, default: false },
    mp: { type: String, default: '' },

    // TODO: сделать, что нибудь навроде mixin, данные свойства нужны везде
    top: { type: Boolean, default: false },
    bottom: { type: Boolean, default: false },
    vcenter: { type: Boolean, default: false },
    left: { type: Boolean, default: false },
    right: { type: Boolean, default: false },

  },

  setup(props) {
    const cssClass = computed(() => {
      let classes = [
        "input-group",
        // TODO: сделать, что нибудь навроде mixin, данные свойства нужны везде
        props.small ? "small": "",
        props.mp || "",

        // TODO: сделать, что нибудь навроде mixin, данные свойства нужны везде
        props.top ? "align-self-start": "",
        props.bottom ? "align-self-end": "",
        props.vcenter ? "align-self-center": "",
        props.left ? "d-flex justify-content-start": "",
        props.right ? "d-flex justify-content-end": "",
      ]

      return classes.join(' ').trim()
    })
    return {
      cssClass,
    }
  }
})

