
import { defineComponent, PropType, computed, ref, watch} from "vue"
import { useI18n } from 'vue-i18n'
import { Edit, Select } from '@/components/base-ui'

interface Tag {
  id: string
  name: string
  may_delete?: boolean
}

export default defineComponent({
  name: "Tags",
  inheritAttrs: true,
  props: {
    modelValue: { type: Array as PropType<Array<Tag>>, default: [] },
    tags: { type: Array as PropType<Array<Tag>>, default: [] },
    disabled: { type: Boolean, default: false },
  },
  components: {
    Edit, Select
  },
  setup(props, { emit }) {
    const i18n = useI18n()

    const selectedTag = ref('')
    const inputTag = ref('')

    const addTag = (name: string) => {
      let names = Array.from(props.modelValue, el => el.name)
      let allNames = Array.from(props.tags, el => el.name)
      if (names.indexOf(name) != -1) {
        return  // already in tags list
      }
      if (allNames.indexOf(name) != -1) {
        let id = props.tags.filter(el => el.name == name)[0].id
        props.modelValue.push({ "id": id, "name": name })
      } else {
        props.modelValue.push({ "id": "", "name": name })
      }
      emit('update:modelValue', props.modelValue)
    }

    const enterClicked = () => {
      addTag(inputTag.value)
    }

    const deleteTag = (name: string) => {
      let i = props.modelValue.findIndex(el => el.name == name)
      if (i != -1) {
        props.modelValue.splice(i, 1)
        emit('update:modelValue', props.modelValue)
      }
    }

    const selectedTags = computed(() => {
      return Array.from(props.modelValue, el => el.name)
    })

    const availableTags = computed(() => {
      let names = Array.from(props.modelValue, el => el.name)
      const arr = []
      for (let el of props.tags) {
        if (names.indexOf(el.name) == -1) {
          arr.push({ "text": el.name, "value": el.id })
        }
      }
      return arr
    })

    watch(selectedTag, (id) => {
      let name = props.tags.filter(el => el.id == id)[0]
      if (name) {
        addTag(name.name)
      }
      selectedTag.value = ""
    })

    return {
      i18n,
      selectedTag,
      selectedTags,
      availableTags,
      inputTag,
      enterClicked,
      deleteTag,
      addTag
    };
  },
});
