import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a041852"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_Box = _resolveComponent("Box")!
  const _component_Table = _resolveComponent("Table")!

  return (_ctx.menu)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Table, {
          "col-width": "a,100%,a",
          hover: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.items, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", {
                  onClick: ($event: any) => (_ctx.itemEdit(index)),
                  class: "cursor-pointer"
                }, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_Image, {
                      path: "img/tbl/m-edit.png",
                      height: "24"
                    })
                  ])
                ], 8, _hoisted_2),
                _createElementVNode("td", {
                  onClick: ($event: any) => (_ctx.changeFilter(index)),
                  class: "cursor-pointer"
                }, [
                  _createVNode(_component_Box, {
                    span: "",
                    class: "link",
                    bold: _ctx.isActive(item.desc)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.desc), 1)
                    ]),
                    _: 2
                  }, 1032, ["bold"])
                ], 8, _hoisted_3),
                _createVNode(_component_Box, {
                  td: "",
                  class: "text-align-right",
                  mp: "0,10,0,10",
                  border: ",1,,,lightgray"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.count_day), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_Box, {
                  td: "",
                  class: "text-align-right",
                  mp: "0,10,0,10",
                  border: ",1,,,lightgray"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.ost_one), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_Box, {
                  td: "",
                  class: "text-align-right",
                  mp: "0,10,0,10",
                  border: ",1,,,lightgray"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.ost_all), 1)
                  ]),
                  _: 2
                }, 1024),
                _createElementVNode("td", {
                  onClick: ($event: any) => (_ctx.incTbl(index)),
                  class: "cursor-pointer"
                }, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_Image, {
                      path: "img/tbl/m-add.png",
                      height: "24"
                    })
                  ])
                ], 8, _hoisted_4)
              ]))
            }), 128))
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}