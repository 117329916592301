
import { computed, defineComponent } from "vue"


export default defineComponent({
  name: "BtnClose",
  props: {
    white: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { attrs }) {
    const className = computed(() => {
      return ["btn-close", props.white && `btn-close-white`];
    })

    return {
      className,
      attrs
    }
  }
})
