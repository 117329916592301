import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35a284aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name", "value", "disabled", "id", "checked"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.cssClass)
  }, [
    _createElementVNode("input", _mergeProps({
      class: "form-check-input",
      type: "radio",
      name: _ctx.group,
      value: _ctx.value,
      disabled: _ctx.disabled,
      id: _ctx.uid,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
    }, _ctx.$attrs, {
      checked: _ctx.value==_ctx.modelValue
    }), null, 16, _hoisted_1),
    _createElementVNode("label", {
      class: _normalizeClass(_ctx.cssLabel),
      for: _ctx.uid
    }, _toDisplayString(_ctx.i18n.t(_ctx.caption)), 11, _hoisted_2)
  ], 2))
}