import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Box = _resolveComponent("Box")!

  return (_ctx.model)
    ? (_openBlock(), _createBlock(_component_Box, {
        key: 0,
        tr: "",
        background: _ctx.background(_ctx.model.desc ? _ctx.model.desc: ''),
        fontsize: "14px",
        class: "text-align-center",
        border: "1,,,,#eee",
        mp: "0,0,0,0",
        onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mouseOnThis=true)),
        onMouseout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mouseOnThis=false))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Box, {
            td: "",
            class: "text-align-center",
            border: ",1,,,lightgray"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.dayjs(_ctx.model.date_oper).format('DD.MM.YYYY')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("td", null, [
            (_ctx.model.box[0]!=0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.model.box[0]), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("td", null, [
            (_ctx.model.box[1]!=0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.model.box[1]), 1))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_Box, {
            td: "",
            background: "lightgray"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.model.box[2]), 1)
            ]),
            _: 1
          }),
          _createElementVNode("td", null, [
            (_ctx.model.pack[0]!=0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.model.pack[0]), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("td", null, [
            (_ctx.model.pack[1]!=0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.model.pack[1]), 1))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_Box, {
            td: "",
            background: "lightgray"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.model.pack[2]), 1)
            ]),
            _: 1
          }),
          _createElementVNode("td", null, [
            (_ctx.model.one[0]!=0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.model.one[0]), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("td", null, [
            (_ctx.model.one[1]!=0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.model.one[1]), 1))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_Box, {
            td: "",
            background: "lightgray"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.model.one[2]), 1)
            ]),
            _: 1
          }),
          _createElementVNode("td", null, _toDisplayString(_ctx.model.ost), 1)
        ]),
        _: 1
      }, 8, ["background"]))
    : _createCommentVNode("", true)
}