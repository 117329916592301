
import { computed, defineComponent } from "vue"

export default defineComponent({
  name: "Table",
  inheritAttrs: true,
  props: {
    bordered: { type: Boolean, default: false },
    hover: { type: Boolean, default: false },
    colWidth: { type: String, default: '' }
  },
  setup(props, { attrs }) {

    const cssClass = computed(() => {
      var suffix = ""
      suffix += props.bordered ? " table-bordered " : ""
      suffix += props.hover ? " table-hover" : ""
      return "table table-sm" + suffix
    })

    const cols = computed(() => {
      let result = []
      for (let col of props.colWidth.split(",")) {
        if (col.trim().endsWith('px') || col.trim().endsWith('%')) {
          result.push(col.trim())
        } else {
          if (col.trim()) {
            result.push('')
          }
        }
      }
      return result
    })

    return {
      cssClass,
      props,
      cols,
      attrs
    };
  }
})
