import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18de3157"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav nav-tabs p-0" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "tab-content py-3 px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link", { active: index === _ctx.selectedIndex }]),
            onClick: ($event: any) => (_ctx.selectTab(index)),
            style: _normalizeStyle(_ctx.tabColor(index))
          }, _toDisplayString(_ctx.i18n.t(tab)), 15, _hoisted_2)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 64))
}