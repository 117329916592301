

import { defineComponent } from 'vue'
import { Container, Row, Col } from "@/components/base-ui"


export default defineComponent({

  name: 'Register',

  components: {
    Container,
    Row,
    Col,
  },

 // ---------------------------------------------------------------------------
  setup(props, { emit }) {

    const userInfo = {
         fname: "",
         lname: "",
         contact: "",
         email: "",
         password: ""
    }

    // TODO: сделать глобальный метод (ф-ия встречается в разных местах)
    const getImage = (name: string) => {
      return require(`@/assets/${name}`)
    };

    // -------------------------------------------------------------------------
    return {
      userInfo,
      getImage,
    }
  }
})
