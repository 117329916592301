
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { isMobile } from '@/components/base-ui'

import { Table, Container, Row, Col, Box, Text } from '@/components/base-ui'
import Loader from '@/components/shared/Loader.vue'

import { TblInterface } from '@/models/tbl/Tbl.interface'
import { TblMenuInterface } from '@/models/tbl/TblState.interface'
import Tbl from './Tbl.vue'


export default defineComponent({
  name: "TblList",
  // ---------------------------------------------------------------------------
  components: {
    Tbl,
    Container, Row, Col, Box, Table, Text,
    Loader,
  },
  // ---------------------------------------------------------------------------
  props: {
    tbls: {
      type: Array as PropType<TblInterface[]>
    },
    loading: {
      type: Boolean
    },
    menu: {
      type: Object as PropType<TblMenuInterface>,
    }
  },
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {
    const i18n = useI18n()
    // -------------------------------------------------------------------------
    return {
      i18n,
      isMobile
    }
  }
  // ---------------------------------------------------------------------------
})
