

import { defineComponent, PropType, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { TblMenuInterface } from '@/models/tbl/TblState.interface'
import { Table, Container, Row, Col, Image, Box } from '@/components/base-ui'
import '@/components/base-ui/common.scss'


export default defineComponent({
  name: "TblMenu",
  // ---------------------------------------------------------------------------
  components: {
    Container, Row, Col, Table, Image, Box
  },
  // ---------------------------------------------------------------------------
  props: {
    menu: {
      type: Object as PropType<TblMenuInterface>,
    },
  },
  // ---------------------------------------------------------------------------
  emits: ["changeFilter", "incTbl", "itemEdit"],
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {
    const i18n = useI18n()

    // -------------------------------------------------------------------------
    const isActive = (name: string) => {
      if (!props.menu) {
        return false
      }
      return props.menu.items[props.menu.currentIndex].desc == name
    }

// -------------------------------------------------------------------------
    const changeFilter = (id: number) => {
      emit("changeFilter", id)
    }

    // -------------------------------------------------------------------------
    const incTbl = (id: number) => {
      emit("incTbl", id)
    }

// -------------------------------------------------------------------------
    // TODO: текст пунктов меню должен приходить с API на выбранном языке
    const i18ru = (txt: string) => {
      // TODO: ПОПРАВИТЬ (подиситема Tbl, не Tasks) - может вообще функция не нужна
      if (txt.startsWith("На дату")) {
        return i18n.t('tasks.На дату') + txt.substring(7)
      }
      if (txt.startsWith("Загрузка")) {
        return i18n.t('tasks.Загрузка') + txt.substring(8)
      }
      return i18n.t('tasks.' + txt)
    }

    // -------------------------------------------------------------------------
    const itemEdit = (id: number) => {
      emit("itemEdit", id)
    }

    // -------------------------------------------------------------------------
    return {
      i18n,
      changeFilter,
      itemEdit,
      incTbl,
      i18ru,
      isActive
    }
  }
  // ---------------------------------------------------------------------------
})
