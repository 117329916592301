

import { defineComponent, computed } from 'vue'
import { getUID } from '../getUID'
import Box, {parseMP} from '../layout/Box.vue'


export default defineComponent({
  name: 'Edit',
  components: {
    Box
  },
  inheritAttrs:true,

  props: {
    caption: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    modelValue: { type: [String, Number], default: '' },
    disabled: { type: Boolean, default: false },

    mp: { type: String, default: '' },
    color: { type: String, default: '' },
    background: { type: String, default: '' },
    bold:  { type: Boolean, default: false },
    height: { type: String, default: '' },
    fontsize: { type: String, default: '' },
    noborder:  { type: Boolean, default: false },
    readonly:  { type: Boolean, default: false },

    sm:  { type: Boolean, default: false },
    md:  { type: Boolean, default: false },
    lg:  { type: Boolean, default: false }
  },

  setup(props) {
    const uid = getUID('Edit-')

    const cssClass = computed(() => {
      let classes = ['']
      return classes.join(' ')
    })

    const cssStyle = computed(() => {
      var styles = ''
      if (props.background) {
        styles += `background-color: ${props.background};`
      }
      if (props.bold) {
        styles += `font-weight: bold;`
      }
      if (props.color) {
        styles += `color: ${props.color};`
      }
      if (props.height) {
        styles += `height: ${props.height};`
        styles += `max-height: ${props.height};`
        styles += `min-height: ${props.height};`
      }
      if (props.noborder) {
        styles += `border: 0px;`
      }
      if (props.fontsize) {
        styles += `font-size: ${props.fontsize};`
      }
      const obj = parseMP(props.mp)
      styles += Array.from(Object.keys(obj), k=>[`${k}:${obj[k]};`]).join('')
      return styles
    })

    const cssInput = computed(() => {
      // let classes = ['form-control form-control-sm']
      var suffix = ''
      suffix = props.sm ? 'form-control-sm' : suffix
      suffix = props.md ? 'form-control-md' : suffix
      suffix = props.lg ? 'form-control-lg' : suffix
      return 'form-control' + (suffix ? (' ' + suffix) : '')
    })

    return {
      uid,
      cssClass,
      cssStyle,
      cssInput
    };
  }
})
