<!-- CRC:C9DA6070:3 -->
<!-- Компонент сетки - строка -->

<!-- Использование:

   <Row> ... </Row>
   <Row [ left ] [ right ] [ hcenter ] ... [ top ] ... > ... </Row>

-->

<template>
  <div :class="cssClass" v-bind="attrs">
    <slot></slot>
  </div>
</template>

<script lang="js">
import { computed, defineComponent } from "vue"

export default defineComponent({
  name: "Row",
  inheritAttrs: true,
  props: {
    left: { type: Boolean, default: false },
    right: { type: Boolean, default: false },
    hcenter: { type: Boolean, default: false },
    between: { type: Boolean, default: false },
    around: { type: Boolean, default: false },
    evenly: { type: Boolean, default: false },
    top: { type: Boolean, default: false },
    bottom: { type: Boolean, default: false },
    vcenter: { type: Boolean, default: false },
    cols: { type: [String, Array] },
    mp: { type: String, default: '' },
  },

  setup(props, { attrs }) {
    const cssClass = computed(() => {
      let classes = ['row']
      if (props.cols) { classes.push(`${spreadProps(props.cols)}`) }
      if (props.left) { classes.push('justify-content-start') }
      if (props.right) { classes.push('justify-content-end') }
      if (props.hcenter) { classes.push('justify-content-center') }
      if (props.between) { classes.push('justify-content-between') }
      if (props.around) { classes.push('justify-content-around') }
      if (props.evenly) { classes.push('justify-content-evenly') }
      if (props.top) { classes.push('align-items-start') }
      if (props.bottom) { classes.push('align-items-end') }
      if (props.vcenter) { classes.push('align-items-center') }
      if (props.mp) { classes.push(props.mp) }
      return classes.join(' ')
    });

    const spreadProps = props => {
      if (typeof props === "string") {
        return `row-cols-${props}`
      }
      return props.map(prop => `row-cols-${prop}`.trim()).join(" ")
    };

    return {
      cssClass,
      props,
      attrs
    }
  }
})
</script>


<!-- ======================================================================= -->
<style scoped lang="scss">
</style>
