
import { defineComponent, ref, PropType, reactive } from "vue"
import { useI18n } from 'vue-i18n'
import Row from '../layout/Row.vue'
import Col from '../layout/Col.vue'
import Checkbox from '../forms/Checkbox.vue'
import Btn from './Btn.vue'


interface Style {
  color: string
  bold: boolean
}

export default defineComponent({
  name: "FontStyle",
  inheritAttrs: true,
  props: {
    modelValue : {
      type: Object as PropType<Style>,
      default: {
        color: 'black',
        bold: false
      }
    },
  },
  components: {
    Row, Col, Checkbox, Btn
  },

  setup(props, { emit, attrs }) {
    const i18n = useI18n()

    const fontStyle = reactive({
      color: 'black',
      bold: false
    })

    const COLORS = {
        red: 'red',
        darkRed: 'darkRed',
        pink: 'pink',
        darkMagenta: 'darkMagenta',
        violet: 'violet',
        magenta: 'magenta',
        black: 'black',
        indigo: 'indigo',
        darkblue: 'darkblue',
        blue: 'blue',
        lightblue: 'lightblue',
        cyan: 'cyan',
        teal: 'teal',
        green: 'green',
        lightgreen: 'lightgreen',
        lime: 'lime',
        lightyellow: 'lightgray',
        yellow: 'yellow',
        orange: 'orange',
        gray: 'gray',
        lightgray: 'lightgray',
        white: 'lightgray',
    }

    const showColorTab = ref(false)

    const colorsStyle = (color: string) => {
      var style = `background-color: ${color}; color: ${color};`
      style += `border: 1px solid ${color};`
      return style
    }
    const toggleTabColors = () => {
      showColorTab.value = !showColorTab.value
    }
    const colorStyle = () => {
      var style = `color: ${props.modelValue.color};`
      style += `font-weight: ${props.modelValue.bold ? 'bold' : 'normal'}`
      return style
    }
    const setColor = (color: string) => {
      showColorTab.value = false
      props.modelValue.color = color
    }

    return {
      i18n,
      attrs,
      COLORS,
      colorsStyle,
      toggleTabColors,
      colorStyle,
      setColor,
      showColorTab,
      fontStyle,
    }
  }
})
