
import { defineComponent, computed, onMounted } from 'vue'
import { useTasksStore } from '@/store/tasks'
import Loader from '@/components/shared/Loader.vue'
import TasksList from '@/components/tasks/TasksList.vue'
import TaskEdit from '@/components/tasks/TaskEdit.vue'
import TasksMenu from '@/components/tasks/TasksMenu.vue'
import TasksHeader from '@/components/tasks/TasksHeader.vue'
import TaskClose from '@/components/tasks/TaskClose.vue'
import { TaskInterface } from '@/models/tasks/Task.interface'
import { RootMutationType } from '@/models'

import { Container, Row, Col, Checkbox, FormGroup, FormLabel, DatePicker,
  TimePicker, Tabs, Tab, Table, Btn, isMobile, Box } from '@/components/base-ui'

import { useI18n } from 'vue-i18n'
import '@/components/base-ui/common.scss'

interface Param {
    [key:string]: any;
}


export default defineComponent({
  name: 'Tasks',
  // ---------------------------------------------------------------------------
  components: {
    TasksList,
    TaskEdit,
    TasksMenu,
    TasksHeader,
    TaskClose,
    Loader,
    Container, Row, Col, Checkbox, FormGroup, FormLabel, DatePicker, Box,
    TimePicker, Tabs, Tab, Table, Btn
  },

  // ---------------------------------------------------------------------------
  setup() {
    const i18n = useI18n()
    const tasksStore = useTasksStore()

    // -------------------------------------------------------------------------
    const tasks = computed(() => {
      return tasksStore.state.tasks
    })

    // -------------------------------------------------------------------------
    const menu = computed(() => {
      return tasksStore.state.menu
    })

    // -------------------------------------------------------------------------
    const error = computed(() => {
      return tasksStore.state.error
    })

    // -------------------------------------------------------------------------
    const nav_pages = computed(() => {
      return tasksStore.state.navigation.nav_pages
    })

    // -------------------------------------------------------------------------
    const showEdit = computed(() => {
      return tasksStore.state.showEdit
    })

    // -------------------------------------------------------------------------
    const loading = computed(() => {
      return tasksStore.state.loading
    })

    // -------------------------------------------------------------------------
    const loadingTask = computed(() => {
      return tasksStore.state.loadingTask
    })

    // -------------------------------------------------------------------------
    onMounted(() => {
      tasksStore.action(RootMutationType.tasks.loadTasks)
      // TODO: обновление статуса меню перенести в мутации и убрать его из всех
      // TODO: методов компонентов (тоже самое в ТБЛ и Финансах)
      if (isMobile()) {
        tasksStore.action(RootMutationType.tasks.updateMenu, {
          showMenu: false
        })
      }
    })

    // -------------------------------------------------------------------------
    const flipMenu = (filter: Param) => {
      tasksStore.action(RootMutationType.tasks.updateMenu, {
        showMenu: !tasksStore.state.menu.showMenu
      })
    }

    // -------------------------------------------------------------------------
    const changePage = (url: string) => {
      tasksStore.action(RootMutationType.tasks.loadTasks, {url: url})
    }

    // -------------------------------------------------------------------------
    const changeFilter = (filter: Param) => {
      tasksStore.action(RootMutationType.tasks.updateMenu, filter)
      tasksStore.action(RootMutationType.tasks.loadTasks, filter)
      if (isMobile()) {
        tasksStore.action(RootMutationType.tasks.updateMenu, {
          showMenu: false
        })
      }
    }

    // -------------------------------------------------------------------------
    const onNewTask = () => {
      tasksStore.action(RootMutationType.tasks.newTask)
    }

    // -------------------------------------------------------------------------
    const onCloseTask = (id: string) => {
      tasksStore.action(RootMutationType.tasks.closingTask, id)
    }

    // -------------------------------------------------------------------------
    const onSaveTask = (task: TaskInterface) => {
      tasksStore.action(RootMutationType.tasks.saveTask, {task: task})
    }

    // -------------------------------------------------------------------------
    const onCancelEdit = () => {
      tasksStore.action(RootMutationType.tasks.cancelEditTask)
    }

    // -------------------------------------------------------------------------
    const onTaskEdit = (id: string) => {
      tasksStore.action(RootMutationType.tasks.loadTask, id)
    }

    // -------------------------------------------------------------------------
    const transferOverdue = (timeInc: string) => {
      tasksStore.action(RootMutationType.tasks.transferOverdue, timeInc)
    }

    // -------------------------------------------------------------------------
    return {
      i18n,
      tasks,
      loading,
      onCloseTask,
      onTaskEdit,
      showEdit,
      onSaveTask,
      onCancelEdit,
      loadingTask,
      onNewTask,
      menu,
      changeFilter,
      flipMenu,
      nav_pages,
      changePage,
      transferOverdue,
      error
    }
  }
})
