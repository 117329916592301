/* CRC:7D4B6B81:1 */
/* Различные функции, утилиты для работы с задачами */
/* jshint esversion: 8 */

import { RepeatParamInterface } from '@/models'
import dayjs from 'dayjs'

// =============================================================================
// Вычисления срока для повторной заначи
export const getNextDate = (repeat: RepeatParamInterface) => {
  var date = dayjs(new Date())

  const setTime = (date: Date) => {
    let time = repeat.time.split(":")
    let hour = Number(time[0] ? time[0]: 0)
    let minutes = Number(time[1] ? time[1]: 0)
    date.setHours(hour, minutes, 0)
    return date
  }

  // ---------------------------------------------------------------------------
  if (repeat.mode == '1') {
    return setTime(date.add(1, 'days').toDate())
  }

  // ---------------------------------------------------------------------------
  if (repeat.mode == '2;1' || repeat.mode == '2;2') {
    var week = repeat.week.map(x => Number(x) % 8);
    if (repeat.mode == '2;1') {
      week = [(repeat.numDay - 1) % 7 + 1]
    }

    // приведим к виду ПН=1..ВС=7
    let currentDay = 1 + (7 - 1 + date.day()) % 7

    for (let d of week) {
      if (d > currentDay) {
        return setTime(date.add(d - currentDay, 'days').toDate())
      }
    }
    for (let d of week) {
      if (d <= currentDay) {
        return setTime(date.add(7 - currentDay + d, 'days').toDate())
      }
    }
    return setTime(date.add(1, 'days').toDate())
  }

  // ---------------------------------------------------------------------------
  if (repeat.mode == '3;1') {
    if (date.date() >= repeat.numDay) {
      date = date.add(1, 'month')
    }
    date = date.set('date', repeat.numDay)
    return setTime(date.toDate())
  }

  // ---------------------------------------------------------------------------
  if (repeat.mode == '4;1') {
    var offset = Math.sign(date.month() - parseInt(repeat.month) + 1)
    if (offset == 0) {
      if (date.date() >= repeat.numDay) {
        offset = 1;
      }
    }
    if (offset == -1) {
      offset = 0;
    }
    date = date.set('date', repeat.numDay)
    date = date.set('month', parseInt(repeat.month))
    date = date.set('year', date.year() + offset)

    return setTime(date.toDate())
  }

  // ---------------------------------------------------------------------------
  if (repeat.mode == '5') {
    date = date.add(repeat.days, 'days')
    date = date.add(repeat.hours, 'hours')
    date = date.add(repeat.minutes, 'minutes')
    return date.toDate()
  }
  // ---------------------------------------------------------------------------
  return setTime(date.add(1, 'days').toDate())

}
