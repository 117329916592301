
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { MoneyOperInListInterface } from '@/models/money/Money.interface'
import { MoneyMenuInterface } from '@/models/money/MoneyState.interface'
import Oper from './Oper.vue'
import Loader from '@/components/shared/Loader.vue'
import { Table, Container, Row, Col, Box, Text } from '@/components/base-ui'
import { isMobile } from '@/components/base-ui'
import '@/components/base-ui/common.scss'


export default defineComponent({
  name: "OperList",
  // ---------------------------------------------------------------------------
  components: {
    Oper,
    Container, Row, Col, Box, Table, Text,
    Loader,
  },
  // ---------------------------------------------------------------------------
  props: {
    opers: {
      type: Array as PropType<MoneyOperInListInterface[]>
    },
    loading: {
      type: Boolean
    },
    menu: {
      type: Object as PropType<MoneyMenuInterface>,
    },
  },
  // ---------------------------------------------------------------------------
  emits: ['editOper', 'newOper'],
  // ---------------------------------------------------------------------------
  setup(props, { emit }) {
    const i18n = useI18n()

    // -------------------------------------------------------------------------
    const onEditOper = (task: MoneyOperInListInterface) => {
      emit('editOper', task)
    }

    // -------------------------------------------------------------------------
    const onNewOper = (task: MoneyOperInListInterface) => {
      emit('newOper', task)
    }

    // -------------------------------------------------------------------------
    return {
      onEditOper,
      onNewOper,
      i18n,
      isMobile
    }
  }
  // ---------------------------------------------------------------------------
})
