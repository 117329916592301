/* CRC:CF8930B5:3 */
/* Формирование уникального ID для "связанных" компонентов */

const MAX_UID = 1000000

export const getUID = (prefix: string) => {
  do {
    prefix += Math.floor(Math.random() * MAX_UID).toString()
  } while (document.getElementById(prefix))

  return prefix;
}
