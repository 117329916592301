import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Box = _resolveComponent("Box")!

  return (_ctx.props.header)
    ? (_openBlock(), _createBlock(_component_Box, {
        key: 0,
        class: "card-header"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }))
    : (_ctx.props.body)
      ? (_openBlock(), _createBlock(_component_Box, {
          key: 1,
          class: "card-body"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        }))
      : (_ctx.props.footer)
        ? (_openBlock(), _createBlock(_component_Box, {
            key: 2,
            class: "card-footer"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _: 3
          }))
        : (_openBlock(), _createBlock(_component_Box, {
            key: 3,
            class: "card"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _: 3
          }))
}