/* CRC:9312D22:1 */
/* Реальная реализация интерфейса TblApiClientInterface */

// TODO: может стоит именно здесь делать логику, а не в [...]ApiClient.model

import {
  TblApiClientUrlsInterface,
  TblApiClientInterface,
  TblApiClientModel
} from '@/models/api-client/tbl'
import { config } from '@/config'

const urls: TblApiClientUrlsInterface = config.api.tbl.urls
const apiVersion: string = config.api.tbl.version


const tblApiClient:
     TblApiClientInterface = new TblApiClientModel(urls, apiVersion)

// export our instance
export default tblApiClient
