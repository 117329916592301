/* CRC:B0D0D79:11 */
/* Экземпляр TasksStateInterface с начальными значениями */


import { TasksStateInterface } from '@/models'
/**
* @name initialTasksState
* @description
* The Tasks state instance with the initial default values
*/
export const initialTasksState: TasksStateInterface = {
  loading: false,
  loadingTask: false,
  tasks: [],

  navigation: {
    total: 0,
    pages: 0,
    current_page: 0,
    next: null,
    prev: null,
    nav_pages: []
  },

  menu: {
    showMenu: true,
    currentIndex: 0,
    showOK: false,
    isSearch: false,
    searchText: '',
    date: undefined,
    items: [ { name: 'today', desc: 'Загрузка...', count: 0 }, ],
    currentItem: { name: '', desc: '', count: 0 },
  },

  count_overdue: 0,
  count_today: 0,
  count_all: 0,

  currentTask: null,
  showEdit: false,
  showCloseTask: false,

  statuses: [],
  prioritets: [],
  wait_groups: [],

  overdueTasks: {
    timer: null,
    interval:  1000 * 60    // 1 минута
  },

  error: {
    status: 0,
    statusText: '',
    msg: ''
  }
}
